import React, { useState, useEffect } from "react";
import { storageService } from "../services/storageService";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default function MyOrder() {
  const [loader, setLoader] = useState(false);
  const [historyList, setHistoryList] = useState();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  useEffect(() => {
    getOrderHistory();
  }, []);

  const getOrderHistory = (current = pagination.page) => {
    let postData = {
      page: current,
      limit: pagination.limit,
      // status: bookingstatus,
    };
    setLoader(true);
    storageService.getOrderHistoryApi(postData).then((res) => {
      if (res.status === true) {
        setHistoryList(res.data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedObject) => {
    getOrderHistory(selectedObject.selected + 1);
  };


  return (
    <div className="row">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Booking Info.</th>
              <th>Invoice (AED)</th>
              <th>Advance Paid</th>
              <th>Quote Amt (AED)</th>
              <th>Shipment Status</th>
              {/* <th>Doc Status</th> */}
              <th>Advance Adj (AED)</th>
              <th>OS Amount (AED)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {historyList &&
              historyList.length > 0 &&
              historyList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {(pagination.page - 1) * pagination.limit +
                      (index + 1)}</td>
                    <td>
                      <p className="mb-0">
                        <b>Booking ID -</b> {item.job_token}
                        <br />
                        <b>Actual -</b>
                        {item.actual_item.kilogram} KG
                        | {item.actual_item.total_volume_CBM.toFixed(4)} CBM | {item.actual_item.package} Package
                        <br />
                        <b>Booking -</b>
                        {item.booking_item.kilogram} KG
                        | {item.booking_item.total_volume_CBM.toFixed(4)} CBM | {item.booking_item.package} Package
                      </p>
                    </td>
                    <td>
                      <p className="mb-0">
                        <b>Total -</b> 0.00
                        <br />
                        <b>Pending -</b> Nill
                      </p>
                    </td>
                    <td>{item.advance_paid || "0.00"} AED</td>
                    <td>{item.quote_amount || "0.00"} AED</td>
                    <td>
                      <span className="badge bg-info">  {item.job_status}</span>
                    </td>
                    {/* <td>
                        <span className="badge bg-warning">Pending</span>
                      </td> */}

                    <td>{item.advance_paid || "0.00"} AED</td>
                    <td>{item.advance_paid || "0.00"} AED</td>
                    <td>
                      <span>


                        <Link to={`/booking-details/${item._id}`}>
                          <img
                            src="/assets/images/view.svg"
                            height="25"
                            alt="Not found"
                          />
                        </Link>
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {
          historyList && historyList.length < 0 &&
          <p className="bg-warning text-center">List Not Available</p>
        }
        {pagination && pagination.page.length > 1 && (
          <ReactPaginate
            initialPage={pagination.page - 1}
            pageCount={pagination.totalRecord / pagination.limit}
            pageRange={2}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            pageLinkClassName="page-link"
            breakLinkClassName="page-link"
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
            pageClassName="page-item"
            breakClassName="page-item"
            nextClassName="page-item"
            previousClassName="page-item"
          />
        )}
      </div>
      {
        loader && <div className="loader_lst">
          <img
            src="/assets/images/JOP.gif"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      }
    </div>
  );
}
