import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap-v5';
import { RiStore2Fill } from "react-icons/ri";
// import { FaShoppingCart, FaBox } from "react-icons/fa";
import { MdEmail, MdStayPrimaryPortrait } from "react-icons/md";
// import { RiHandCoinFill } from "react-icons/ri";
import DatePicker from "react-datepicker";
import { storageService } from '../services'

// import BookingchartPage from './BookingchartPage';
// import IncentivechartPage from './IncentivechartPage';

export default function StoreDetails() {
    const history = useHistory()
    const params = history.location.state
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState();
    useEffect(() => {
        getStoreDetails()
    }, [])


    const getStoreDetails = () => {
        let getData = {
            id: params._id,
        }
        storageService.getStoreDetails(getData).then(res => {
            setData(res.data)
        }).catch(error => {
            console.log(error)
        })
    }
    return (
        <section className="pb-5 pt-0">
            <div className="header_title">
                <div className="container-fluid">
                    <h1>Store Details</h1>
                    <img src="/assets/images/about-us-inner-banner.jpg" className="bannerimg" alt='Not found' />
                </div>
            </div>
            <div className="container-fluid mt-5">

                <div className="row">
                    <div className="col-lg-3 col-md-4 mt-3">
                        <div className="white_box h-100">
                            <div className="storeProfile mx-auto p-0">
                                <RiStore2Fill />
                            </div>
                            <div className="flex-grow-1">
                                <h4 className=" text-center my-3">{data && data.store_name}</h4>

                                <ListGroup variant="" className="dashboardlist">
                                    <ListGroup.Item className="py-3">
                                        <p className="mb-0 text-muted"><MdEmail /> {data && data.store_email}</p>
                                        <p className="mb-0 text-muted"><MdStayPrimaryPortrait /> +{data && data.phone_number}</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex align-items-center justify-content-between py-3">

                                        <p className="mb-0">Total Bookings</p>
                                        <p className="mb-0 fw-bold">-</p>

                                    </ListGroup.Item>
                                    <ListGroup.Item className="d-flex align-items-center justify-content-between py-3">

                                        <p className="mb-0">Total Products</p>
                                        <p className="mb-0 fw-bold">-</p>

                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9 col-md-8 mt-3">
                        <div className="white_box h-100">
                            <h5 className="mb-0 mb-3">Bookings</h5>
                            <div className="d-flex align-items-center">
                                <div className="filterSec">
                                    <div>
                                        <label>From</label>
                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                        <label>To</label>
                                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                                    </div>
                                    <div>
                                        <input type="text" placeholder="Search by Id, Store Name" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                {/* <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Booking Info.</th>
                                            <th>Invoice (AED)</th>
                                            <th>Advance Paid</th>
                                            <th>Quote Amt (AED)</th>
                                            <th>Shipment Status</th>
                                            <th>Doc Status</th>
                                            <th>Advance Adj (AED)</th>
                                            <th>OS Amount (AED)</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className="mb-0"><b>Booking ID -</b> 000771/130421/US<br />
                                                    <b>Actual -</b> 256.00 KGS | 0.052 CBM | 152 Pieces<br />
                                                    <b>Booking -</b> 0 KGS | 0 CBM | 0 Pieces</p>
                                            </td>
                                            <td>
                                                <p className="mb-0"><b>Total -</b> 0.00<br />
                                                    <b>Pending -</b> Nill</p>
                                            </td>
                                            <td>0.00 AED</td>
                                            <td>35,810.00</td>
                                            <td><span className="badge bg-info">Requested</span></td>
                                            <td><span className="badge bg-warning">Pending</span></td>

                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td>
                                                <Link to="/booking-details"><img src="/assets/images/view.svg" height="25"  alt='Not found'  /></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0"><b>Booking ID -</b> 000771/130421/US<br />
                                                    <b>Actual -</b> 256.00 KGS | 0.052 CBM | 152 Pieces<br />
                                                    <b>Booking -</b> 0 KGS | 0 CBM | 0 Pieces</p>
                                            </td>
                                            <td>
                                                <p className="mb-0"><b>Total -</b> 0.00<br />
                                                    <b>Pending -</b> Nill</p>
                                            </td>
                                            <td>0.00 AED</td>
                                            <td>35,810.00</td>
                                            <td><span className="badge bg-info">Requested</span></td>
                                            <td><span className="badge bg-warning">Pending</span></td>

                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td>
                                                <Link to="/booking-details"><img src="/assets/images/view.svg" alt='Not found'  height="25" /></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0"><b>Booking ID -</b> 000771/130421/US<br />
                                                    <b>Actual -</b> 256.00 KGS | 0.052 CBM | 152 Pieces<br />
                                                    <b>Booking -</b> 0 KGS | 0 CBM | 0 Pieces
                                                </p>
                                            </td>
                                            <td>
                                                <p className="mb-0"><b>Total -</b> 0.00<br />
                                                    <b>Pending -</b> Nill</p>
                                            </td>
                                            <td>0.00 AED</td>
                                            <td>35,810.00</td>
                                            <td><span className="badge bg-info">Requested</span></td>
                                            <td><span className="badge bg-warning">Pending</span></td>

                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td>
                                                <Link to="/booking-details"><img src="/assets/images/view.svg" alt='Not found'  height="25" /></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0"><b>Booking ID -</b> 000771/130421/US<br />
                                                    <b>Actual -</b> 256.00 KGS | 0.052 CBM | 152 Pieces<br />
                                                    <b>Booking -</b> 0 KGS | 0 CBM | 0 Pieces</p>
                                            </td>
                                            <td>
                                                <p className="mb-0"><b>Total -</b> 0.00<br />
                                                    <b>Pending -</b> Nill</p>
                                            </td>
                                            <td>0.00 AED</td>
                                            <td>35,810.00</td>
                                            <td><span className="badge bg-info">Requested</span></td>
                                            <td><span className="badge bg-warning">Pending</span></td>

                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td>
                                                <Link to="/booking-details"><img src="/assets/images/view.svg" alt='Not found'  height="25" /></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0"><b>Booking ID -</b> 000771/130421/US<br />
                                                    <b>Actual -</b> 256.00 KGS | 0.052 CBM | 152 Pieces<br />
                                                    <b>Booking -</b> 0 KGS | 0 CBM | 0 Pieces</p>
                                            </td>
                                            <td>
                                                <p className="mb-0"><b>Total -</b> 0.00<br />
                                                    <b>Pending -</b> Nill</p>
                                            </td>
                                            <td>0.00 AED</td>
                                            <td>35,810.00</td>
                                            <td><span className="badge bg-info">Requested</span></td>
                                            <td><span className="badge bg-warning">Pending</span></td>

                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td>
                                                <Link to="/booking-details"><img src="/assets/images/view.svg" alt='Not found'  height="25" /></Link>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="mb-0"><b>Booking ID -</b> 000771/130421/US<br />
                                                    <b>Actual -</b> 256.00 KGS | 0.052 CBM | 152 Pieces<br />
                                                    <b>Booking -</b> 0 KGS | 0 CBM | 0 Pieces</p>
                                            </td>
                                            <td>
                                                <p className="mb-0"><b>Total -</b> 0.00<br />
                                                    <b>Pending -</b> Nill</p>
                                            </td>
                                            <td>0.00 AED</td>
                                            <td>35,810.00</td>
                                            <td><span className="badge bg-info">Requested</span></td>
                                            <td><span className="badge bg-warning">Pending</span></td>

                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td>
                                                <Link to="/booking-details"><img src="/assets/images/view.svg" alt='Not found'  height="25" /></Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                             */}
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Booking ID</th>
                                            <th>Actual Amount</th>
                                            <th>Agreed Amount</th>
                                            <th>Total Incentive (20%)</th>
                                            <th>Customer Incentive (10%)</th>
                                            <th>Retailer Incentive (10%)</th>
                                            <th>Incentive Payout</th>
                                            <th>Value Of Goods</th>
                                            <th>Retailer Invoice No.</th>
                                            <th>Payment Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>123455</td>
                                            <td>2500 AED</td>
                                            <td>2500 AED</td>
                                            <td>500 AED</td>
                                            <td>250 AED</td>
                                            <td>250 AED</td>
                                            <td>250 AED</td>
                                            <td>5000 AED</td>
                                            <td>abc123</td>
                                            <td>Delivered</td>
                                            {/* <td>
                                            <p className="mb-0"><b>Booking ID -</b> 000771/130421/US<br />
                                                <b>Actual -</b> 256.00 KGS | 0.052 CBM | 152 Pieces<br />
                                                <b>Booking -</b> 0 KGS | 0 CBM | 0 Pieces</p>
                                        </td>
                                        <td>
                                            <p className="mb-0"><b>Total -</b> 0.00<br />
                                                <b>Pending -</b> Nill</p>
                                        </td> */}
                                            {/* <td>0.00 AED</td>
                                        <td>35,810.00</td>
                                        <td><span className="badge bg-info">Requested</span></td>
                                        <td><span className="badge bg-warning">Pending</span></td>
                                        <td>0.00</td>
                                        <td>0.00</td>
                                        <td>
                                            <Link to="/booking-details"><img  src="/assets/images/view.svg" alt='Not found' height="25" /></Link>
                                        </td> */}
                                        </tr>

                                        <tr>
                                            <td>2</td>
                                            <td>823782</td>
                                            <td>6500 AED</td>
                                            <td>5900 AED</td>
                                            <td>1180 AED</td>
                                            <td>590 AED</td>
                                            <td>590 AED</td>
                                            <td>590 AED</td>
                                            <td>6000 AED</td>
                                            <td>xyz000</td>
                                            <td>Arrived at Dest Apt</td>
                                        </tr>

                                        <tr>
                                            <td>3</td>
                                            <td>298399</td>
                                            <td>5000 AED</td>
                                            <td>4000 AED</td>
                                            <td>800 AED</td>
                                            <td>400 AED</td>
                                            <td>400 AED</td>
                                            <td>0 AED</td>
                                            <td>4500 AED</td>
                                            <td>mno823</td>
                                            <td>Departed</td>
                                        </tr>

                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td><b>Total</b></td>
                                            <td colspan="2"></td>
                                            <td><b>12400 AED</b></td>
                                            <td><b>2480 AED</b></td>
                                            <td> <b>1240 AED</b></td>
                                            <td> <b>1240 AED</b></td>
                                            <td> <b>840 AED</b></td>
                                            <td> <b>15500 AED</b></td>
                                            <td colspan="2"></td>
                                        </tr>
                                    </tfoot>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
