import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

export default function Receiver() {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.receiver_details
        return (
            <div className='customer-back'>
                <h5 className="secHeading">Receiver Details</h5>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td className="py-1">Name - </td>
                            <td className="py-1"> { info.receiver_name }</td>
                        </tr>
                        <tr>
                            <td className="py-1">Email - </td>
                            <td className="py-1">{ info.email }</td>
                        </tr>
                        <tr>
                            <td className="py-1">Contact No. - </td>
                            <td className="py-1">{ info.pickup_contact }</td>
                        </tr>
                        <tr>
                            <td className="py-1">Address - </td>
                            <td className="py-1">{ info.address }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}
