import React, { useState, useEffect } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import EnquiryService from "../services/enquiryService";
import moment from "moment";
import ReactPaginate from "react-paginate";
let currentPage1 = 1;

export default function EnquiryManagementCRMList() {
  const [enquiryList, setEnquiryList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchField, setSearchField] = useState();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [currentPage, setCurrentPage] = useState(currentPage1);

  useEffect(() => {
    getEnquiryItem();
  }, [searchField, currentPage]);

  const getEnquiryItem = (cancelled = 0) => {
    let param = {
      limit: 10,
      page: currentPage,
      quote_status: "INQUIRY",
      cancelled: cancelled,
    };
    if (searchField) {
      param["search"] = searchField;
    }
    EnquiryService.getEnquiryapi(param).then((result) => {
      if (result.status === true) {
        setLoader(false);
        setPagination({ ...pagination, ...result.pagination });
        let quoteArry = [];
        result.data.map((val) => {
          val.show = false;
          quoteArry.push(val);
        });

        setEnquiryList(result.data);
      } else {
        setLoader(false);
      }
    });
  };

  const financial = (x) => {
    return Number.parseFloat(x).toFixed(2);
  };

  const appendHandler = (event, key) => {
    event.target.closest("tr").classList.toggle("showTr");
    let arry = [...enquiryList];
    enquiryList.map((val, ind) => {
      if (key === ind) {
        val.show = Boolean(!val.show);
      }
    });
    setEnquiryList(arry);
  };

  const onPageChangeEvent = (e) => {
    setCurrentPage(e.selected + 1);
    let ele = document.getElementById('parent_tr')
    ele.classList.remove('showTr')
  };

  return (
    <>
      <section className="pb-5 pt-0 position-relative min-h-320">
        {loader ? (
          <>
            <div className="loader_lst">
              <img
                src="/assets/images/JOP.gif"
                className="bannerimg"
                alt="Not found"
              />
            </div>
          </>
        ) : (
          <>
            <div className="header_title">
              <div className="container-fluid">
                <div className="d-flex justify-content-between">
                  <h1>Enquiry</h1>
                </div>
                <img
                  src="/assets/images/country.jpg"
                  className="bannerimg"
                  alt="Not found"
                />
              </div>
            </div>
            <div className="container-fluid mt-5">
              <div className="white_box storeboxing h-100 pb-1">
                <div className="col-3 flex-row mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name/Phone"
                    onChange={(e) => setSearchField(e.target.value)}
                    value={searchField}
                  />
                </div>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th rowSpan="2">#</th>
                        <th rowSpan="2">
                          Enquiery ID <br /> Quotation No.
                        </th>
                        <th rowSpan="2">
                          Customer <br /> Name
                        </th>
                        <th rowSpan="2"> Mobile No.</th>
                        <th rowSpan="2">Inquiry Date</th>
                        <th rowSpan="2">
                          Retailer <br /> Name
                        </th>
                        <th rowSpan="2">Destination</th>
                        <th colSpan="3" className="splitting_tr_up">
                          Cargo Details
                        </th>
                      </tr>
                      <tr className="splittingCol">
                        <td>
                          No. of <br /> Packages
                        </td>
                        <td>
                          Total <br /> Volume
                        </td>
                        <td>
                          Total <br /> Weight
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {enquiryList &&
                        enquiryList.map((item, key) => {
                          let retailerNm = "NA";

                          if (
                            item.quotes[0].referral_token_details &&
                            item.quotes[0].referral_token_details?.retailer_name
                          ) {
                            retailerNm =
                              item.quotes[0].referral_token_details
                                ?.retailer_name;

                            retailerNm = retailerNm.substring(0, 10);
                          } else {
                            if (
                              item.quotes[0].promo_code_details?.retailer_name
                            ) {
                              retailerNm =
                                item.quotes[0].promo_code_details
                                  ?.retailer_name;
                              retailerNm = retailerNm.substring(0, 10);
                            } else if (
                              item.quotes[0].general_details?.retailer_name
                            ) {
                              retailerNm =
                                item.quotes[0].general_details?.retailer_name;
                              retailerNm = retailerNm.substring(0, 10);
                            }
                          }
                          let userFullNm = "NA";
                          if (item.quotes[0].user_detail) {
                            userFullNm = item.quotes[0].user_detail
                              .user_full_name
                              ? item.quotes[0].user_detail.user_full_name
                              : item.quotes[0].user_detail.user_name;
                            userFullNm = userFullNm;
                          }

                          let cityCountry =
                            item.quotes[0].quote_courier.length > 0
                              ? item.quotes[0].quote_courier.map((list) => {
                                  return (
                                    list.request_data.destination_city +
                                    ((list.request_data?.destination_country &&
                                      ", " +
                                        list.request_data
                                          .destination_country) ||
                                      "")
                                  );
                                })
                              : item.quotes[0].quote_air.length > 0
                              ? item.quotes[0].quote_air.map((list) => {
                                  return (
                                    list.request_data.destination_city +
                                    ((list.request_data?.destination_country &&
                                      ", " +
                                        list.request_data
                                          .destination_country) ||
                                      "")
                                  );
                                })
                              : item.quotes[0].quote_sea.length > 0
                              ? item.quotes[0].quote_air.map((list) => {
                                  return (
                                    list.request_data.destination_city +
                                    ((list.request_data?.destination_country &&
                                      ", " +
                                        list.request_data
                                          .destination_country) ||
                                      "")
                                  );
                                })
                              : item.quotes[0].quote_land.length > 0
                              ? item.quotes[0].quote_land.map((list) => {
                                  return (
                                    list.request_data.destination_city +
                                    ((list.request_data?.destination_country &&
                                      ", " +
                                        list.request_data
                                          .destination_country) ||
                                      "")
                                  );
                                })
                              : item.quotes[0].quote_land.map((list) => {
                                  return (
                                    list.request_data.destination_city +
                                    ((list.request_data?.destination_country &&
                                      ", " +
                                        list.request_data
                                          .destination_country) ||
                                      "")
                                  );
                                });

                          return (
                            <>
                              <tr className="parentTr custom" id="parent_tr">
                                <td>
                                  {item.quotes.length > 1 ? (
                                    <button
                                      className="btn btn-primary p-1 px-2"
                                      type="button"
                                      onClick={(event) =>
                                        appendHandler(event, key)
                                      }
                                    >
                                      {item.show === false ? (
                                        <FaAngleUp />
                                      ) : (
                                        <FaAngleDown />
                                      )}{" "}
                                      {item.quotes.length}
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-primary p-1 px-2"
                                      type="button"
                                    >
                                      {1}
                                    </button>
                                  )}
                                </td>
                                <td>{item.quotes[0].quote_token || "NA"}</td>
                                <td>{userFullNm}</td>
                                <td>
                                  {
                                    item.quotes[0].user_detail
                                      ?.user_phone_number
                                  }
                                </td>

                                <td>
                                  {moment(item.quotes[0].created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                <td>{retailerNm}</td>

                                <td>
                                  {cityCountry.join(",").slice(0, 20)} <br />
                                </td>

                                <td className="text-center">
                                  {item.quotes[0].quote_courier.length > 0
                                    ? item.quotes[0].quote_courier.map(
                                        (list) => {
                                          return list.request_data
                                            .number_of_packages;
                                        }
                                      )
                                    : item.quotes[0].quote_air.length > 0
                                    ? item.quotes[0].quote_air.map((list) => {
                                        return list.request_data
                                          .number_of_packages;
                                      })
                                    : item.quotes[0].quote_sea.length > 0
                                    ? item.quotes[0].quote_air.map((list) => {
                                        return list.request_data
                                          .number_of_packages;
                                      })
                                    : item.quotes[0].quote_land.length > 0
                                    ? item.quotes[0].quote_land.map((list) => {
                                        return list.request_data
                                          .number_of_packages;
                                      })
                                    : item.quotes[0].quote_land.map((list) => {
                                        return list.request_data
                                          .number_of_packages;
                                      })}
                                </td>

                                <td className="text-center">
                                  {item.quotes[0].quote_courier.length > 0
                                    ? item.quotes[0].quote_courier.map(
                                        (list) => {
                                          return financial(
                                            list.request_data.volume
                                          );
                                        }
                                      )
                                    : item.quotes[0].quote_air.length > 0
                                    ? item.quotes[0].quote_air.map((list) => {
                                        return financial(
                                          list.request_data.volume
                                        );
                                      })
                                    : item.quotes[0].quote_sea.length > 0
                                    ? item.quotes[0].quote_air.map((list) => {
                                        return financial(
                                          list.request_data.volume
                                        );
                                      })
                                    : item.quotes[0].quote_land.length > 0
                                    ? item.quotes[0].quote_land.map((list) => {
                                        return financial(
                                          list.request_data.volume
                                        );
                                      })
                                    : item.quotes[0].quote_land.map((list) => {
                                        return financial(
                                          list.request_data.volume
                                        );
                                      })}
                                </td>

                                <td className="text-center"> 
                                  {item.quotes[0].quote_courier.length > 0
                                    ? item.quotes[0].quote_courier.map(
                                        (list) => {
                                          return financial(
                                            list.request_data.weight
                                          );
                                        }
                                      )
                                    : item.quotes[0].quote_air.length > 0
                                    ? item.quotes[0].quote_air.map((list) => {
                                        return financial(
                                          list.request_data.weight
                                        );
                                      })
                                    : item.quotes[0].quote_sea.length > 0
                                    ? item.quotes[0].quote_air.map((list) => {
                                        return financial(
                                          list.request_data.weight
                                        );
                                      })
                                    : item.quotes[0].quote_land.length > 0
                                    ? item.quotes[0].quote_land.map((list) => {
                                        return financial(
                                          list.request_data.weight
                                        );
                                      })
                                    : item.quotes[0].quote_land.map((list) => {
                                        return financial(
                                          list.request_data.weight
                                        );
                                      })}
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="13">
                                  <table className="table table-centered table-nowrap mb-0">
                                    <thead className="table-light">
                                      <tr>
                                        <th rowSpan="2">#</th>
                                        <th rowSpan="2">
                                          Enquiery ID <br />
                                          Quotation No.
                                        </th>
                                        <th rowSpan="2">
                                          Customer <br /> Name
                                        </th>
                                        <th rowSpan="2"> Mobile No.</th>
                                        <th rowSpan="2">Inquiry Date</th>
                                        <th rowSpan="2">
                                          Retailer <br /> Name
                                        </th>
                                        <th rowSpan="2">Destination</th>
                                        <th
                                          colSpan="3"
                                          className="splitting_tr_up"
                                        >
                                          Cargo Details
                                        </th>
                                      </tr>
                                      <tr className="splittingCol">
                                        <td>
                                          No. of <br /> Packages
                                        </td>
                                        <td>
                                          Total <br /> Volume
                                        </td>
                                        <td>
                                          Total <br /> Weight
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item.quotes.map((val, ind) => {
                                        return (
                                          <tr className="child-tr">
                                            <td>{ind + 1}</td>
                                            <td>{val.quote_token || "NA"}</td>
                                            <td>
                                              {val.user_detail
                                                ?.user_full_name || "NA"}
                                            </td>
                                            <td>
                                              {
                                                val.user_detail
                                                  ?.user_phone_number
                                              }
                                            </td>

                                            <td>
                                              {moment(val.created_at).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </td>
                                            <td>
                                              {
                                                // get retailer name by general details
                                                val?.referral_token_details
                                                  ?.retailer_name
                                                  ? val?.referral_token_details
                                                      ?.retailer_name
                                                  : val?.promo_code_details
                                                      ?.retailer_name
                                                  ? val?.promo_code_details
                                                      ?.retailer_name
                                                  : val?.general_details
                                                      ?.retailer_name
                                                  ? val?.general_details
                                                      ?.retailer_name
                                                  : "NA"
                                              }
                                            </td>

                                            <td>
                                              {val.quote_courier.length > 0
                                                ? val.quote_courier.map(
                                                    (list) => {
                                                      return list.request_data
                                                        .destination_city;
                                                    }
                                                  )
                                                : val.quote_air.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .destination_city;
                                                  })
                                                : val.quote_sea.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .destination_city;
                                                  })
                                                : val.quote_land.length > 0
                                                ? val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .destination_city;
                                                  })
                                                : val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .destination_city;
                                                  })}
                                            </td>

                                            <td className="text-center">
                                              {val.quote_courier.length > 0
                                                ? val.quote_courier.map(
                                                    (list) => {
                                                      return list.request_data
                                                        .number_of_packages;
                                                    }
                                                  )
                                                : val.quote_air.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .number_of_packages;
                                                  })
                                                : val.quote_sea.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .number_of_packages;
                                                  })
                                                : val.quote_land.length > 0
                                                ? val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .number_of_packages;
                                                  })
                                                : val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .number_of_packages;
                                                  })}
                                            </td>
                                            
                                            <td className="text-center">
                                              {val.quote_courier.length > 0
                                                ? val.quote_courier.map(
                                                    (list) => {
                                                      return list.request_data
                                                        .volume;
                                                    }
                                                  )
                                                : val.quote_air.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .volume;
                                                  })
                                                : val.quote_sea.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .volume;
                                                  })
                                                : val.quote_land.length > 0
                                                ? val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .volume;
                                                  })
                                                : val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .volume;
                                                  })}
                                            </td>

                                            <td className="text-center">
                                              {val.quote_courier.length > 0
                                                ? val.quote_courier.map(
                                                    (list) => {
                                                      return list.request_data
                                                        .weight;
                                                    }
                                                  )
                                                : val.quote_air.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .weight;
                                                  })
                                                : val.quote_sea.length > 0
                                                ? val.quote_air.map((list) => {
                                                    return list.request_data
                                                      .weight;
                                                  })
                                                : val.quote_land.length > 0
                                                ? val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .weight;
                                                  })
                                                : val.quote_land.map((list) => {
                                                    return list.request_data
                                                      .weight;
                                                  })}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>

                  {enquiryList && enquiryList.length <= 0 && !loader && (
                    <div className="text-center w-100 bg-warning my-5">
                      <h6 className="py-2"> list not available </h6>
                    </div>
                  )}

                  {enquiryList && enquiryList.length > 0 && (
                    <ReactPaginate
                      initialPage={pagination.page - 1}
                      pageCount={pagination.totalRecord / pagination.limit}
                      pageRange={2}
                      marginPagesDisplayed={2}
                      onPageChange={onPageChangeEvent}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}
