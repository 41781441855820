const helperFunction = {
  getService: (Air, Sea, Courier, Land) => {
    var service_total = {};
    service_total["air_port_delivery"] =
      Air.all_data && Air.all_data.final?.port_delivery?.port_grant_total
        ? Air.all_data.final?.port_delivery?.port_grant_total
        : "Not Applicable";

    service_total["air_door_delivery"] =
      Air.all_data && Air.all_data.final?.door_delivery?.door_grant_total
        ? Air.all_data?.final?.door_delivery?.door_grant_total
        : "Not Applicable";

    service_total["air_consolidation_delivery"] =
      Air.all_data &&
        Air.all_data?.final?.consolidation_delivery?.consolidation_grant_total
        ? Air.all_data?.final?.consolidation_delivery?.consolidation_grant_total
        : "Not Applicable";

    service_total["sea_port_delivery"] =
      Sea.all_data && Sea.all_data.final?.port_delivery?.port_grant_total
        ? Sea.all_data?.final?.port_delivery?.port_grant_total
        : "Not Applicable";

    service_total["sea_door_delivery"] =
      Sea.all_data && Sea.all_data.final?.door_delivery?.door_grant_total
        ? Sea.all_data?.final?.door_delivery?.door_grant_total
        : "Not Applicable";

    service_total["sea_consolidation_delivery"] =
      Sea.all_data &&
        Sea.all_data.final?.consolidation_delivery?.consolidation_grant_total
        ? Sea.all_data?.final?.consolidation_delivery?.consolidation_grant_total
        : "Not Applicable";

    service_total["courier_door_delivery"] =
      Courier.all_data && Courier.all_data.door_delivery?.door_grant_total
        ? Courier.all_data?.door_delivery?.door_grant_total
        : "Not Applicable";

    service_total["courier_port_delivery"] =
      Courier.all_data && Courier.all_data.port_delivery?.port_grant_total
        ? Courier.all_data?.port_delivery?.port_grant_total
        : "Not Applicable";

    service_total["courier_consolidation_delivery"] =
      Courier.all_data &&
        Courier.all_data.consolidation_delivery?.consolidation_grant_total
        ? Courier.all_data?.consolidation_delivery?.consolidation_grant_total
        : "Not Applicable";

    service_total["land_door_delivery"] =
      Land.all_data && Land.all_data.door_delivery?.door_grant_total
        ? Land.all_data?.door_delivery?.door_grant_total
        : "Not Applicable";
    service_total["land_port_delivery"] =
      Land.all_data && Land.all_data?.port_delivery?.port_grant_total
        ? Land.all_data?.port_delivery?.port_grant_total
        : "Not Applicable";

    service_total["land_consolidation_delivery"] =
      Land.all_data &&
        Land.all_data.consolidation_delivery?.consolidation_grant_total
        ? Land.all_data?.consolidation_delivery?.consolidation_grant_total
        : "Not Applicable";

    return service_total;
  },

  getFreightamount: (value, detail) => {

   // if (value == "NONE") {
    if (value == "Select-Packing") {
      return 0;
    }

    /// air
    if (value == "PORTAIR") {
      return (detail.quote_air?.length && detail.quote_air[0]?.final?.port_delivery?.discount_port_grant_total) ? detail.quote_air[0]?.final?.port_delivery?.discount_port_grant_total : "0"
    }

    if (value == "DOORAIR") {
      return (detail.quote_air?.length && detail.quote_air[0]?.final?.door_delivery?.discount_door_grant_total) ? detail.quote_air[0]?.final?.door_delivery?.discount_door_grant_total : "0"
    }

    if (value == "CONSOLIDATIONAIR") {
      return (detail.quote_air?.length && detail.quote_air[0]?.final?.consolidation_delivery?.discount_consolidation_grant_total) ? detail.quote_air[0]?.final?.consolidation_delivery?.discount_consolidation_grant_total : "Not Applicable"
    }

    /// sea
    if (value == "PORTSEA") {
      return (detail.quote_sea?.length && detail.quote_sea[0]?.final?.port_delivery?.discount_port_grant_total) ? detail.quote_sea[0]?.final?.port_delivery?.discount_port_grant_total : "0"
    }

    if (value == "DOORSEA") {
      return (detail.quote_sea?.length && detail.quote_sea[0]?.final?.door_delivery?.discount_door_grant_total) ? detail.quote_sea[0]?.final?.door_delivery?.discount_door_grant_total : "0"
    }

    if (value == "CONSOLIDATIONSEA") {
      return (detail.quote_sea?.length && detail.quote_sea[0]?.final?.consolidation_delivery?.discount_consolidation_grant_total) ? detail.quote_sea[0]?.final?.consolidation_delivery?.discount_consolidation_grant_total : "Not Applicable"
    }

    // courier

    if (value == "DOORCOURIER") {
      return (detail.quote_courier?.length && detail.quote_courier[0]?.door_delivery?.discount_door_grant_total) ? detail.quote_courier[0]?.door_delivery?.discount_door_grant_total : "0"
    }

    // land

    if (value == "DOORLAND") {
      return (detail.quote_land?.length && detail.quote_land[0]?.door_delivery?.discount_door_grant_total) ? detail.quote_land[0]?.door_delivery?.discount_door_grant_total : "0"
    }

  },

  stringGenerator: (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  insuranceCalculation: (grandAmount, itemsValue, insuranceData) => {
    let insuranceAmount = 0;
    insuranceData &&
      insuranceData.map((item) => {
        insuranceAmount +=
          ((Number(grandAmount) + Number(itemsValue)) *
            item.insurance_premium) /
          100;
      });
    return Number(insuranceAmount);
  },

  storageCalculation: (strVal, storageArray,volumestateItems ) => {
 
    if (storageArray) {
      let cbmRate = 0,
        handleIn = 0,
        handleOut = 0;

      storageArray &&
        storageArray.map((item) => {
          if (item.tarrif === "storage_charges") {
            cbmRate = +item.rate;
          }
          if (item.tarrif === "handling_in") {
            handleIn = +item.rate;
          }
          if (item.tarrif === "handling_out") {
            handleOut = +item.rate;
          }
        });

      let datavol = volumestateItems.volume;
      const totalStorage =
        Number(strVal) * Number(cbmRate) * Number(datavol) +
        (Number(handleIn) * Number(datavol) +
          Number(handleOut) * Number(datavol));
      return Math.round(totalStorage);
    }
  },

  calculateInsurancePer: (minAmt, insurancePer, freight, insuranceAmt) => {
    // const minValue = 150
    const minValue = minAmt
    let finalAmt = ((Number(freight) + Number(insuranceAmt)) * Number(insurancePer)) / 100
    
    return finalAmt > minValue ? finalAmt : minValue
  },
  
  calculateStorage: (noOfDay, storageRate, volume, handlingIn, handlingOut) => {
      const minValue = 100
      let finalAmt = 0

      if(Number(noOfDay) === 0){
        return finalAmt
      }

      finalAmt = (Number(noOfDay) * Number(storageRate) * Number(volume)) + (Number(handlingIn) * Number(volume)) + (Number(handlingOut) * Number(volume))

      return finalAmt > minValue ? finalAmt : minValue
      // (no of day * storage rate) + handling in * volume + handling out * volume
  },

  toCamelCase: (str) => {
    return str
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
  },

  increasingDimension: (itemDimension, type) => {
    const length = Number(itemDimension.length),
      width = Number(itemDimension.width),
      height = Number(itemDimension.height);

    const cartonAfterPacking = { length: 1, width: 1, height: 1 },
      palletAfterPacking = { length: 1, width: 1, height: 15 },
      createAfterPacking = { length: 6, width: 6, height: 15 },
      woodenAfterPacking = { length: 6, width: 6, height: 15 };

    const value = {
      cartonPacking: () => {
        return {
          length: length + cartonAfterPacking.length,
          width: width + cartonAfterPacking.width,
          height: height + cartonAfterPacking.height,
        };
      },
      palletPacking: () => {
        return {
          length: length + palletAfterPacking.length,
          width: width + palletAfterPacking.width,
          height: height + palletAfterPacking.height,
        };
      },
      cratePacking: () => {
        return {
          length: length + createAfterPacking.length,
          width: width + createAfterPacking.width,
          height: height + createAfterPacking.height,
        };
      },
      woodenPacking: () => {
        return {
          length: length + woodenAfterPacking.length,
          width: width + woodenAfterPacking.width,
          height: height + woodenAfterPacking.height,
        };
      },
    };

    return value[type]();
  },

  calculateItem: (itemDimension, type) => {
    let length = Number(itemDimension.length),
      width = Number(itemDimension.width),
      height = Number(itemDimension.height);

    if (type != "cartonPacking") {
      length = length / 100; // Converting into metre
      width = width / 100; // Converting into metre
      height = height / 100; // Converting into metre
    }

    const value = {
      cartonPacking: () => {
        return (length * width * height) / 1000000;
      },
      palletPacking: () => {
        //return 2 * (length * width);
        return (length * width);
      },
      cratePacking: () => {
        return (
          2 * (length * width) + 2 * (height * width) + 2 * (length * height)
        );
      },
      woodenPacking: () => {
        return (
          2 * (length * width) + 2 * (height * width) + 2 * (length * height)
        );
      },
    };

    return value[type]();
  },

  calculateVolume: (dim) => {
    return (dim.length * dim.width * dim.height) / 1000000;
  },

  calculateWeight: (weight, diff, type) => {
    const itemWeight = Number(weight);
    const difference = Number(diff).toFixed(3);
    let wght = 0;

    let getWeight = (dif, val, incrementBy) => {
      let dVal = dif.toString().split(".");
      let valA = Number(dVal[0]);
      let valB = parseFloat(`0.${dVal[1]}`);
      if (valA > 0) {
        wght = valA / val;
      }
      if (valB <= val) {
        wght += incrementBy;
      } else {
        let secDiv = valB / val;
        let dValOne = secDiv.toString().split(".");
        let valC = Number(dValOne[0]);
        let valD = parseFloat(`0.${dValOne[1]}`);
        wght += valC;
        getWeight(valD, val, incrementBy);
      }
      return wght;
    };

    const value = {
      cartonPacking: () => {
        let gWght = getWeight(difference, 0.25, 1); // (difference, min Value, increment By)
        return (gWght + itemWeight).toFixed(3);
      },
      palletPacking: () => {
        //let gWght = getWeight(difference, 0.25, 8); // (difference, min Value, increment By)
        let gWght = getWeight(difference, 0.25, 2); // (difference, min Value, increment By)
        return (gWght + itemWeight).toFixed(3);
        // return (((difference / 0.25) * 8) + itemWeight).toFixed(3)
      },
      cratePacking: () => {
        return (difference * 2 * 6 + itemWeight).toFixed(3);
      },
      woodenPacking: () => {
        return (difference * 2.6 * 6 + itemWeight).toFixed(3);
      },
    };

    return value[type]();
  },

  calculatePrice: (volume, noOfPck, type, packingCalculateData) => {
    const itemVol = Number(volume);
    const cartonFreight = packingCalculateData.length > 0 && packingCalculateData[2];
    const palletFreight = packingCalculateData.length > 0 && packingCalculateData[0];
    const crateFreight = packingCalculateData.length > 0 && packingCalculateData[1];
    const woodenFreight = packingCalculateData.length > 0 && packingCalculateData[3];

    const calculateFrieght = (perUnit, minimum) => {
      let freight = itemVol * perUnit;
      freight = freight < minimum ? minimum : freight;
      return freight * Number(noOfPck);
    };

    const value = {
      cartonPacking: () => {
        return calculateFrieght(cartonFreight.perUnit, cartonFreight.minimum);
      },
      palletPacking: () => {
        return calculateFrieght(palletFreight.perUnit, palletFreight.minimum);
      },
      cratePacking: () => {
        return calculateFrieght(crateFreight.perUnit, crateFreight.minimum);
      },
      woodenPacking: () => {
        return calculateFrieght(woodenFreight.perUnit, woodenFreight.minimum);
      },
    };

    return value[type]();
  },

  getReatailerDiscount: (percentageCode, serviceamt) => {
    let percentageAmount = 0;
    if (percentageCode) {
      percentageAmount = (Number(serviceamt) * Number(percentageCode)) / 100;

    }
    return percentageAmount
  },
  handleDecimalPoints : (val,digit) => {
    if(String(val).includes(".")){
        let newVal = String(val).split(".")
        newVal[1] = newVal[1].slice(0,digit)
        return Number(newVal.join("."))
    }else{return val}
  },
  convertToSelectList : (arr,label,value) => {
    return arr.map((item)=> {
      return {label:item[label],value:item[value],isoCode:item.isoCode}
    })
  }
};

export default helperFunction;
