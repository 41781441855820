import React, { useRef, useState } from "react";
import { FaArrowRight, FaRegUser } from "react-icons/fa";
import { IoIosArrowDown, IoIosCalendar } from "react-icons/io";
import { IoMailOutline, IoCallSharp } from "react-icons/io5";
import SimpleReactValidator from "simple-react-validator";
import AutoComplete from "../../Container/home/AutoComplete";
import reLocationService from "../../services/reLocationService";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap-v5";
import { IoIosCheckmarkCircleOutline, IoMdClose } from "react-icons/io";


let moving_type = [
"1 – 5 Boxes",
"6 – 15 Boxes",
"Studio Apt",
"1 Bed Room Apt",
"2 Bed Room Apt",
"3 Bed Room Apt / Villa",
"4 Bed Room Apt / Villa or More",
]

const Quoterequestform = () => {
  const [DestinationValue, setDestinationValue] = useState("");
  const [, setForceUpdate] = useState("");
  const [inputvalue, setInputvalue] = useState({
    moving_from: "Dubai - United Arab Emirates",
    // moving_to: "",
    moving_size: "",
    moving_month: "",
    name: "",
    email: "",
    phone: "",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setInputvalue({
      ...inputvalue,
      moving_to: "",
      moving_size: "",
      moving_month: "",
      name: "",
      email: "",
      phone: "",
    });
    setDestinationValue("");
  };

  const Handlechange = (event) => {
    setInputvalue({ ...inputvalue, [event.target.name]: event.target.value });
  };
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      messages: {
        required: "Required",
      },
    })
  );

  const getDestinationData = (e) => {
    if (e === "") {
      setDestinationValue("");
    } else {
      let destinationData = {
        destination_country: e.country,
        destination_country_short_name: e.country_short_name,
        destination_city: e.city,
        destination_latitude: e.latitude,
        destination_longitude: e.longitude,
      };
      setDestinationValue(destinationData);
    }
  };

  const submitQuote = (e) => {
    e.preventDefault();
    // setLoader(true)
    let postData = null;
    let validate = null;

    // let random_key = getRandomkeys();

    validator.current.fields["filter"] = true;
    validate =
      +validator.current.fieldValid("moving_to") &&
      validator.current.fieldValid("moving_from") &&
      validator.current.fieldValid("moving_size") &&
      validator.current.fieldValid("moving_month") &&
      validator.current.fieldValid("name") &&
      validator.current.fieldValid("email") &&
      validator.current.fieldValid("phone");

    if (inputvalue) {
      postData = {
        // ...DestinationValue,
        moving_to:
          DestinationValue?.destination_city +
          "," +
          DestinationValue?.destination_country,
        moving_from: "Dubai - United Arab Emirates",
        // moving_to: inputvalue.moving_to,
        moving_size: inputvalue.moving_size,
        moving_month: inputvalue.moving_month,
        name: inputvalue.name,
        email: inputvalue.email,
        phone: inputvalue.phone,
      };

      validate = validator.current.allValid();

      if (validate) {
        // setShow(true);
        reLocationService
          .quoteSubmit(postData)
          .then((res) => {
            if (res.status === true) {
              // getProfileData();
              setShow(true);
              toast.success(res.message);
              setInputvalue({
                ...inputvalue,
                moving_to: "",
                moving_size: "",
                moving_month: "",
                name: "",
                email: "",
                phone: "",
              });
              setDestinationValue("");
              // window.location.reload();
            } else {
              toast.error(res.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        validator.current.showMessages();
      }
    }
  };

  return (
    <section className="pt-5 pb-0">
      <div className="container">
        <div className="row ">
          <div
            className="col-md-6 d-flex justify-content-end pe-5"
            style={{ alignItems: "flex-end" }}
          >
            <div>
              <img
                src="/assets/images/appointment_person 1.png"
                alt="Not found"
                style={{ height: "500px" }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="card relocation-form mb-2">
              <div className="card-body p-0">
                <div
                  className="text-center"
                  style={{ backgroundColor: "#59AEC6" }}
                >
                  <h4 className="p-4 text-white">Request for Quote</h4>
                </div>
                <div className="p-4 pt-5">
                  <form onSubmit={(e) => submitQuote(e)}>
                    <div className="row">
                      <div className="mb-3 col-md-6 position-relative">
                        <input
                          className="form-control p-3"
                          type="text"
                          min="1"
                          name="moving_from"
                          placeholder="Moving From"
                          value="Dubai - United Arab Emirates"
                          // readOnly
                        />
                        {validator.current.message(
                          "Moving From",
                          inputvalue?.moving_from,
                          "required",
                          {
                            className: "text-danger",
                          }
                        )}
                        <span className="input-icon">
                          <FaArrowRight className="fs-4" />
                        </span>
                      </div>
                      <div className="mb-3 col-md-6 position-relative">
                        <AutoComplete
                          getData={(e) => getDestinationData(e)}
                          inputStyles={{ height: "58px" }}
                          dropDownStyles={{ top: "56px" }}
                          value={DestinationValue}
                        />
                        {validator.current.message(
                          "search",
                          DestinationValue,
                          "required",
                          { className: "text-danger text-capitalize" }
                        )}
                        <span className="input-icon">
                          <FaArrowRight className="fs-4" />
                        </span>
                      </div>
                      <div className="mb-3 col-md-6 position-relative">
                        <select
                          className="form-control"
                          style={{ height: "58px", color: `${inputvalue?.moving_size ? '' : 'gray'}` }}
                          name="moving_size"
                          onChange={(e) => {
                            Handlechange(e);
                          }}
                          value={inputvalue.moving_size}
                        >
                          <option selected disabled value="">
                         Select Moving Size
                          </option>
                          {moving_type.map((item,i)=>(
                            <option className="text-dark" key={i} value={item}>{item}</option>
                          ))}
                          {/* <option value="6 – 15 Boxes">6 – 15 Boxes</option>
                          <option value="Studio Apt">Studio Apt</option>
                          <option value="1 Bed Room Apt">1 Bed Room Apt</option>
                          <option value="2 Bed Room Apt">2 Bed Room Apt</option>
                          <option value="3 Bed Room Apt / Villa">
                            3 Bed Room Apt / Villa
                          </option>
                          <option value="4 Bed Room Apt / Villa or More">
                            4 Bed Room Apt / Villa or More
                          </option> */}
                        </select>
                        {validator.current.message(
                          "Moving Size",
                          inputvalue?.moving_size,
                          "required",
                          {
                            className: "text-danger",
                          }
                        )}
                        <span className="input-icon">
                          <IoIosArrowDown className="fs-4" />
                        </span>
                      </div>
                      <div className="mb-3 col-md-6 position-relative custom-date-input">
                        <input
                          className="form-control p-3"
                          type="date"
                          min="1"
                          onChange={(e) => {
                            Handlechange(e);
                          }}
                          name="moving_month"
                          placeholder=" Moving Month"
                          value={inputvalue.moving_month}
                        />
                        {validator.current.message(
                          "Moving Month",
                          inputvalue?.moving_month,
                          "required",
                          {
                            className: "text-danger",
                          }
                        )}
                        <span className="input-icon">
                          <IoIosCalendar className="fs-4" />
                        </span>
                      </div>
                      <div className="mb-3 col-md-12 position-relative">
                        <input
                          className="form-control p-3"
                          type="text"
                          min="1"
                          onChange={(e) => {
                            Handlechange(e);
                          }}
                          name="name"
                          placeholder="Name"
                          value={inputvalue.name}
                        />
                        {validator.current.message(
                          "Name",
                          inputvalue?.name,
                          "required",
                          {
                            className: "text-danger",
                          }
                        )}
                        <span className="input-icon">
                          <FaRegUser className="fs-4" />
                        </span>
                      </div>
                      <div className="mb-3 col-md-12 position-relative">
                        <input
                          className="form-control p-3"
                          type="email"
                          onChange={(e) => {
                            Handlechange(e);
                          }}
                          min="1"
                          name="email"
                          placeholder="Email"
                          value={inputvalue.email}
                        />
                        {validator.current.message(
                          "Email",
                          inputvalue?.email,
                          "required",
                          {
                            className: "text-danger",
                          }
                        )}
                        <span className="input-icon">
                          <IoMailOutline className="fs-4" />
                        </span>
                      </div>
                      <div className="mb-3 col-md-12 position-relative">
                        <input
                          onChange={(e) => {
                            Handlechange(e);
                          }}
                          className="form-control p-3"
                          type="number"
                          min="1"
                          name="phone"
                          placeholder="Phone"
                          value={inputvalue.phone}
                        />
                        {validator.current.message(
                          "Mobile",
                          inputvalue?.phone,
                          "required",
                          {
                            className: "text-danger",
                          }
                        )}
                        <span className="input-icon">
                          <IoCallSharp className="fs-4" />
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn getquoteBtn mb-3 mx-2"
                        style={{ padding: " 27px 56px" }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        size="md"
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div
            className="text-center position-relative"
            style={{ padding: "0px 30px" }}
          >
            <IoIosCheckmarkCircleOutline
              className="mb-3 mt-3"
              style={{ color: "#25AB53", fontSize: "48px" }}
            />
            <p className="pb-1" style={{ fontSize: "16px" }}>
              Your quote request has been sent to the concerned Dept. Someone
              will get in touch with you shortly. If urgent pls contact on – 056
              7540709 / 04 – 4468444.
            </p>
            <button
              className="btn getquoteBtn mb-3 mx-2 text-center d-inline rounded"
              onClick={() => handleClose()}
              style={{ padding: "5px 45px" }}
            >
              OK
            </button>
            <IoMdClose
              className="fs-4 cross-icon"
              onClick={() => handleClose()}
            />
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Quoterequestform;
