import React, { useEffect, useState } from "react";
import { galleryService } from "../../services/galleryService";

const GalleryList = () => {
  const [galleryimg, setGalleryimg] = useState([]);
  const [loader, setLoader] = useState(false)

  let img_url = process.env.REACT_APP_API_BASEURL;

  const getData = async () => {
    setLoader(true)
    await galleryService
      .getGallery()
      .then((res) => {
        setLoader(false)
        setGalleryimg(res.data);
      })
      .catch((error) => {
        setLoader(false)
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <section className="pt-0 bg-white">
      <div className="header_title" style={{ minHeight: "450px" }}>
        <div className="container-fluid">
          <h1 className="text-center">Gallery</h1>
          <img
            src="/assets/images/gallerybanner.png"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container mt-4">
        <div className="row mb-4t" style={{ marginTop: "165px" }}>
      {
        loader && (<div className="loading_spin text-center bg-transparent">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>) ||
         ( galleryimg &&
            galleryimg.length > 0 &&
            galleryimg.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6  mb-4">
                  <div className="text-center h-100">
                    <div>
                      <img
                        src={img_url + "/" + item?.image}
                        // src="/assets/images/galleryimg.png"
                        //height="150"
                        className="w-100"
                        alt="Not found"
                      />
                    </div>
                  </div>
                </div>
              );
            }) || <p className="text-center pb-4">Data not found</p>)
      }
        </div>
      </div>
      <div className="header_title" style={{ minHeight: "450px" }}>
        <div className="container-fluid">
          <div className="col-sm-6" style={{paddingLeft : "85px"}}>
            <h1 style={{ fontSize: "34px" }}>
              We will take care of all your moving needs from A to Z!
            </h1>
            <p>
              {/* Magnis diam montes vehicula non habitant parturient sed. Nullam
              fermentum velit cursus tempor leo litora augue nec. Laoreet est
              sem cursus libero semper sed nulla purus ut habitant potenti. */}
            {/* <div className="d-flex justify-content-center"> */}
              <button
                className="btn getquoteBtn mt-4 mb-3 mx-2 d-block ms-0"
                // onClick={() => handleShow()}
              >
                <img
                  src="/assets/images/arrow_right.svg"
                  alt="Not found"
                  height={12}
                />
                <span className="px-2 d-inline-block fw-bold p-0">
                  Discover More
                </span>
              </button>
            {/* </div> */}
            </p>
            {/* <button>abc</button> */}
          </div>
          <img
            src="/assets/images/galleryfooter.png"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
    </section>
  );
};

export default GalleryList;
