import React from "react";
import { Redirect } from "react-router-dom";
import { store } from "../Redux/store";
import { Storage } from "../Storage";


const ProtectedRoutes = (props) => {

    const Component = props.Cmp
    const isLogin = store.getState().userDetails
    // const isLogin = Storage.get('user-token')
    const { isLogged } = isLogin

    if (!isLogged) {
        return (
            <Redirect to="/login" />
        )
    } else {
        return (
            <>
                <Component />
            </>
        )
    }
}
export default ProtectedRoutes;