import React, { useState, useEffect } from "react";
// import { Modal } from 'react-bootstrap-v5';
import { MdRemoveRedEye } from "react-icons/md";
import { storageService } from '../services'
import { Link } from "react-router-dom";
import { Storage } from '../Storage/Storage'
import { useSelector } from "react-redux";
export default function Store() {

  const state = useSelector(state => state.retailerDetails)
  // const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(true);
  // const [Editshow, setEditshow] = useState(false);
  // const [DeleteShow, setDeleteShow] = useState(false)

  // const OpenDeleteHandle = () => setDeleteShow(true)
  // const CloseDeletehandle = () => setDeleteShow(false)

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const editHandle = () => setEditshow(true)
  // const CloseeditHandle = () => setEditshow(false)
  useEffect(() => {
    getStore()
  }, [])

  const getStore = () => {
    Storage.get('user-token')
    console.log(Storage.get('user-auth'), ' Storage.get')
    let getData = {
      id: state.user.data._id,
    }
    storageService.getStores(getData).then(res => {
      setList(res.data)
      setTimeout(() => {
        setLoader(false)
      }, [1000])
    }).catch(error => {
      console.log(error)
    })
  }



  return (
    <>
      <section className="pb-5 pt-0 position-relative min-h-320">
        {
          loader ? <>
            <div className="loader_lst">
              <img src="/assets/images/JOP.gif" className="bannerimg" alt='Not found' />
            </div>
          </> :
            <>
              <div className="header_title">
                <div className="container-fluid">
                  <div className="d-flex justify-content-between">
                    <h1>Store</h1>
                    {/* <button type="button" className="themebutton" >Add Stores</button> */}
                  </div>
                  <img src="/assets/images/country.jpg" className="bannerimg" alt='Not found' />
                </div>
              </div>
              <div className="container-fluid mt-5">
                <div className="white_box storeboxing h-100 pb-1">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>S.NO.</th>
                          <th>Store Name</th>
                          <th>Manager Name</th>
                          <th>Mobile No. / Email</th>
                          <th>Address</th>
                          <th>Total Bookings</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          list.length > 0 && list.map((elem, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{elem.store_name}</td>
                              <td>{elem.contact_person}</td>
                              <td>+{elem.phone_number} / {elem.store_email} </td>
                              <td>{elem.address}</td>
                              <td>-</td>
                              <td>{elem.store_status}</td>
                              <td className="actionTd">
                                <Link
                                  // to={"/store-details/" + elem._id}
                                  to={{
                                    pathname: `/store-details/${elem._id}`,
                                    state: elem,
                                  }}
                                ><MdRemoveRedEye /></Link>
                                {/* &nbsp;<MdModeEdit /> &nbsp;
                                                <MdDeleteForever className="text-danger" /> */}
                              </td>
                            </tr>
                          ))
                        }


                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

            </>
        }

      </section>



      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body>
        <Modal.Title className="text-center">Create your stores</Modal.Title>
        <form className="w-100 mt-3">
          <div className="form-group mb-3">
          <label for="floatingName">Store Name</label>
            <input
              type="text"
              className="form-control"
              id="floatingName"
              placeholder="Enter Name"
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingPhone">Phone</label>
            <input
              type="number"
              className="form-control"
              id="floatingPhone"
              placeholder="Enter Phone No."
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingEmail">Email</label>
            <input
              type="email"
              className="form-control"
              id="floatingEmail"
              placeholder="Enter Email"
            />
            
          </div>

          <div className="form-group">
          <label for="floatingAddress">Address</label>
            <textarea className="form-control" cols="30" placeholder="Address.." id="floatingAddress"></textarea>
          </div>

          <div className="mt-3 col-12">
            <button type="button" className="themebutton w-50 mx-auto" type="submit">
            Add Stores
            </button>
          </div>

         
        </form>
        </Modal.Body>
      </Modal>




      <Modal show={Editshow} onHide={CloseeditHandle}>
        <Modal.Body>
        <Modal.Title className="text-center">Update your stores</Modal.Title>
        <form className="w-100 mt-3">
          <div className="form-group mb-3">
          <label for="floatingName">Store Name</label>
            <input
              type="text"
              className="form-control"
              id="floatingName"
              placeholder="Enter Name"
              value="Lorem Ipsum"
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingPhone">Phone</label>
            <input
              type="number"
              className="form-control"
              id="floatingPhone"
              placeholder="Enter Phone No."
              value="9100011122"
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingEmail">Email</label>
            <input
              type="email"
              className="form-control"
              id="floatingEmail"
              placeholder="Enter Email"
              value="lorem@ipsum.com" 
            />
            
          </div>

          <div className="form-group">
          <label for="floatingAddress">Address</label>
            <textarea value="AB Road Indore" className="form-control" cols="30" placeholder="Address.." id="floatingAddress"></textarea>
          </div>

          <div className="mt-3 col-12">
            <button type="button" className="themebutton w-50 mx-auto" type="submit">
            Update Stores
            </button>
          </div>

         
        </form>
        </Modal.Body>
      </Modal>



      <Modal show={DeleteShow} onHide={CloseDeletehandle}>
        <Modal.Body className="deleteModal">
        
        <div className="text-center">
       
        <h6 className="my-3">Are you sure you want to delete ?</h6>
            
        </div>
        <table className="text-center">
            <tr>
                <td width="300px" className="border-end border-top cursor-pointer">
                    <h6 className="my-2" onClick={CloseDeletehandle}>YES</h6>
                </td>
                <td width="300px" className="border-start border-top cursor-pointer">
                    <h6 className="my-2" onClick={CloseDeletehandle}>NO</h6>
                </td>
            </tr>
        </table>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
