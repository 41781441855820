import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';


export default class AutoComplete extends React.Component {
    constructor(props) {
        // console.log(props.prefillState, 'props')
        super(props);
        this.state = {
            address: props.prefillState && props.prefillState.city+ (props.prefillState?.country ? (', '+props.prefillState.country) : "")
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.prefillState?.city !== prevProps.prefillState?.city) {
            this.setState({
                address: this.props.prefillState?.city+ (this.props.prefillState?.country ? (', '+this.props.prefillState.country) : "")
            })
        }
    }

    // handleChange = address => {
    //   geocodeByAddress(address).then(results => {
    //     const contrypick = results[0].address_components.filter((item) => item.types.indexOf("country") >= 0)
    //     const citypick = results[0].address_components.filter((item) => (item.types.indexOf("locality") >= 0 || item.types.indexOf("administrative_area_level_1") >= 0))
    //     this.setState({ address: results[0].formatted_address })
    //     getLatLng(results[0]).then(latlngRes => {
    //       this.props.getData({
    //         country: contrypick[0].long_name,
    //         country_short_name: contrypick[0].short_name,
    //         city: citypick[0].long_name,
    //         latitude: latlngRes.lat,
    //         longitude: latlngRes.lng
    //       })
    //     })
    //   })
    // };

    handleSelect = address => {
        geocodeByAddress(address).then(results => {

            const contrypick = results[0].address_components.filter((item) => item.types.indexOf("country") >= 0)
            const citypick = results[0].address_components.filter((item) => (item.types.indexOf("locality") >= 0 || item.types.indexOf("administrative_area_level_1") >= 0))
            this.setState({ address: results[0].formatted_address })
            getLatLng(results[0]).then(latlngRes => {
                this.props.getData({
                    country: contrypick[0].long_name,
                    country_short_name: contrypick[0].short_name,
                    city: citypick[0]?.long_name ? citypick[0]?.long_name : contrypick[0].long_name,
                    latitude: latlngRes.lat,
                    longitude: latlngRes.lng
                })

            })
        })
    };

    handleChange = address => {
        if (!address) {
            this.props.getData('')
        }

        this.setState({ address: address })
    };

    render() {
        return (
            <PlacesAutocomplete   
                value={this.state.address}
                onChange={(e) => this.handleChange(e)}
                onSelect={this.handleSelect}
            >

                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="position-relative">

                        <input
                            style={this.props?.inputStyles || {}}
                            {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'form-control',
                            })}
                        />
                        <div className="autocomplete-dropdown-container" style={this.props?.dropDownStyles || {}}>
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}
