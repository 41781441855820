import React, { useEffect, useState } from "react";
import commonApicall from "../commonApicall";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";

const BookingTable = (props) => {
	const { listdata } = props;
	const getdata = commonApicall.getCalculation(listdata);
//    console.log("getdata=========>",listdata);
	const getPercent = (value, percent) => {
		return (Number (value) * Number (percent) / 100)
	}

	return (
		<table className="table table-bordered">
			<thead>
				<tr>
					<th>Sr. No.</th>
					<th>Booking ID</th>
					<th>Actual Amount</th>
					<th>Amount (Should Be)</th>
					<th>Agreed Amount</th>
					<th>Total Incentive</th>
					<th>Customer Incentive</th>
					<th>Retailer Incentive</th>
					<th>Actual Incentive Retailer Payout</th>
					<th>Incentive Paid Status</th>
					{/* <th>Incentive Payout</th> */}
					<th>Value Of Goods</th>
					<th>Retailer Invoice No.</th>
					<th>Shipment Status</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				{listdata &&
					listdata.length >= 0 &&
					listdata.map((item, keys) => {
						let amountAct = item?.payment_information?.actual_amount
						let amountAgr = item?.payment_information?.agreed_amount
						let totalDiscount = item?.referral_token_details?.total_discount
						let custDiscount = item?.referral_token_details?.customer_discount
						let retailerDiscount = commonApicall.getDiscount(item.referral_token_details)

						let tatalIncentive = item?.referral_token_details ? getPercent(amountAct, totalDiscount) : 0
						let custIncentive = item?.referral_token_details ? getPercent(amountAct, custDiscount) : 0
					
					let retailerPayout =0;
					let finalCust =  (amountAct - custIncentive).toFixed(2)
				

						let amountShouldBe = (amountAct - custIncentive).toFixed(2)
						let retailerIncentive = item?.referral_token_details 
									&& getPercent(amountAct, retailerDiscount)
						if(amountShouldBe==amountAgr.toFixed(2)){
							retailerPayout = retailerIncentive;
						}else{
						   let amtDiff =  (finalCust -amountAgr).toFixed(2);
						   retailerPayout = (retailerIncentive -amtDiff).toFixed(2);
						   if(retailerPayout<0){retailerPayout=0}
						}
						return (
							<tr>
								<td>{keys + 1}</td>
								<td>{item.job_token || "NA"}</td>
								<td>{amountAct}</td>
								<td>{amountShouldBe}</td>
								<td>{amountAgr}</td>
								<td>
									{item?.referral_token_details 
										&&	getPercent(amountAct, totalDiscount) 
									}
								</td>
								<td>
									{item?.referral_token_details
										&&	getPercent(amountAct, custDiscount) 
									}
								</td>
								<td>
									{item?.referral_token_details 
									&& getPercent(amountAct, retailerDiscount) }
								</td>
								
								<td>{retailerPayout}</td>
								<td>{item?.retailer_details.length > 0 && item.retailer_details[0].invoice_payout_status || "NA"}</td>
								<td>{item?.retailer_details.length > 0 && item.retailer_details[0].invoice_value || "NA"}</td>
								<td>{item?.retailer_details.length > 0 && item.retailer_details[0].invoice_number || "NA"}</td>
								<td>{item?.job_status || "NA"}</td>
								<td>
								<Link to={`/retailer-invoice/${item?._id}`} className="stretched-link"><FaRegEye/></Link>								
								</td>
							</tr>
						);
					})}
			</tbody>

			{listdata && listdata.length >= 0 && (
				<tfoot>
					{/* need to corrct calculation <tr>
						<td>
							<b>Total</b>
						</td>
						<td colspan="2"></td>
						<td>
							<b>{(getdata && getdata.agreed_amount + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.total_incentive + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.customer_incentive + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.retailer_incentive + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.incentive_payout + " AED") || "NA"}</b>
						</td>
						<td>
							<b>{(getdata && getdata.goods_value + " AED") || "NA"}</b>
						</td>
						<td colspan="2"></td>
					</tr> */}
				</tfoot>
			)}
		</table>
	);
};

export default BookingTable;
