import React, { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap-v5';
import { authServices } from '../../services';
import Verification from './Verification';
import SimpleReactValidator from "simple-react-validator";
// import 'react-toastify/dist/ReactToastify.min.css';
import { toast, ToastContainer } from 'react-toastify';


export default function ForgetPassword() {
    const [email, setEmail] = useState("")

    const Handlechange = (event) => {
        setEmail(event.target.value);
      };

    const HandleSubmit = (event) => {
    event.preventDefault();
    authServices.forgetpassword({email}).then((response)=>{
        console.log("response...",response)
        if (response.response_status == 200) {
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    }).catch((error)=>{console.log(error)})
    }


    
    
 return (
    <div className="loginpage">
      <img src="assets/images/login_bg.jpg" className="loginbg" />
      <div className="flex-grow-1 text-center loginchar">
        {/* <img src="/user/assets/images/shopping_in_UAE.svg" height="500" /> */}
      </div>
      <div className="loginbox">

        <div><h2 className="mb-5">Forget Password</h2></div >
        <form className="w-100" onSubmit={(event) => HandleSubmit(event)}>

        <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingEmail"
              placeholder="Email"
              onChange={(event) => Handlechange(event)}
              name="user_login"
            />
            <label for="floatingEmail">Email
            </label>
            {/* <span className="text-primary pointer pt-1" onClick={() => (setShowFeild('phone'), setLogin({ ...myLogin, user_email_phone_number: '' }))}> <u>Login via phone number </u></span> */}

        </div>
        <div className="mt-3">
            <button className="themebutton w-100" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
)}