import React from "react";

const RelocationServices = () => {
  return (
    <section className="bg-grey pt-5 pb-0 section_bg" id="packing-services">
      <div className="container mt-4">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 mb-4 text-center">
            <p
              style={{ color: "#C27E0E", fontSize: "20px" }}
              className="text-center section_heading mb-2"
            >
              Our Services
            </p>
            <h3 className="mb-2" style={{ color: "#101518", fontSize: "38px" }}>
              Moving Services For Every Need
            </h3>
            {/* <p className="text-muted" style={{ fontSize: "16px" }}>
              Gravida elit class natoque nisi etiam consectetuer eget dui
              consectetur. Molestie sit imperdiet congue euismod senectus
              eleifend sociosqu turpis.
            </p> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_1.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0">Overseas Moving</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_2.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0">Local Moving</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className=" rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_3.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0">Office Moving</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className=" rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_4.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0"> Pet Relocation</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className=" rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_5.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0">Vehicle Moving</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className=" rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_6.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0"> Import Relocation</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className=" rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_7.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0">Storage Services</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className=" rounded bg-white text-center service-card  p-3 h-100">
              <div>
                <img
                  src="/assets/images/relocation_service_8.png"
                  //height="150"
                  className="w-100"
                  alt="Not found"
                />
              </div>
              <div className="mt-3">
                <h5 className="fw-bold text-dark mb-0">
                  Transit Insurance Cover
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelocationServices;
