import React from "react";
import Quoterequestform from "./Quoterequestform";
import RelocationBanner from "./RelocationBanner";
import RelocationCountryGuide from "./RelocationCountryGuide";
import RelocationServices from "./RelocationServices";
import Testimonial from "./Testimonial";
import WhyChooseUs from "./WhyChooseUs";



const Relocation = () => {
  return (  
    <>
      <RelocationBanner/>
      <Quoterequestform />
      <RelocationServices />
      <WhyChooseUs/>
      <Testimonial/>
      <RelocationCountryGuide/>
    </>
  );
};

export default Relocation;
