import React, { useState, useEffect } from "react";
// import { Modal } from 'react-bootstrap-v5';
import { MdRemoveRedEye } from "react-icons/md";
import { productService } from "../services";
import { Link } from "react-router-dom";
import { Storage } from "../Storage/Storage";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { MdCloudUpload, MdCloudDownload } from "react-icons/md";
import { toast } from "react-toastify";
const REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL;

export default function Product() {
  const state = useSelector((state) => state.retailerDetails);
  const [loader, setLoader] = useState(true);
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = (currentPage = pagination.page) => {
    let getData = {
      id: state.user.data._id,
      page: currentPage,
      limit: pagination.limit,
    };

    productService
      .getProduct(getData)
      .then((res) => {
        setList(res.data);
        setPagination({ ...pagination, ...res.pagination });
        setTimeout(() => {
          setLoader(false);
        }, [1000]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedObject) => {
    getProduct(selectedObject.selected + 1);
  };

  const onClickExport = (e) => {
    productService.exportProductData().then((res) => {
      if (res.status) {
        window.location.href = REACT_APP_API_BASEURL + "/" + res.data;
        toast.success("file download successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const onClickImport = (e) => {
    const formData = new FormData();
    formData.append("csv_file", e.target.files[0]);
    productService.importProductData(formData).then((res) => {
      if (res.status) {
        toast.success("file upload successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <section className="pb-5 pt-0 position-relative min-h-320">
        {loader ? (
          <>
            <div className="loader_lst">
              <img
                src="/assets/images/JOP.gif"
                className="bannerimg"
                alt="Not found"
              />
            </div>
          </>
        ) : (
          <>
            <div className="header_title">
              <div className="container-fluid">
                <div className="d-flex justify-content-between">
                  <h1>Products</h1>
                  {/* <button type="button" className="themebutton" >Add Stores</button> */}
                </div>
                <img
                  src="/assets/images/country.jpg"
                  className="bannerimg"
                  alt="Not found"
                />
              </div>
            </div>
            <div className="container-fluid mt-5">
              <div className="white_box storeboxing h-100 pb-1">
                {Storage.get("user-token") === "retailer" ? (
                  <div className="row justify-content-end mb-3">
                    <label
                      htmlFor="upload"
                      className="themebutton px-4 uploadProducts"
                    >
                      <input
                        type="file"
                        id="upload"
                        accept=".csv"
                        hidden
                        onChange={(e) => onClickImport(e)}
                      />
                      <MdCloudDownload />
                      Import File
                    </label>
                    &nbsp;&nbsp;
                    <button
                      className="themebutton px-4 uploadProducts"
                      onClick={() => onClickExport()}
                    >
                      <MdCloudUpload />
                      Export File
                    </button>
                    {/* <button className="btn btn-primary w-sm waves-effect waves-light mx-3 my-0"
                                        onClick={() => history.push('/add-product')}
                                    >Add Product
                                    </button> */}
                  </div>
                ) : (
                  ""
                )}

                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>S.NO.</th>
                        <th>Product Name</th>
                        <th>Product Volume</th>
                        <th>product_gross_weight</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list &&
                        list.length > 0 &&
                        list.map((elem, i) => {
                          console.log(elem.product_dimentions[0]);
                          return (
                            <tr key={i}>
                              <td> {(pagination.page - 1) * pagination.limit +
                                (i + 1)}</td>
                              <td>{elem.product_name}</td>
                              <td>{elem.product_volume}</td>
                              <td>{elem.product_gross_weight} </td>
                              <td>
                                {Array.isArray(elem.product_dimentions) ?
                                  elem.product_dimentions[0].length : elem.product_dimentions.length
                                }
                              </td>
                              <td>
                                {Array.isArray(elem.product_dimentions) ?
                                  elem.product_dimentions[0].width : elem.product_dimentions.width
                                }
                              </td>
                              <td>
                                {Array.isArray(elem.product_dimentions) ?
                                  elem.product_dimentions[0].height : elem.product_dimentions.height
                                }
                              </td>
                              <td>{elem.product_status}</td>
                              <td className="actionTd">
                                <Link
                                  // to={"/store-details/" + elem._id}
                                  //     to={{
                                  //         pathname: `/store-details/${elem._id}`,
                                  //         state: elem,
                                  //     }}
                                  onClick={() => {
                                    toast.info("coming soon");
                                  }}
                                >
                                  <MdRemoveRedEye />
                                </Link>{" "}
                                &nbsp;
                                {/* <MdModeEdit /> &nbsp;
                                                    <MdDeleteForever className="text-danger" /> */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {list && list.length > 0 && (
                    <ReactPaginate
                      initialPage={pagination.page - 1}
                      pageCount={pagination.totalRecord / pagination.limit}
                      pageRange={2}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </section>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body>
        <Modal.Title className="text-center">Create your stores</Modal.Title>
        <form className="w-100 mt-3">
          <div className="form-group mb-3">
          <label for="floatingName">Store Name</label>
            <input
              type="text"
              className="form-control"
              id="floatingName"
              placeholder="Enter Name"
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingPhone">Phone</label>
            <input
              type="number"
              className="form-control"
              id="floatingPhone"
              placeholder="Enter Phone No."
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingEmail">Email</label>
            <input
              type="email"
              className="form-control"
              id="floatingEmail"
              placeholder="Enter Email"
            />
            
          </div>

          <div className="form-group">
          <label for="floatingAddress">Address</label>
            <textarea className="form-control" cols="30" placeholder="Address.." id="floatingAddress"></textarea>
          </div>

          <div className="mt-3 col-12">
            <button type="button" className="themebutton w-50 mx-auto" type="submit">
            Add Stores
            </button>
          </div>

         
        </form>
        </Modal.Body>
      </Modal>




      <Modal show={Editshow} onHide={CloseeditHandle}>
        <Modal.Body>
        <Modal.Title className="text-center">Update your stores</Modal.Title>
        <form className="w-100 mt-3">
          <div className="form-group mb-3">
          <label for="floatingName">Store Name</label>
            <input
              type="text"
              className="form-control"
              id="floatingName"
              placeholder="Enter Name"
              value="Lorem Ipsum"
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingPhone">Phone</label>
            <input
              type="number"
              className="form-control"
              id="floatingPhone"
              placeholder="Enter Phone No."
              value="9100011122"
            />
          </div>

          <div className="form-group mb-3">
          <label for="floatingEmail">Email</label>
            <input
              type="email"
              className="form-control"
              id="floatingEmail"
              placeholder="Enter Email"
              value="lorem@ipsum.com" 
            />
            
          </div>

          <div className="form-group">
          <label for="floatingAddress">Address</label>
            <textarea value="AB Road Indore" className="form-control" cols="30" placeholder="Address.." id="floatingAddress"></textarea>
          </div>

          <div className="mt-3 col-12">
            <button type="button" className="themebutton w-50 mx-auto" type="submit">
            Update Stores
            </button>
          </div>

         
        </form>
        </Modal.Body>
      </Modal>



      <Modal show={DeleteShow} onHide={CloseDeletehandle}>
        <Modal.Body className="deleteModal">
        
        <div className="text-center">
       
        <h6 className="my-3">Are you sure you want to delete ?</h6>
            
        </div>
        <table className="text-center">
            <tr>
                <td width="300px" className="border-end border-top cursor-pointer">
                    <h6 className="my-2" onClick={CloseDeletehandle}>YES</h6>
                </td>
                <td width="300px" className="border-start border-top cursor-pointer">
                    <h6 className="my-2" onClick={CloseDeletehandle}>NO</h6>
                </td>
            </tr>
        </table>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
