import React, { useEffect, useState } from 'react'
import VolumeCalculator from './VolumeCalculator'
import { Modal } from 'react-bootstrap-v5';

// import { Link } from "react-router-dom";
import Aos from 'aos';
import "aos/dist/aos.css";
import Slider1 from '../../Components/Slider'
import Slider from "react-slick"
// import AutoComplete from './AutoComplete';
import { toast } from 'react-toastify';
// import { Storage } from '../../Storage/Storage'


export default function Home(props) {
    // const [lgShow, setLgShow] = useState(false);

    const [isQuote, setIsQuote] = useState(false)
    const [quotes, setQuotes] = useState({
        number_of_packages: '',
        weight: '',
        volume: ''
    })
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // responsive: [
        //     {
        //         breakpoint: 993,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 2,
        //         }
        //     },
        //     {
        //         breakpoint: 776,
        //         settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //         }
        //     }

        // ]
    };

    useEffect(() => {

        Aos.init({ duration: 1000, delay: 500, disable: 'mobile' });

    }, []);

    useEffect(() => {
        if (props.location.msg) {
            toast.success(props.location.msg)
        }
    }, [])


    const onSubmitClick = (value) => {
        setIsQuote(false)
        setQuotes({
            ...quotes,
            number_of_packages: value.totalPackage,
            weight: value.totalKg,
            volume: value.totalCBM
        })
    }

    const onCloseClick = () => {
        setIsQuote(false)
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);


    const [isOpenModal, setisOpenModal] = useState(true)



    return (
        <>

            <section className="p-0">
                <Slider1 data-aos="fade-down" />
            </section>
            <section className="worldmap ">
                <div className="container ">
                    {/* <div className="col-md-12">
                            <h2 className="text-center text-white section_heading" data-aos="fade-up">Testimonial</h2>
                        </div> */}
                    <h5 data-aos="fade-up" className='text-center text-white'>You’re on holiday and you’re looking to purchase multiple bulky, heavy and precious items across many different stores. For EACH order you would have to hunt out a shipping company, get a quote, look for trust factor and pick an expensive option.
                    </h5>
                    <em data-aos="fade-up" className='h5 text-center text-white d-block'>
                        That’s old news.
                    </em>
                    <h5 data-aos="fade-up" className='text-center text-white '>With World2Door.com you can create a single shipping order, club all your purchases to it, get a shipping quote, complete your online payment and receive it as your home address.</h5>
                    <img src="/assets/images/process_1.svg" className="w-100" alt='Not found' />
                    <div className='d-flex align-items-center justify-content-center '>
                        <button className="btn getquoteBtn mt-5 mb-3 mx-2" onClick={() => setIsQuote(true)}>Get Quote</button>
                        <button className="btn getquoteBtn mt-5 mb-3 mx-2 d-none" onClick={() => handleShow()}>
                            <span className="px-3 d-inline-block fw-bold p-0">Watch Video</span>
                            <img src="/assets/images/play_icon.png" alt='Not found' height={20} />
                        </button>
                    </div>
                    <VolumeCalculator
                        show={isQuote}
                        onSubmit={(e) => onSubmitClick(e)}
                        onClose={() => onCloseClick()}
                    />
                </div>
            </section>

            <section className="testimonial">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <h2 className="text-center text-white section_heading" data-aos="fade-up">Testimonial</h2>
                            <h5 data-aos="fade-up" className='text-white text-center'>
                                Your aspirations do not limit you. So why should your shopping list? With world2door.com shop and club multiple purchases to a single shipping order with zero limits on weight and have it shipped anywhere in the world. All done with a powerful website and mobile app. Here’s what customers say about us.
                            </h5>
                        </div>
                        <div className="col-md-12 mx-auto" data-aos="fade-down">
                            <Slider {...settings}>
                                <div>
                                    <div className="tr_bx h-100 p-3 text-center">
                                        <h5 className=" fw-bold mb-0">Mr. Esteban Espinoza</h5>
                                        <p className="d-block mb-3">Chile</p>
                                        <p className="qouteTxt">I would like to thank World2Door for the excellent support and assistance in every detail of the shipment process. We were completely new in the shipment procedures and with their help everything was clear, smooth and easy to do. We imported a big container from Dubai, UAE to Santiago, Chile and for that distance, the price was quite fair, the delivery time was short and the overall service was excellent.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="tr_bx h-100 p-3 text-center">
                                        <h5 className=" fw-bold mb-0">Mr. Hussain Naal</h5>
                                        <p className="d-block mb-3">USA</p>
                                        <p className="qouteTxt">All stuff received. All good. And very good service from both side (UAE-USA). Thank you very much.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="tr_bx h-100 p-3 text-center">
                                        <h5 className=" fw-bold mb-0">Mr. Sulaiman Abdulazi</h5>
                                        <p className="d-block mb-3">Riyadh (KSA)</p>
                                        <p className="qouteTxt">My experience with World2Door has been nothing short of excellent, I trusted them with an item that is worth over $1000 and they did their best to help me receive it on time, and in good condition. I also had to return the item, and Mr. Rajat were very helpful and supportive by going beyond his responsibilities and cooperating in returning the item to the outlet which he did not have to.</p>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className="tr_bx h-100 p-3 text-center">
                                        <div className="round_img mx-auto mb-3">
                                            <img src="http://writestylesonline.com/wp-content/uploads/2016/08/Follow-These-Steps-for-a-Flawless-Professional-Profile-Picture-1024x1024.jpg"alt='Not found'  />
                                        </div>
                                        <h5 className=" fw-bold mb-0">Lorem Ipsum</h5>
                                        <p className="d-block mb-3">Lorem Ipsum</p>
                                        <p className="">Browsing the websites of UAE stores. We’ll make shopping in UAE as easy as doing so in your neighbourhood.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="tr_bx h-100 p-3 text-center">
                                        <div className="round_img mx-auto mb-3">
                                            <img src="http://writestylesonline.com/wp-content/uploads/2016/08/Follow-These-Steps-for-a-Flawless-Professional-Profile-Picture-1024x1024.jpg"alt='Not found'  />
                                        </div>
                                        <h5 className=" fw-bold mb-0">Lorem Ipsum</h5>
                                        <p className="d-block mb-3">Lorem Ipsum</p>
                                        <p className="">Browsing the websites of UAE stores. We’ll make shopping in UAE as easy as doing so in your neighbourhood.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="tr_bx h-100 p-3 text-center">
                                        <div className="round_img mx-auto mb-3">
                                            <img src="http://writestylesonline.com/wp-content/uploads/2016/08/Follow-These-Steps-for-a-Flawless-Professional-Profile-Picture-1024x1024.jpg"alt='Not found'  />
                                        </div>
                                        <h5 className=" fw-bold mb-0">Lorem Ipsum</h5>
                                        <p className="d-block mb-3">Lorem Ipsum</p>
                                        <p className="">Browsing the websites of UAE stores. We’ll make shopping in UAE as easy as doing so in your neighbourhood.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="tr_bx h-100 p-3 text-center">
                                        <div className="round_img mx-auto mb-3">
                                            <img src="http://writestylesonline.com/wp-content/uploads/2016/08/Follow-These-Steps-for-a-Flawless-Professional-Profile-Picture-1024x1024.jpg"alt='Not found'  />
                                        </div>
                                        <h5 className=" fw-bold mb-0">Lorem Ipsum</h5>
                                        <p className="d-block mb-3">Lorem Ipsum</p>
                                        <p className="">Browsing the websites of UAE stores. We’ll make shopping in UAE as easy as doing so in your neighbourhood.</p>
                                    </div>
                                </div> */}
                            </Slider>


                        </div>

                    </div>
                </div>
            </section>
            <section className="trusted_bg">
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="col-md-12 mb-5">

                            <h2 data-aos="fade-up" className="text-center text-white section_heading ">The UAE’s trusted and reliable shipping partner</h2>

                            <h5 data-aos="fade-up" className='text-center text-white'>Why trust your precious SOFA SET to an unknown shipping company when you can tie up with World2Door.com and be in the company of 1000s of happy customers just like you?
                            </h5>
                            <h5 data-aos="fade-up" className='text-center text-white'>
                                No order size too big or small.
                            </h5>
                            <h5 data-aos="fade-up" className='text-white text-center'>Today We’re the UAE’s Trusted and Reliable Shipping Partner. Tomorrow we’ll be the world’s go-to commerce and fulfilment partner. Watch this space.
                            </h5>
                        </div>
                        <div className="col-md col-6 mb-3" data-aos="fade-down" >
                            <img src="/assets/images/low_rates_.svg" alt='Not found' />
                            <h5 className="text-white fw-bold pt-md-4 pt-3">Low Rates Assured</h5>
                        </div>
                        <div className="col-md col-6 mb-3" data-aos="fade-down">
                            <img src="/assets/images/insurance.svg" alt='Not found' />
                            <h5 className="text-white fw-bold pt-md-4 pt-3">Insurance available</h5>
                        </div>
                        <div className="col-md col-6 mb-3" data-aos="fade-down">
                            <img src="/assets/images/smartphone.svg" alt='Not found' />
                            <h5 className="text-white fw-bold pt-md-4 pt-3">100% paperless transactions</h5>
                        </div>
                        <div className="col-md col-6 mb-3" data-aos="fade-down">
                            <img src="/assets/images/storage_service.svg" alt='Not found' />
                            <h5 className="text-white fw-bold pt-md-4 pt-3">Storage services available</h5>
                        </div>
                        <div className="col-md col-6 mb-3" data-aos="fade-down">
                            <img src="/assets/images/packing_service.svg" alt='Not found' />
                            <h5 className="text-white fw-bold pt-md-4">Fragile packing services option available</h5>
                        </div>
                        {/* <div className="col-md-12 text-center" data-aos="fade-up">
                            <button className="btn-primary videobtn" onClick={() => handleShow()}>
                                <span className="px-3 d-inline-block fw-bold">Watch Video</span>
                                <img src="/assets/images/play_icon.png" alt='Not found' />
                            </button>
                        </div> */}


                        <Modal
                            show={show}
                            size="lg"
                            onHide={handleClose}
                            keyboard={false}
                        >
                            <Modal.Body>
                                <iframe width="100%" height="600" src="https://www.youtube.com/embed/t1oKXMXMoH0" title="YouTube video player" autoplay="true" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </section>
            <section className="bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-center section_heading" data-aos="fade-down">We are perfect for</h2>
                        </div>
                        <div className="col-md-4 mb-3" data-aos="zoom-in" >
                            <div className="white_box h-100 p-md-4 px-3 py-4  text-center">
                                <div className="round_img  mx-auto my-4">
                                    <img src="/assets/images/tourist.jpg" alt='Not found' />
                                </div>
                                <h5 className="fw-bold mb-3">Tourist</h5>
                                <p className="">Making large item or high-volume purchases in Dubai. You’ll never have to worry about baggage allowance again.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3" data-aos="zoom-in">
                            <div className="white_box h-100 p-md-4 px-3 py-4  text-center">
                                <div className="round_img mx-auto my-4">
                                    <img src="/assets/images/resident.jpg" alt='Not found' />
                                </div>
                                <h5 className="fw-bold my-3">Resident</h5>
                                <p className="">Making purchases for their family and houses back home. Think your mum will love the couch you saw today? Now you can get it and ship it across easily.</p>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3" data-aos="zoom-in">
                            <div className="white_box h-100 p-md-4 px-3 py-4 text-center">
                                <div className="round_img mx-auto my-4">
                                    <img src="/assets/images/international_shopper.jpg" alt='Not found' />
                                </div>
                                <h5 className="fw-bold mb-3">International Shoppers</h5>
                                <p className="">Browsing the websites of UAE stores. We’ll make shopping in UAE as easy as doing so in your neighbourhood.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>






        </>
    )
}
