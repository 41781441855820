import React, { useContext } from 'react'
import {BookingDetailContext} from '../BookingDetails'

const Payment = () => {
    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.payment_information
        return (
            <div>
                <h5 className="secHeading">Payment Details</h5>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td className="py-1">Quotation No. - </td>
                            <td className="py-1"> { state.bookingDetails.quote_token }</td>
                        </tr>
                        <tr>
                            <td className="py-1">Booking Amount - </td>
                            <td className="py-1">{ info.breakdown.bookingAmount }</td>
                        </tr>
                        <tr>
                            <td className="py-1">Actual Amount - </td>
                            <td className="py-1">{ info.actual_amount }</td>
                        </tr>
                        <tr>
                            <td className="">Agreed Amount - </td>
                            <td className="">{ info.agreed_amount }</td>
                        </tr>
                        <tr>
                            <td className="">Insurance  - </td>
                            <td className="">{ info.breakdown.insurance }</td>
                        </tr>
                        <tr>
                            <td className="">Packing Charge  - </td>
                            <td className="">{ info.breakdown.packing_charge }</td>
                        </tr>
                        <tr>
                            <td className="">Storage  - </td>
                            <td className="">{ info.breakdown.storage }</td>
                        </tr>
                        <tr>
                            <td className="">Freight  - </td>
                            <td className="">{ info.breakdown.freight }</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    
}

export default Payment
