import React from 'react'
import {  Col, Row, Form, } from "react-bootstrap-v5";

export  const LoginForm = ({loginDetails, setLoginDetails}) => {
    return (
        <React.Fragment>
             <Row>
            <Col className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" value={loginDetails.loginEmail} readOnly  />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col className="col-md-12">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" value={loginDetails.loginPass} onChange={(e) => setLoginDetails({
                        ...loginDetails,
                        loginPass: e.target.value
                    })} />
                </Form.Group>
            </Col>
        </Row>
        </React.Fragment>
    )
}