import {
    LOGIN,
    TEMPLOGIN,
    ITEM_GOODS,
    VOLUMECAL,
    VOLUMESEA,
    VOLUMECOURIER,
    VOLUMELAND,
    GETQUOTE,
    GETTOKEN,
    PROCEEDTOJOB,
    GETFREIGHT
} from './type';

export const UserDetails = data => dispatch => {
    dispatch({
        type: LOGIN,
        payload: data
    });
};
export const UserTempLogin = data => dispatch => {
    dispatch({
        type: TEMPLOGIN,
        payload: data
    });
};
export const ItemGoods = data => dispatch => {
    dispatch({
        type: ITEM_GOODS,
        payload: data
    });
};

export const RetailerDetails = data => dispatch => {
    dispatch({
        type: LOGIN,
        payload: data
    });
};

export const VolumeDetailsAction = data => dispatch => {
    dispatch({
        type: VOLUMECAL,
        payload: data
    })
}

export const VolumeSeaAction = data => dispatch => {
    dispatch({
        type: VOLUMESEA,
        payload: data
    })
}

export const VolumeCourierAction = data => dispatch => {
    dispatch({
        type: VOLUMECOURIER,
        payload: data
    })
}
export const VolumeTntlandAction = data => dispatch => {
    dispatch({
        type: VOLUMELAND,
        payload: data
    })
}

export const GetquoteAction = data => dispatch => {
    dispatch({
        type: GETQUOTE,
        payload: data
    })
}
export const GetTokenAction = data => dispatch => {
    dispatch({
        type: GETTOKEN,
        payload: data
    })
}

export const proceedAction = data => dispatch => {
    dispatch({
        type: PROCEEDTOJOB,
        payload: data
    })
}

export const getFreightAction = data => dispatch => {
    dispatch({
        type: GETFREIGHT,
        payload: data
    })
}