import React, { useState } from 'react'
import VolumeCalculator from '../home/VolumeCalculator'


export default function Services() {
    const [isQuote, setIsQuote] = useState(false)
    const [quotes, setQuotes] = useState({
        number_of_packages: '',
        weight: '',
        volume: ''
    })
    const onSubmitClick = (value) => {
        setIsQuote(false)
        setQuotes({
            ...quotes,
            number_of_packages: value.totalPackage,
            weight: value.totalKg,
            volume: value.totalCBM
        })
    }

    const onCloseClick = () => {
        setIsQuote(false)
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    return (
        <>
            <section className="pb-5 pt-0">
                <div className="header_title">
                    <div className="container-fluid">
                        <h1>Services</h1>
                        <img src="/assets/images/track.jpg" className="bannerimg" alt='Not found' />
                    </div>
                </div>
                <div className="container-fluid_ mt-120 px-0">
                    <section className="bg-white" id="delivery-modes">
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <h2 className="text-center section_heading" >Delivery Modes</h2>
                                </div>
                                <div className="col-md-6 border-end" >
                                    <div className=" d-flex tr_bx  p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/Flat.svg" height="75" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="fw-bold text-dark pb-2">Courier Mode</h5>
                                            <p className="text-muted">In this delivery mode usually it is beneficial to send all small items. This mode is fast and shipments are sent by Air. The typical carriers used are Fedex / DHL / UPS / TNT etc.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 " >
                                    <div className="d-flex tr_bx  p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/plane.svg" height="75" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="fw-bold text-dark pb-2">Air Freight Mode</h5>
                                            <p className="text-muted">This delivery mode is usually for shipments which are slightly larger in size but are urgent and have to be sent by Air. They tend to be cheaper than Courier mode for larger shipments. The typical carriers are Emirates , British Airways , Air India, Lufthansa etc.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 border-end" >
                                    <div className=" d-flex tr_bx  p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/shipment.svg" height="75" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div>
                                            <h5 className="fw-bold text-dark pb-2">Sea Freight Mode</h5>
                                            <p className="text-muted">This delivery mode is usually meant for large size shipments. The transit time is slow but it is much cheaper. It is not used for small shipments as it becomes expensive due to their minimum fixed costs. Some of the shipping lines used are Maersk Line, CMA – CGM, Hapag Llyod etc.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" >
                                    <div className=" h-100 tr_bx  p-md-4 p-3 d-flex  ">
                                        <div>
                                            <img src="/assets/images/delivery-truck.svg" height="75" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="fw-bold text-dark pb-2">Land Freight</h5>
                                            <p className="text-muted">This service is available only where countries are connected by Land. This service is a faster option than sea frt.</p>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn getquoteBtn mt-4 mb-0" onClick={() => setIsQuote(true)}>Get Quote</button>
                            </div>
                        </div>
                        <VolumeCalculator
                            show={isQuote}
                            onSubmit={(e) => onSubmitClick(e)}
                            onClose={() => onCloseClick()}
                        />
                    </section>
                    <section className="bg-grey" id="packing-services">
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <h2 className="text-center section_heading" >Packing Services</h2>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6  mb-4" >
                                    <div className="rounded bg-white text-center p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/WoodenBoxPacking.jpg" height="150" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="mt-3">
                                            <h5 className="fw-bold text-dark pb-2">Wooden Box Packing</h5>
                                            <p className="text-muted">This type of packing is usually done for delicate or brittle items which needs additional protection during the transit. The items are completely covered from all sides by wooden box so that no external hit can penetrate the wooden and damage the goods.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6  mb-4" >
                                    <div className="rounded bg-white text-center p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/WoodenCratePacking.jpg" height="150" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="mt-3">
                                            <h5 className="fw-bold text-dark pb-2">Wooden Crate Packing</h5>
                                            <p className="text-muted">This type of packing is usually done for delicate items which needs additional protection during the transit. The items are not fully covered by wooden and have gaps between them. This helps during customs inspection where the wooden box does not need to be opened to check the package inside.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6  mb-4" >
                                    <div className=" rounded bg-white text-center p-md-4 p-3 h-100">
                                        <div >
                                            <img src="/assets/images/packing.jpg" height="150" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="mt-3">
                                            <h5 className="fw-bold text-dark pb-2">Carton Packing</h5>
                                            <p className="text-muted">This type of packing is usually done in corrugated carton boxes. These cartons boxes are meant packing clothes and regular household goods.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6  mb-4" >
                                    <div className=" rounded bg-white text-center p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/PackingforFragilePacking.jpg" height="150" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="mt-3">
                                            <h5 className="fw-bold text-dark pb-2">Customised Packing for Fragile Packing</h5>
                                            <p className="text-muted">This type of packing is done for items which are extremely fragile and brittle. In such cases the internal packing is also very good using cushioning materials like bubble wraps/thermocol chips etc and the external packing is done with wooden box.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6  mb-4" >
                                    <div className=" rounded bg-white text-center p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/Customised Packing for Heavy Weight Shipments.jpg" height="150" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="mt-3">
                                            <h5 className="fw-bold text-dark pb-2">Customised Packing for Heavy Weight Shipments</h5>
                                            <p className="text-muted">This type of packing is usually for items which are very heavy. The wooden planks used are very thick in size to take on the weight of the shipment.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="bg-white" id="storage-services">
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <h2 className="text-center section_heading" >Storage Service</h2>
                                </div>
                                <div className="col-md-6 border-end mb-4" >
                                    <div className="rounded bg-white text-center p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/shortterm.svg" height="75" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="mt-3">
                                            <h5 className="fw-bold text-dark pb-2">Short Term</h5>
                                            <p className="text-muted">We provide short term storage of goods where customers require storage of their items for less than 1 month.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mb-4" >
                                    <div className="rounded bg-white text-center p-md-4 p-3 h-100">
                                        <div>
                                            <img src="/assets/images/longterm.svg" height="75" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="mt-3">
                                            <h5 className="fw-bold text-dark pb-2">Long Term</h5>
                                            <p className="text-muted">We provide long term storage of goods where customers require storage of their items for more than 1 month.</p>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn getquoteBtn mt-2 mb-0" onClick={() => setIsQuote(true)}>Get Quote</button>

                            </div>
                        </div>
                    </section>
                    <section className="bg-gery" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6" id="consolidation-service">
                                    <div className="rounded bg-white text-center p-md-4 p-3 mt-4 h-100">
                                        <h4 className="fw-bold text-dark pb-2 mb-4 mt-5">Consolidation Service</h4>
                                        <div>
                                            <img src="/assets/images/consolidate.png" height="200" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="flex-grow-1 mt-4">
                                            <p className="text-muted">We provide consolidation services to our customers who purchase items from multiple retailers. We club all the purchased items together and ship it as one shipment. This helps reduce the shipping cost significantly for the customer. The whole process is made very smooth and seamless. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" id="insurance-services">
                                    <div className="rounded bg-white text-center p-md-4 mt-4 p-3 h-100">
                                        <h4 className="fw-bold text-dark pb-2 mb-4 mt-5">Insurance Service</h4>
                                        <div>
                                            <img src="/assets/images/insurance.png" height="200" className="mt-3 me-3" alt='Not found' />
                                        </div>
                                        <div className="flex-grow-1 mt-4">
                                            <p className="text-muted">We encourage all customers to take Insurance cover for any eventualities as the cargo gets exchanged many hands during transit. During this process items could get damaged due to mishandling or due to accidents or the items could get lost. In all such cases Insurance coverage will help recover the value of the goods. The Insurance Premium are small hence always recommended and is based on the value of goods insured.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </section >
        </>
    )
}
