import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap-v5";
import { RiStore2Fill } from "react-icons/ri";
import { FaShoppingCart, FaBox, FaQuestionCircle } from "react-icons/fa";
import { MdEmail, MdPhone } from "react-icons/md";
import { RiHandCoinFill } from "react-icons/ri";
import { storageService, productService } from "../services";
import { useSelector } from "react-redux";
import { Storage } from "../Storage";
import ManagementBooking from "./Bookings/ManagementBooking";
import StorageBooking from "./Bookings/StorageBooking";
import BookingchartPage from "./BookingchartPage";
import IncentivechartPage from "./IncentivechartPage";
import Loader from "./Loader";
import {enquiryService} from '../services/enquiryService'
import commonApicall from "./commonApicall";

export default function Dashboard() {
  const [list, setList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [inquiriesList, setInquiriesList] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [loader, setLoader] = useState(true);
  const state = useSelector((state) => state.retailerDetails);

  useEffect(() => {
    getStore();
    getBookings()
    getProduct();
    getInquiries();
  }, []);

  const getStore = () => {
    let getData = {
      id: state.user.data._id,
    };
    storageService
      .getStores(getData)
      .then((res) => {
        setList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProduct = () => {
    setLoader(true);
    let getData = {
      id: state.user.data._id,
    };

    productService
      .getProduct(getData)
      .then((res) => {
        if (res.status === true) {
          setLoader(false);
          setProductList(res.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInquiries = () => {
    setLoader(true);
    enquiryService
      .getInquiries()
      .then((res) => {
        if (res.status === true) {
          setLoader(false);
          setInquiriesList(res.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBookings = () => {
    let param = {};
    setLoader(true);
    commonApicall.getListData(param, function (status, message, data) {
      setLoader(false);
      if (status === true) {
        setBookingList(data);
      } else {
        setLoader(false);
      }
    });
  };

  return (
    <section className="pb-5 pt-0 position-relative min-h-320 mt-3">
      <>
        <div className="header_title">
          <div className="container-fluid">
            <h1>Dashboard</h1>
            <img
              src="/assets/images/about-us-inner-banner.jpg"
              alt="Not found"
              className="bannerimg"
            />
          </div>
        </div>

        {Storage.get("user-token") === "management" && <ManagementBooking />}

        <div className="container-fluid mt-5">
          {Storage.get("user-token") != "management" && (
            <div className="white_box h-100">
              <div className="row dashboard_sec">
                {Storage.get("user-token") === "retailer" && (
                  <div className="col-md-3 col-sm-6 mb-3">
                    <div className="card">
                      <Link to="/store" className="stretched-link">
                        {" "}
                      </Link>
                      <div className="card-body d-flex  red_bg">
                        <div className="float-end mt-2">
                          <div id="total-revenue-chart"></div>
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <p className="text-white mb-0">Total Stores</p>
                          <h4 className="mb-1 text-white mb-0">
                            <span className="dashboard_count">
                              {list && list.length}
                            </span>
                          </h4>
                        </div>
                        <div className="dashboard_icons">
                          <RiStore2Fill />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* {Storage.get("user-token") === "management" && (
                  <div className="col-md-3 col-sm-6 mb-3">
                    <div className="card">
                      <Link
                        to="/all-bookings-management"
                        className="stretched-link"
                      >
                        {" "}
                      </Link>
                      <div className="card-body d-flex  yellow_bg">
                        <div className="float-end mt-2">
                          <div id="total-revenue-chart"></div>
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <p className="text-white mb-0">Performance</p>
                          <h4 className="mb-1 text-white mb-0">
                            <span className="dashboard_count">-</span>
                          </h4>
                        </div>
                        <div className="dashboard_icons">
                          <FaShoppingCart />
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* {Storage.get("user-token") === "management" && (
                  <div className="col-md-3 col-sm-6 mb-3">
                    <div className="card">
                      <Link to="/store" className="stretched-link">
                        {" "}
                      </Link>
                      <div className="card-body d-flex  red_bg">
                        <div className="float-end mt-2">
                          <div id="total-revenue-chart"></div>
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <p className="text-white mb-0">Stores Detail</p>
                          <h4 className="mb-1 text-white mb-0">
                            <span className="dashboard_count">
                              {list && list.length}
                            </span>
                          </h4>
                        </div>
                        <div className="dashboard_icons">
                          <RiStore2Fill />
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

                {Storage.get("user-token") === "retailer" && (
                  <div className="col-md-3 col-sm-6 mb-3">
                    <div className="card">
                      <Link to="/all-bookings" className="stretched-link">
                        {" "}
                      </Link>
                      <div className="card-body d-flex  yellow_bg">
                        <div className="float-end mt-2">
                          <div id="total-revenue-chart"></div>
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <p className="text-white mb-0">Total Bookings</p>
                          <h4 className="mb-1 text-white mb-0">
                            <span className="dashboard_count">{bookingList && bookingList.length}</span>
                          </h4>
                        </div>
                        <div className="dashboard_icons">
                          <FaShoppingCart />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {Storage.get("user-token") === "store" && (
                  <StorageBooking />

                  // comment by sd
                  // <div className="col-md-3 col-sm-6 mb-3">
                  //   <div className="card">
                  //     <Link
                  //       to="/all-bookings-store"
                  //       className="stretched-link"
                  //     >
                  //       {" "}
                  //     </Link>
                  //     <div className="card-body d-flex  yellow_bg">
                  //       <div className="float-end mt-2">
                  //         <div id="total-revenue-chart"></div>
                  //       </div>
                  //       <div className="flex-grow-1 pl-3">
                  //         <p className="text-white mb-0">Total Bookings</p>
                  //         <h4 className="mb-1 text-white mb-0">
                  //           <span className="dashboard_count">-</span>
                  //         </h4>
                  //       </div>
                  //       <div className="dashboard_icons">
                  //         <FaShoppingCart />
                  //       </div>
                  //     </div>
                  //   </div>
                  // </div>
                )}

                {Storage.get("user-token") === "management" && (
                  <div className="col-md-3 col-sm-6 mb-3">
                    <div className="card">
                      <a className="stretched-link"> </a>
                      <div className="card-body d-flex  blue_bg">
                        <div className="float-end mt-2">
                          <div id="total-revenue-chart"></div>
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <p className="text-white mb-0">Total Incentive</p>
                          <h4 className="mb-1 text-white mb-0">
                            <span className="dashboard_count">-</span>
                          </h4>
                        </div>
                        <div className="dashboard_icons">
                          <RiHandCoinFill />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* {Storage.get("user-token") === "retailer" ? (
                  <div className="col-md-3 col-sm-6 mb-3">
                    <div className="card">
                      <Link to="/product" className="stretched-link">
                        {" "}
                      </Link>
                      <div className="card-body d-flex  purple_bg">
                        <div className="float-end mt-2">
                          <div id="total-revenue-chart"></div>
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <p className="text-white mb-0">Total Products</p>
                          <h4 className="mb-1 text-white mb-0">
                            <span className="dashboard_count">
                              {productList && productList.length}
                            </span>
                          </h4>
                        </div>
                        <div className="dashboard_icons">
                          <FaBox />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}

                {Storage.get("user-token") === "retailer" ? (
                  <div className="col-md-3 col-sm-6 mb-3">
                    <div className="card">
                      <Link
                        to="/enquiry-management-c-r-m-list"
                        className="stretched-link"
                      >
                        {" "}
                      </Link>
                      <div className="card-body d-flex  green_bg">
                        <div className="float-end mt-2">
                          <div id="total-revenue-chart"></div>
                        </div>
                        <div className="flex-grow-1 pl-3">
                          <p className="text-white mb-0">Total Inquiries</p>
                          <h4 className="mb-1 text-white mb-0">
                            <span className="dashboard_count">
                              {inquiriesList && inquiriesList.length}
                            </span>
                          </h4>
                        </div>
                        <div className="dashboard_icons">
                          <FaQuestionCircle />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          <div className="row">
            {/* sd comment {( Storage.get("user-token") === "store") &&(
                <>
                  <div className="col-md col-sm-6 mt-3">
                    <div className="white_box h-100">
                      <BookingchartPage />
                    </div>
                  </div>

                </>
              )} */}

            {/* 
              {(Storage.get("user-token") === "management") && (
                <>
                  <div className="col-md col-sm-6 mt-3">
                    <div className="white_box h-100">
                      <BookingchartPage />
                    </div>
                  </div>
                  <div className="col-md col-sm-6 mt-3">
                    <div className="white_box h-100">
                      <IncentivechartPage />
                    </div>
                  </div>
                </>
              )} */}

            {Storage.get("user-token") === "retailer" ? (
              <div className="col-md col-sm-6 mt-3">
                <div className="white_box h-100">
                  <h5 className="d-flex align-items-center justify-content-between">
                    Stores Monthly Report
                    <div className="chartfilter">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Store"
                      />
                    </div>
                  </h5>
                  <ListGroup variant="" className="dashboardlist">
                    {list.length > 0 &&
                      list.map((elem, i) => (
                        <ListGroup.Item key={i}>
                          <div className="d-flex align-items-center py-3">
                            <div className="me-3">
                              <img
                                height="50"
                                src="/assets/images/storeicon.svg"
                                className="storeimg"
                                alt="Not found"
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="position-relative mb-1 fw-bold">
                                {elem.store_name}
                              </h6>
                              <p className="mb-0 text-muted">
                                <MdPhone />+{elem.phone_number} &nbsp; | &nbsp;{" "}
                                <MdEmail /> {elem.store_email}
                              </p>
                            </div>
                            <div className="text-end">
                              <label className="text-success fw-bold">
                                + AED 987
                              </label>
                              <label className="text-danger fw-bold d-block">
                                10 Bookings
                              </label>
                            </div>
                          </div>
                        </ListGroup.Item>
                      ))}
                  </ListGroup>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
      <Loader isShow={loader} />
    </section>
  );
}
