import React from 'react';
import { Bar } from 'react-chartjs-2';

const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Bookings',
            data: [124, 100, 150, 124, 100, 55, 50, 10, 110, 70, 90, 110],
            backgroundColor: ["#06825B", "#F19D2D"],
        }
    ],
};

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};





export default function BookingchartPage() {
    return (
        <div >
            <h5 className="d-flex align-items-center justify-content-between">Bookings
                <div className="chartfilter">
                    <select className="form-control ">
                        <option>Monthly</option>
                        <option>Weekly</option>
                        <option>Daily</option>
                    </select>
                </div>
            </h5>
            <Bar data={data} height={240} options={options} />
        </div>
    )
}
