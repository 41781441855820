import React from 'react'
import { Row, Col, Form, Button } from "react-bootstrap-v5";
import { useSelector } from 'react-redux';

export const RegistrationForm = ({ regDetails, setRegDetails }) => {

    const UserInfo = useSelector((state) => state.userDetails.user.data);

    return (
        <React.Fragment>
            <Row>
                <Col className="col-md-12">
                    <Form.Group className="mb-3" controlId="nn">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={regDetails.uname} name="user_full_name" onChange={(e) => setRegDetails({
                            ...regDetails,
                            uname: e.target.value
                        })} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" value={regDetails.email} readOnly />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-12">
                    <Form.Group className="mb-3" controlId="cn">
                        <Form.Label>Contact Number</Form.Label>
                        <Form.Control type="text" value={regDetails.contactNum} onChange={(e) => setRegDetails({
                            ...regDetails,
                            contactNum: e.target.value
                        })} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-12">
                    <Form.Group className="mb-3" controlId="ut">
                        <Form.Label>User Type: </Form.Label>
                        {UserInfo.user_type}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter password" value={regDetails.pass} onChange={(e) => setRegDetails({
                            ...regDetails,
                            pass: e.target.value
                        })} />
                    </Form.Group>
                </Col>
                <Col className="col-md-12">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter password" value={regDetails.passConf} onChange={(e) => setRegDetails({
                            ...regDetails,
                            passConf: e.target.value
                        })} />
                    </Form.Group>
                </Col>
                <Col className="col-md-12">
                    <Form.Check
                        inline
                        label="Terms & Condition"
                        name="is_terms_conditions"
                        type='checkbox'
                        value={regDetails.terms}
                        onChange={(e) => setRegDetails({
                            ...regDetails,
                            terms: e.target.checked
                        })}
                    // onChange={(e) => handleChange(e)}
                    // ref={terms}
                    />
                    <Form.Check
                        inline
                        label="Privacy Policy"
                        name="is_privacy_policy"
                        type='checkbox'
                        value={regDetails.privacy}
                        onChange={(e) => setRegDetails({
                            ...regDetails,
                            privacy: e.target.checked
                        })}
                    // onChange={(e) => handleChange(e)}
                    // ref={privacy}
                    />
                    <br />
                    <a href="https://www.world2door.com/terms-and-conditions" target="_blank">Terms & Condition</a>
                    <br />
                    <a href="https://www.world2door.com/privacy-policy" target="_blank">Privacy Policy</a>
                </Col>
            </Row>
        </React.Fragment>
    )

}