import React, { useState, useEffect, useRef, memo } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Modal, Col, Row, Form, Button } from "react-bootstrap-v5";
import { toast } from "react-toastify";
import { quoteService, signupQuoteService } from '../../services';
import { authServices } from '../../services/authServices';
import { Storage } from "../../Storage";
import { UserDetails } from "../../Redux/action";
import { LoginForm } from './AuthForm/LoginForm';
import { RegistrationForm } from './AuthForm/RegistrationForm';

function UserAuthentication({ toggleAuth, handleProceed }) {
    const [show, setShow] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [loginDetails, setLoginDetails] = useState({
        loginEmail: "",
        loginPass: ""
    });
    const [regDetails, setRegDetails] = useState({
        uname: "",
        email: "",
        contactNum: "",
        pass: "",
        passConf: "",
        terms: false,
        privacy: false,
    });


    // const uname = useRef(null);
    // const email = useRef(null);
    // const contactNum = useRef(null);
    // const pass = useRef(null);
    // const terms = useRef(false);
    // const privacy = useRef(false);

    // const loginEmail = useRef(null);
    // const loginPass = useRef();

    const UserInfo = useSelector((state) => state.userDetails.user.data);
    const dispatch = useDispatch();

    const handleClose = () => {
        setShow(false)
        toggleAuth()
    };


    useEffect(() => {
        quoteService
            .checkEmailApi({ user_email: UserInfo.user_email })
            .then((result) => {
                // if(uname.current) uname.current.value = result.data.user_full_name
                // if(email.current) email.current.value = result.data.user_email
                // if(loginEmail.current) loginEmail.current.value = result.data.user_email
                // if(contactNum.current) contactNum.current.value = result.data.user_phone_number
                //  console.log("email=====",email);
                //  console.log("loginEmail=====",loginEmail);
                if (result.status === true) {
                    if (result.data.is_registered === true) {
                        setShowLogin(true);
                        setLoginDetails({
                            loginEmail: result.data.user_email,
                            loginPass: ""
                        })
                    } else {
                        setShowLogin(false);
                        setRegDetails({
                            uname: result.data.user_full_name,
                            email: result.data.user_email,
                            contactNum: result.data.user_phone_number,
                            pass: "",
                            terms: false,
                            privacy: false,
                        });
                    }
                } else {
                    setShowLogin(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);



    const handleSubmit = () => {
        if (showLogin) {
            if (!loginDetails.loginEmail || !loginDetails.loginPass) {
                toast.error("Please check the fields");
                return false
            } else {
                userLogin()
            }
        } else {
            if (!regDetails.terms || !regDetails.privacy) {
                toast.error("Make sure if you have read Terms & condition and Privacy policy");
                return false
            } else if (regDetails.pass !== regDetails.passConf) {
                toast.error("The password confirm does not match.");
                return false
            }
            else if (!regDetails.contactNum || !regDetails.pass) {
                toast.error("Please check the fields");
                return false
            } else {
                userRegistration()
            }
        }
    }

    const userLogin = () => {
        const loginData = {
            user_login: loginDetails.loginEmail,
            user_password: loginDetails.loginPass,
            user_firebase_token: "abcdefghijklm",
            user_device_token: "WEB",
            user_device_type: "WEB",
        }
        // Call API
        authServices
            .login(loginData)
            .then((result) => {
                if (result.status === true) {
                    Storage.set("user-token", result.data);
                    Storage.set("user-auth", result.data);
                    dispatch(UserDetails({ data: result.data }));
                    setShow(false)
                    toggleAuth()
                    handleProceed()
                } else {
                    toast.error(result.message);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    const userRegistration = () => {
        const regData = {
            user_email: regDetails.email,
            user_password: regDetails.pass,
            user_full_name: regDetails.uname,
            user_phone_number: regDetails.contactNum,
            is_registered: true,
            is_terms_conditions: true,
            is_privacy_policy: true,
        }

        // Call API
        signupQuoteService
            .signupQuotes(regData)
            .then((result) => {

                if (result.status === true) {
                    Storage.set("user-token", result.data);
                    Storage.set("user-auth", result.data);
                    dispatch(UserDetails({ data: result.data }));
                    setShow(false)
                    toggleAuth()
                    handleProceed()
                } else {
                    toast.error(result.message);
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>{showLogin ? "Sign In" : "Sign Up"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showLogin
                    ? <LoginForm loginDetails={loginDetails} setLoginDetails={setLoginDetails} />
                    : <RegistrationForm regDetails={regDetails} setRegDetails={setRegDetails} />}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" type="button" onClick={() => handleSubmit()}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(UserAuthentication);
