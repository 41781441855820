import { DataService } from './dataService'

export const galleryService = {
    
    getGallery: async () => {
        return DataService.get('/admin/get_gallery_image')
    },

    // getEnquiryapi: async (data) => {
    //     return DataService.get('/retailer/get_retailer_inquiry_list', data)
    // },
}


export default galleryService;