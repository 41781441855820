import React from "react";
import { Redirect } from 'react-router-dom'
import { store } from "../Redux/store";
import { Storage } from "../Storage";


const PublicRoutes = (props) => {

    const isLogin = store.getState().userDetails
    // const isLogin = Storage.get('user-token')
    const { isLogged } = isLogin
    let Comp = props.component

    if (!isLogged) {
        return (
            <Comp />
        )
    } else {
        return (
            <Redirect to="/" />
        )
    }
}
export default PublicRoutes;