
import { quoteService, storageService } from "../services/index";

const commonApicall = {

    getListData : (paramdata, callback) => {
        
	     quoteService.get_retailer_quote_list(paramdata).then((res) => {
            callback(res.status, res.message, res.data)
			})
			.catch((error) => {
				
                // callback(false, "something went wrong", [])
			});
	},

    getBookingListData : (paramdata, callback) => {
        quoteService.get_retailer_quote_list_updated(paramdata).then((res) => {
           callback(res.status, res.message, res.data)
        })
        .catch((error) => {
            console.log(error);
        });
   },

    
    getBookingList : (paramdata, callback) => {
        
        storageService.getBookingapi(paramdata).then((res) => {
           callback(res.status, res.message, res)
           })
           .catch((error) => {
               console.log(error);
               // callback(false, "something went wrong", [])
           });
   },

    getDiscount : (details) => {
    return Number(details.total_discount) - Number(details.customer_discount);
    },

    getCalculation : (objectData) => {
        let totalincentive, customerincentive, retailerincentive, inc_payout, g_value, amount_agreed;

        if(objectData && Array.isArray(objectData)){
            for(let val of objectData){
                if(objectData.length > 0){
                    totalincentive = Number(val.referral_token_details.total_discount);
                    customerincentive = Number(val.referral_token_details.customer_discount);
                    retailerincentive = (Number(val.referral_token_details.total_discount) - Number(val.referral_token_details.customer_discount));
                    amount_agreed = Number(val.cargo_details.agreed_amount);
                    g_value = Number(val.cargo_details.actual_value_of_goods);
                    inc_payout = Number(val.cargo_details.incentive_payout);
                }else{
                    totalincentive += Number(val.referral_token_details.total_discount);
                    customerincentive += Number(val.referral_token_details.customer_discount);
                    retailerincentive += (Number(val.referral_token_details.total_discount) - Number(val.referral_token_details.customer_discount));
                    amount_agreed += Number(val.cargo_details.agreed_amount);
                    g_value += Number(val.cargo_details.actual_value_of_goods);
                    inc_payout += Number(val.cargo_details.incentive_payout);
                }
            }
        }

        let calculateValue = {}
       return calculateValue = {
            total_incentive: totalincentive,
            customer_incentive: customerincentive,
            retailer_incentive: retailerincentive,
            incentive_payout: inc_payout,
            goods_value: g_value,
            agreed_amount: amount_agreed,   
        }
    },


    getStoreListData : (paramdata, callback) => {
        
        storageService.getStores(paramdata).then((res) => {
           callback(res.status, res.message, res.data)
           })
           .catch((error) => {
               console.log(error);
               // callback(false, "something went wrong", [])
           });
   },

   getInvoiceList : (paramdata, callback) => {     
    storageService.getInvoice(paramdata).then((res) => {
       callback(res.status, res.message, res.data)
       })
       .catch((error) => {
           console.log(error);
       });
     },

    getInvoiceDetail: (paramdata, callback) => {
      storageService
        .getDetails(paramdata)
        .then((res) => {
          callback(res.status, res.message, res.data);
        })
        .catch((error) => {
          console.log(error);
        });
      },
}

export default commonApicall;