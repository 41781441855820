import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import DatePicker from "react-datepicker";
import commonApicall from "../commonApicall";
import { toast } from "react-toastify";
import BookingTable from "./BookingTable"

export default function AllBooking() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [bookingList, setBookingList] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let param = {};
    if (startDate) {
      param["start_date"] = startDate;
    } else if (endDate) {
      param["end_date"] = endDate;
    } else if (searchValue) {
      param["search"] = searchValue;
    }

    setLoader(true);
    commonApicall.getListData(param, function (status, message, data) {
      setLoader(false);
      if (status === true) {
        setBookingList(data);
      } else {
        toast.error(message);
      }
    });
  }, [startDate, endDate, searchValue]);

  function resetFun() {
    setStartDate("");
    setEndDate("");
    setSearchValue("");
  }

  return (
    <>
      <section className="pb-5 pt-0">
        <div className="header_title">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h1>All Bookings</h1>
              {/* <button type="button" className="themebutton" >Add Stores</button> */}
            </div>
            <img
              alt="Not found"
              src="assets/images/login_bg.jpg"
              className="bannerimg"
            />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="white_box h-100 pb-1">
            <div className="filterSec">
              <div>
                <label>From</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
                <label>To</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search by Id, Store Name"
                  className="form-control"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                />
              </div>
              <div className="ms-3">
                <button className="themebutton" onClick={resetFun}>
                  Reset
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <BookingTable listdata={bookingList} />
              {bookingList && bookingList.length < 0 && (
                <p className="mb-0 p-1 bg-warning text-dark text-center">
                  No Data Available
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      <Loader isShow={loader} />
    </>
  );
}
