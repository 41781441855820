import React, { useState, useEffect } from "react";
import commonApicall from "../commonApicall";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap-v5";
import InvoiceHtml from "../../Function/Invoice";
import { jsPDF } from "jspdf";
import { renderToString } from "react-dom/server";
import PdfDownload from "../../Function/PdfDownload";

export default function RetailerInvoice() {
  const [loader, setLoader] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [showModalData, setShowModelData] = useState({
    showModal: false,
    invoiceView: 0,
  });

  const job_id = useParams();
  useEffect(() => {
    getRetailerInvoice();
    getInvoiveDetail();
  }, []);

  const getRetailerInvoice = () => {
    setLoader(true);
    commonApicall.getInvoiceList(job_id, function (status, message, data) {
      setLoader(false);
      if (status === true) {
        setInvoice(data);
      } else {
        toast.error(message);
      }
    });
  };

  const getInvoiveDetail = () => {
    setLoader(true);
    commonApicall.getInvoiceDetail(job_id, function (status, message, data) {
      setLoader(false);
      if (status === true) {
        setInvoiceDetail(data);
      } else {
        toast.error(message);
      }
    });
  };

  function timeConverter(UNIX_timestamp) {
    var timestamp = UNIX_timestamp;
    var date = new Date(timestamp);
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  const handleShow = (value, index) => {
    console.log(value, index);
    if (value === true) {
      setShowModelData({ showModal: value, invoiceView: index });
    } else {
      setShowModelData({ showModal: value, invoiceView: 0 });
    }
  };

  const downloadPdf = async (index) => {
    setLoadingPdf(true);
    const doc = new jsPDF();
    const string = renderToString(
      <PdfDownload
        invoiceItem={invoice[showModalData.invoiceView]}
        quoteToken={invoiceDetail.job_token}
        carrierData={invoiceDetail.carrier_details}
        receiverData={invoiceDetail.receiver_details}
        customerData={invoiceDetail.customer_details}
        jobId={invoiceDetail._id}
        bookingData={invoiceDetail}
      />
    );

    await doc.html(string, {
      async callback(doc) {
        doc.save("W2D_" + invoice[index].invoice_number);
      },
      html2canvas: { scale: 0.22 },
      x: 20,
      y: 5,
    });
    setLoadingPdf(false);
  };

  return (
    <>
      <section className="pb-5 pt-0 position-relative min-h-320">
        {loader ? (
          <>
            <div className="loader_lst">
              <img
                src="/assets/images/JOP.gif"
                className="bannerimg"
                alt="Not found"
              />
            </div>
          </>
        ) : (
          <>
            <div className="header_title">
              <div className="container-fluid">
                <div className="d-flex justify-content-between">
                  <h1>Retailer Invoices </h1>
                </div>
                <img
                  src="/assets/images/country.jpg"
                  className="bannerimg"
                  alt="Not found"
                />
              </div>
            </div>
            <div className="container-fluid mt-5">
              <div className="white_box storeboxing h-100 pb-1">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>INVOICE#</th>
                        <th>DATE</th>
                        <th>AMOUNT</th>
                        <th>STATUS</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoice.length > 0 &&
                        invoice.map((item, i) => (
                          <tr key={i}>
                            <td style={{ width: "20%" }}>
                              {item.invoice_number}
                            </td>
                            <td style={{ width: "20%" }}>
                              {timeConverter(item.created_date)}
                            </td>
                            <td style={{ width: "20%" }}>
                              {item.gross_amount} AED
                            </td>

                            <td style={{ width: "20%" }}>
                              <span
                                style={{ fontSize: 12 }}
                                className="badge bg-primary"
                              >
                                {item.status}
                              </span>
                            </td>
                            <td style={{ display: "flex", gap: 10 }}>
                              <Button
                                style={{ height: "auto" }}
                                onClick={() => handleShow(true, i)}
                              >
                                View
                              </Button>{" "}
                              {loadingPdf === false ? (
                                <Button
                                  style={{ height: "auto" }}
                                  onClick={() => downloadPdf(i)}
                                >
                                  Download PDF
                                </Button>
                              ) : (
                                <p className="mb-0 mt-2">Please wait...</p>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </section>

      {invoice && invoice.length > 0 && (
        <Modal
          size="xl"
          show={showModalData.showModal}
          onHide={() => handleShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Invoice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InvoiceHtml
              invoiceItem={invoice[showModalData.invoiceView]}
              quoteToken={invoiceDetail.job_token}
              carrierData={invoiceDetail.carrier_details}
              receiverData={invoiceDetail.receiver_details}
              customerData={invoiceDetail.customer_details}
              jobId={invoiceDetail._id}
              bookingData={invoiceDetail}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
