import React from "react";

export default function Faq() {
  return (
    <section className="pb-5 pt-0">
      <div className="header_title">
        <div className="container-fluid">
          <h1>FAQs</h1>
          <img
            src="/assets/images/faq.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="white_box h-100">
          <div className="row">
            {/* <div className="col-md-12">
              <form className="row">
                <label className="fw-bold mb-2">Search</label>
                <div className="col-md">
                  <div className="form-group mb-3">
                    <input
                      className="form-control rounded_input"
                      placeholder="United States of America"
                    />
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-group mb-3">
                    <select className="form-control rounded_input">
                      <option>ABOUT World 2 DOOR</option>
                    </select>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-group mb-3">
                    <button className="themebutton">Search</button>
                  </div>
                </div>
              </form>
            </div> */}
            <div className="col-md-12">
              <h2 className="pageheading">FAQ World 2 DOOR</h2>
              <div className="border-box">
                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    href="#faq1"
                    className="collapsed"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    ABOUT World 2 DOOR
                  </a>
                  <div className="collapse mb-3" id="faq1">
                    <h5 className="mt-2">1) What is World2Door Cargo LLC?</h5>
                    We are a tech company in the field of Logistics that helps customers such as tourists, residents, overseas shoppers to deliver their shopped/personal cargo internationally anywhere in the world.
                  </div>

                  <div className="collapse mb-3" id="faq1">
                    <h5>2)  Where is it located?</h5>
                    We are based out of Dubai, United Arab Emirates.
                  </div>

                  <div className="collapse mb-3" id="faq1">
                    <h5>3) What are your office working days and hours?</h5>
                    We are open from Saturday through Thursday from 09.00 am to 06.00 pm
                  </div>

                  <div className="collapse mb-3" id="faq1">
                    <h5>4) Who can use WORLD2Door?</h5>
                    Any individual or company who has items to be sent from the U.A.E to any part of the world or even within the U.A.E. can use WORLD2Door services.
                  </div>

                  <div className="collapse mb-3" id="faq1">
                    <h5>5) Can I use WORLD2Door for a holiday?</h5>
                    Yes. We can arrange delivery of your goods to your holiday destination.
                  </div>


                  <div className="collapse  mb-3" id="faq1">
                    <h5>6) How can you be contacted?</h5>
                    We can be contacted at 04-4468444 and you can also send us an email at info@WORLD2door.ae
                  </div>

                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq2"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    SERVICES OFFERED BY WORLD2DOOR
                  </a>
                  <div className="collapse  mb-3 mt-3" id="faq2">
                    <h5>1) What are the services offered by WORLD2Door?</h5>
                    WORLD2Door offers International Delivery of Goods from and to UAE. Its services include Freight Rates on multiple shipping modes like Air/Sea/Land/Courier. It also offers Insurance Coverage, Packing, Storage & Relocation services.
                  </div>

                  <div className="collapse  mb-3" id="faq2">
                    <h5>2) What is so unique about WORLD2Door services?</h5>
                    WORLD2Door offers International Delivery of Goods from and to UAE. Its services include Freight Rates on multiple shipping modes like Air/Sea/Land/Courier. It also offers Insurance Coverage, Packing, Storage & Relocation services.
                  </div>

                  <div className="collapse  mb-3" id="faq2">
                    <h5>3) What are the shipping modes you provide?</h5>
                    We provide multiple modes of shipping option to the customer. Based on the urgency and cost effectiveness, a customer can opt for any mode – sea, air, courier or land.
                  </div>

                  <div className="collapse  mb-3" id="faq2">
                    <h5>4) How can I get the quotation?</h5>
                    You can find live rates instantly on our website www.world2door.ae with all the options to the selected destination. You can also call us on 04-4468444 or send us an email for customized quote at info@world2door.ae.
                  </div>


                  <div className="collapse  mb-3" id="faq2">
                    <h5>5) How soon can I get the rates?</h5>
                    The rates are available on our website and you can access it anytime anywhere.
                  </div>

                  <div className="collapse mb-3" id="faq2">
                    <h5>6)Can you collect my packages from various locations?</h5>
                    Yes, we can collect your packages from multiple locations. Be it stores, your home or your office.
                  </div>

                  <div className="collapse  mb-3" id="faq2">
                    <h5>7)Do you consolidate packages picked up from various locations/stores?</h5>
                    Yes. We can collect all the packages from multiple locations, consolidate and can send it as one shipment.
                  </div>

                  <div className="collapse  mb-3" id="faq2">
                    <h5>8)Are there any restrictions on the size or the number of packages?</h5>
                    There is absolutely no restriction on the size or number of packages to be shipped. The size of the package allowed to ship would depend upon the mode selected and the carrier’s acceptance.
                  </div>

                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq3"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    STORAGE
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq3">
                    <h5>  1) Can I store my bags/packages with you?</h5>
                    Yes. There will be storage and handling charges based on the number of days and total area occupied by the packages.
                  </div>

                  <div className="collapse  mb-3" id="faq3">
                    <h5>  2) Can I store any items in the warehouse?</h5>
                    The General Household goods can be stored. Items declared Dangerous goods by IATA will not be accepted for storage.
                  </div>

                  <div className="collapse mb-3" id="faq3">
                    <h5>  3)Can we store liquids and foods Items?</h5>
                    We do not accept Liquids and Food Items or any Perishable items for Storage.
                  </div>
                </div>


                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq4"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    PACKAGING
                  </a>
                  <div className="collapse  mb-3 mt-3" id="faq4">
                    <h5>1) How should I prepare my suitcase or box for transport?</h5>
                    All goods should be packed in export worthy packaging (Cartons/Pallets/Crates/Bags), which should be able to sustain the entire length of the travel. Assistance with packing can be provided, if required.
                  </div>

                  <div className="collapse mb-3" id="faq4">
                    <h5>2)Are the goods specially packed by WORLD2Door?</h5>
                    If the items received from the Retailer are not transport worthy, only then WORLD2Door will advise and repack the packages.
                  </div>

                  <div className="collapse mb-3" id="faq4">
                    <h5>3) What are the types of packaging you offer?</h5>
                    We do all kinds of packing right from cartons to pallet to crate for regular and fragile items.  For very delicate cargo we have customized packing.
                  </div>

                  <div className="collapse mb-3" id="faq4">
                    <h5>4)What if we don’t want to pay for the additional packing and do it ourselves?</h5>
                    We have a professional team who knows the kind of packing particular items require. If you do not wish to pay additional charges for packing and the carrier accepts the items in its original packing, then we will ship out the package. However, if the packing is found unworthy for sea or air freight mode then it will not be accepted by the carrier till it is appropriately packed. We will inform you and after you approve and pay the additional charges for the packing, we will proceed further.
                  </div>

                  <div className="collapse  mb-3" id="faq4">
                    <h5>5)Who takes the responsibility for the damage in case the goods are not packed properly?</h5>
                    It is the customer's responsibility to pack the goods in export worthy packages. You also need to take care of internal packing. We do offer professional packing as an “Add on” service. You can opt for it on our website by paying the additional charges.
                  </div>

                  <div className="collapse  mb-3" id="faq4">
                    <h5>6)What is internal packing?</h5>
                    Internal packing is creating a cushion for the items to remain safe inside the package during the journey. Even with sturdy packing from outside items can get damaged internally if the internal packing is weak.
                  </div>

                  <div className="collapse mb-3" id="faq4">
                    <h5>7)Will my packages be opened by anyone?</h5>
                    Yes, we reserve the right to open the packages to check the contents, if we find it necessary. However, the packages could be opened by Customs Officer either at origin or destination or both, if they want to inspect.
                  </div>

                  <div className="collapse  mb-3" id="faq4">
                    <h5>8) Do I have to indicate specifically, in case I want the goods to be handled with care?</h5>
                    Yes. Our team should be notified about any delicate/fragile items. Any fragile items should be packed with proper care.
                  </div>


                  <div className="collapse  mb-3" id="faq4">
                    <h5>9)  If the packing was done by Store2Door and the package is found damaged due to which the items were damaged or stolen, will you be responsible for it?</h5>
                    We do professional packing which is good to withstand the journey. However, a package can be damaged or item can be stolen enroute the journey as it moves through multiple hands. Therefore, we recommend you do cargo insurance for any eventualities. We will not be responsible for any damage or stolen items.
                  </div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq5"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    COLLECTIONS
                  </a>
                  <div className="collapse  mb-3 mt-3" id="faq5">
                    <h5>1)What kind of addresses can you collect from and deliver to?</h5>
                    We can collect from stores, warehouses, hotels, hostels, residences etc. and deliver the same to these places at the destination.
                  </div>

                  <div className="collapse  mb-3" id="faq5">
                    <h5>2)Can you collect my packages from various locations?</h5>
                    Yes, we can collect your packages from multiple locations. Be it stores, your home or your office.
                  </div>


                  <div className="collapse  mb-3" id="faq5">
                    <h5>3)Do you consolidate packages picked up from various locations/stores?</h5>
                    Yes. We collect all the packages, consolidate and can send it as one shipment.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>4)What time my collection will take place?</h5>
                    Our customer service representative will contact you to confirm the pick time and location.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>5)Will I receive a call/text before my collection?</h5>
                    Yes. The contact person given for the pick up will be called before the collection.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>6)Can I change my collection date?</h5>
                    Yes. We must be intimated about the change by mail or phone before the collection is arranged.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>7)Can I change my collection name, collection address or telephone number?</h5>
                    Yes. You may change the collection address before the collection is arranged.
                  </div>


                  <div className="collapse mb-3" id="faq5">
                    <h5>8)What if I miss the pick up?</h5>
                    You will need to call us again to rebook the pickup and it may incur additional charges.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>9)Do you collect on weekends or Bank Holidays?</h5>
                    We do not collect on the weekends and on Bank Holidays; however, we can make an exception on case to case basis. Additional charges may apply.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>10)Are collection time slots available?</h5>
                    Usually the collection times will be between 9 am to 6 pm (Saturdays through Thursdays)
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>11)Do you offer same day collections?</h5>
                    Yes. We can arrange same day collection on a working day; however, we need to be informed before 4:00 pm same day. There will be additional charges for express/same day pick up.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>12)Can I update my collection time slot?</h5>
                    Yes. It should be done a day before collection.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>13)My address is difficult to find and is not very accessible, can I provide additional access information?</h5>
                    Yes. You may call our helpline and provide the details.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>14)Can you collect from a business address?</h5>
                    Yes. We can collect from a business address.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>15)Can I drop my bag off?</h5>
                    Yes. You can drop off the packages at our warehouse.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>16)Do I need to be present when my bag is collected?</h5>
                    No. It is not required for you to be present when your bag is collected.
                  </div>

                  <div className="collapse mb-3" id="faq5">
                    <h5>17)What if I need to go out on the collection day?</h5>
                    You may change the date of collection a day before or leave the package with someone for us to collect.
                  </div>

                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq6"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    PAYMENTS
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq6">
                    <h5>1)What payment methods do you accept?</h5>
                    You can make the payment online on our website. We accept debit card, credit card and cash as well. You may also transfer the payment directly to our bank account or deposit cash into our account.
                  </div>

                  <div className="collapse mb-3 " id="faq6">
                    <h5>2)How do I make an outstanding payment?</h5>
                    You can make the payment online on our website by a credit card or a debit card. We accept all cards. We can also accept payment in cash or you can transfer the payment to our bank account directly.
                  </div>

                  <div className="collapse mb-3 " id="faq6">
                    <h5>3)When do I have to pay for the shipping?</h5>
                    You can pay at the time of placing the booking which will be considered as an advance payment. If you haven’t made the advance payment, then we need you to make the payment in full before shipment is moved.
                  </div>

                  <div className="collapse mb-3" id="faq6">
                    <h5>4)Do you hold credit card details?</h5>
                    No. We do not hold any credit card details.
                  </div>

                  <div className="collapse  mb-3 " id="faq6">
                    <h5>5)Why has my card been declined?</h5>
                    You will need to check with your bank or your service provider.
                  </div>

                  <div className="collapse  mb-3" id="faq6">
                    <h5>6)Can I pay in cash at the time of delivery?</h5>
                    To safeguard ourselves, as a company policy, we collect the entire payment as soon as the booking is confirmed and then ship your packages.
                  </div>

                  <div className="collapse  mb-3" id="faq6">
                    <h5>7)Can I pay by cheque or cash?</h5>
                    Yes, you may pay by current dated cheque or cash in full. In case of cheque payments, the shipment will be executed only after the cheque has been encashed.
                  </div>

                  <div className="collapse  mb-3" id="faq6">
                    <h5>8)Can I make part payments/installments?</h5>
                    You can make the part payments or advance payments; however, the shipment will be processed and shipped out after the complete payment is received.
                  </div>

                  <div className="collapse mb-3" id="faq6">
                    <h5>9)In what currency do you accept the payments?</h5>
                    We accept payments only in Dirhams (AED).
                  </div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq7"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    INSURANCE AND CLAIMS
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq7">
                    <h5>1)How much is the insurance premium?</h5>
                    The acceptance of insurance proposal & Insurance Premium will depend on the value & nature of goods. You may check the charges by going on to the website and entering the value of the Goods.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>2) How much should the package be insured for?</h5>
                    The package should be insured for the present value of the goods + the freight amt.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>3)Do I get the full value back for the damaged goods?</h5>
                    Yes. After the assessment by the insurance company that the value of the damaged goods declared by the customer is correct and not over valued the full claim amt is settled deducting only the Excess Fee which is currently Aed 500/- for processing the claim.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>4)How do you calculate the insurance premium fee?</h5>
                    A certain percentage is considered based on the invoice value of the goods and the freight cost. Kindly visit our website to know the exact premium for your cargo.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>5)Is it mandatory to take insurance?</h5>
                    Insurance is not mandatory; however, we strongly recommend insuring your goods to have coverage in case of eventualities.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>6)What is the coverage?</h5>
                    Loss/Damage to your goods as per the policy Terms & Conditions.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>7)Can I add the insurance later?</h5>
                    It is ideal to incept coverage prior to commencement of shipment from Store/Origin. However, you can opt for it later but the coverage will be from the commencement date of the policy.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>8)How do I claim for the insurance in case of damage, missing or stolen items?</h5>
                    You can write to us at info@world2door.ae to register the claim with insurance company with your booking number in the subject and from thereon, we will guide you.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>9)How many days does it take for the insurance to process?</h5>
                    It takes one day to open the claim file. There is no definite time-frame given by the insurance company in which they can complete the investigation. However, once the claim is approved, it takes upto 10-15 days to get the insurance claim amount subject to submission of required documents.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>10)Can insurance replace the damaged item for a new item?</h5>
                    Policy will indemnify for your loss/damage. If the damaged item is at beyond repair conditions and/or not economical to repair, insurance company as per the assessment shall declare it as ‘Total Loss’ at their own discretion and settle the claim.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>11)Do I get the claim money in the account or in cheque?</h5>
                    It can be a cheque or an online transfer.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>12)Where can I find the claim processing form?</h5>
                    WORLD2Door will provide the claim processing form upon request.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>13)What is the name of the insurance company?</h5>
                    The current insurance company is AXA Insurance Gulf BSC. We will keep you informed in case the insurance company changes in the future.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>14)Is the insurance only for new items or also for used items?</h5>
                    The insurance can be done for both old and new items. The premium; however, will be higher for the old/used items.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>15)Till when is the insurance valid?</h5>
                    The insurance is only valid from the date of issue of policy till it reaches the port of destination/door delivery requested. In door delivery cases, if the shipment has reached the destination port and not collected within 30 days, the policy seizes to exist. Where the customer wants the services only up to the port of destination, the insurance seizes to exist once it reaches the final port.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>16)My contents have been damaged, how do I claim for the damage?</h5>
                    When you receive the goods in damaged condition please put a remark on the delivery note that the shipment has been received in damaged condition while receiving the package and take a picture as proof. You may then contact us and we will guide you through the process of claim.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>17)Is the insurance transferrable?</h5>
                    No. The insurance is not transferrable.
                  </div>

                  <div className="collapse mb-3" id="faq7">
                    <h5>18)Can I cancel the insurance?</h5>
                    If the insurance policy has been issued, it CANNOT be cancelled.
                  </div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq8"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    QUOTATIONS
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq8">
                    <h5>1) Is Sea mode the cheapest mode of shipping?</h5>
                    No. For smaller sized items Courier is the fastest and the cheapest option. The next best is Airfreight which are still cheaper than Sea frt for slightly bigger shipments. But Sea frt mode is best for large shipment. But to check which is the fastest or cheapest mode check the rates on WORLD2door App/Wesbite as it will vary country to country.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq8">
                    <h5>2) Can the rates mentioned on the website be negotiated?</h5>
                    Our rates are very economical and therefore fixed. But you may contact WORLD2door for better rates.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq8">
                    <h5>3)  Can I save the quotation and lock the pricing?</h5>
                    You can save the quotation and retrieve it from “My Quotations” section on our website by logging in to your account. However, it is imperative for you to “Re-validate” your quote when you decide to place the booking. In general, the quote is valid for 7 days from the date of initial quotation, if no changes have been made to the cargo details, destination or/and mode of shipment.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq8">
                    <h5>4) I can’t see the rates up to the door delivery?</h5>
                    If you don’t see the rates upto the door then you may write to us at info@world2door.ae  or click on “Customized quote request” on the website.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq8">
                    <h5>5) I want door delivery but find the rates very high?</h5>
                    We have very competitive rates; however, if you find the rates too high for door to door delivery, you can opt for shipping from door to port. At destination, you can do the clearance yourself or appoint a clearing agent on your behalf. You may contact WORLD2door for better rates.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq8">
                    <h5>6)Can I get the quotation in my country’s currency?</h5>
                    We can manually provide the rates in the requested currency of your country; however, the payment must be settled in Dirhams (AED) only.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq8">
                    <h5>7)Besides freight charges, do you also provide quotations for other services?</h5>
                    Yes, besides freight we can quote you for our “Add-On” services such as warehousing, insurance and packing.
                  </div>

                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq9"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    DOCUMENTS REQUIRED
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq9">
                    <h5>1) What are the documents required?</h5>
                    The standard documents required are a clear scanned copy of your passport, visa page, Emirates ID (U.A.E. nationals & U.A.E. residents only). The mandatory documents will depend upon destination country rules and regulations.</div>

                  <div className="collapse mb-3 mt-3" id="faq9">
                    <h5>2)Do you need any documents related to the goods?</h5>
                    We need the copy of the purchase invoice in case of the new items. For household items, a detailed packing list of the items is required.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq9">
                    <h5>3)Do I need to provide my passport copy?</h5>
                    The customs need to relate the goods to you and therefore, a copy of the passport is required as proof.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq9">
                    <h5>4)I do not have the purchase invoice/bill. Can I still send the packages through you?</h5>
                    Please provide us the value of the item and we will prepare the shipping documents accordingly.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq9">
                    <h5>5)How do I submit the documents to you?</h5>
                    You can simply log into your account with Store2Door and upload the required documents from “My Documents” section.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq9">
                    <h5>6)Where can I find my shipping documents (Insurance policy copy, bill of lading, airway bill etc.)?</h5>
                    You can view and download the shipping documents from “My Documents” section of your Store2Door account.
                  </div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq10"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    TRACKING
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq10">
                    <h5>1) How do I track my order?</h5>
                    You can track the progress of your shipment from our website www.world2door.ae with the booking reference number.</div>

                  <div className="collapse mb-3 mt-3" id="faq10">
                    <h5>2)It’s been more than 24 hours and the status of the shipment hasn’t changed?</h5>
                    Please check your emails for communication from us as all the updates will be sent automatically to your mailing address.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq10">
                    <h5>3)The website is down and I am unable to track the order?</h5>
                    In such instances when our website is down for some upgrade or maintenance purposes, you can send us an email at info@world2door.ae with your booking reference number in the subject line and we will revert to you with the status.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq10">
                    <h5>4)The tracking shows that my order has been shipped, I want to add a few more items, can you please hold the shipment back?</h5>
                    Once the order has be shipped out, there is no way we can hold it back or modify any of the details. You can simply place a new order with us in case you want to ship more items.</div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq11"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    CUSTOMS
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>1)Do customs affect me?</h5>
                    Usually all new items attract duty and taxes as per the tariffs in their respective countries. But some countries are more lenient than others and may allow some cargo to be cleared without payment of duty which will be completely at the discretion of the Customs Department.</div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>2)Do I need to pay Customs Duty and Taxes?</h5>
                    Yes. You need to pay Customs Duty and Taxes at the destination. Usually old and used personal effects are exempted from Duty or a duty discount is given depending upon the country of import, but it will be at the discretion of the customs. New Items will attract customs duty and taxes.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>3) What Information do I need to provide to achieve duty free clearance?</h5>
                    Usually all new items attract duty as per the tariffs in their respective countries. Only household goods that are considered as old and used are exempted from duty.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>4)What is the 'Declared Value for Customs'?</h5>
                    Declared Value for Customs is the correct and fair value of the goods to be declared to customs.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>5)How much customs duty will I need to pay?</h5>
                    Customs Duty will be as per the import tariffs of their respective countries.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>6)How long will custom clearance take at destination?</h5>
                    Usually custom clearance at destination takes 2-3 days for air shipments and 5 – 7 days for sea shipments. However, it can take more than usual if the customs need additional information or clarification.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>7)Can I send commercial items?</h5>
                    No. Individuals are not allowed to send commercial items.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>8)Why is my shipment still in customs?</h5>
                    If the package has been held back at customs, it could mean customs have some reservations on the value of the goods or the goods itself.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>9)Customs have contacted me directly. What do I do now?</h5>
                    Mostly customs might call you if they need some clarification. If they are satisfied with your reply they will release the shipment or else they will call you to their office for further clarification.
                  </div>


                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>10)I have received a bill from customs, how do I dispute this?</h5>
                    You will have to approach the customs office and explain to the customs officer with the proof of purchase that the declared value and item are correct.
                  </div>


                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>11)Why do I need to upload a copy of my flight ticket / passport / work permit /visas?</h5>
                    This is to identify that you are a legal person staying in the destination country and to relate the packages belong to you.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>12)What if I am unable to provide a flight ticket, passport, visa etc.?</h5>
                    If any of the standard and mandatory documents are not provided, then the shipment cannot be shipped.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>13)Whose name needs to appear on the customs paperwork?</h5>
                    The receiver’s name will appear on the custom’s paperwork.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>14)Can the shipment be delayed at the customs?</h5>
                    Yes. If the customs officer has some doubts or suspects something wrong, he can hold the shipment for inspection.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>15)Will I have to pay storage charges due to customs delay?</h5>
                    Yes, you will be liable to pay any charges including storage, packing/re-packing charges due to customs inspection.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq11">
                    <h5>16)Customs Duty and taxes is charged on what value?</h5>
                    Customs Duty and taxes are charged on purchase value of goods + freight + Insurance( CIF). If the customer gets a discount on the purchased items ,the duty/taxes will still be calculated on the Original value of goods and not on discounted value as the discount is on the value of the product but there is no discount on the duty/taxes.
                  </div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq12"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    List of Prohibited Items
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>1)What can I send? Is there anything I can't send?</h5>
                    WORLD2Door does not send Dangerous goods/ Perishable Goods / Food Items / Currency/Jewelry, prohibited items at destination country etc. Please refer to the General Prohibited List on our website or App.</div>

                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>2)Can I send toiletries and other liquids?</h5>
                    WORLD2Door does not carry toiletries and liquids.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>3)Can I send laundry bags, bin liners or plastic storage boxes?</h5>
                    Yes. You may send the above-mentioned items if these are packed in export worthy packing (Cartons/Pallets/Crates) etc.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>4)Can I send a laptop, TV, phone, printer or other electrical items?</h5>
                    Yes. One battery per unit, ideally fitted into the unit is allowed. Loose batteries are not allowed.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>5)Can I send sports equipment such as a bicycle, skis or golf clubs?</h5>
                    Yes. If the sports equipment are packed in export worthy packing that is acceptable by the carrier.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>6)Can I send food?</h5>
                    No. We do not carry food items or any perishable items.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>7)Can I send musical instruments such as Guitars?</h5>
                    Yes. If the musical instruments are packed in export worthy packing that is acceptable by the carrier.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq12">
                    <h5>8)Can I ship batteries?</h5>
                    No. Only batteries are not allowed.
                  </div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq13"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    DELIVERY
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>1)What kind of addresses can you collect from and deliver to?</h5>
                    We can collect from most places in the U.A.E. and deliver to most parts of the world. The embargo countries/war inflicted countries are avoided.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>2)How do I know when my bag will be out for delivery?</h5>
                    Our team at the destination will be calling you before arranging the delivery.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>3)Can I change my delivery name, delivery address or telephone number?</h5>
                    You may do it before delivery of the shipment so that any difference in billing can be paid before dispatch.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>4)How long it will take for my package to arrive?</h5>
                    The approximate transit time for each service is mentioned in your quotation. The transit times will be subject to no delay in customs and availability of space on the carriers. The delay could happen due to force majeure as well as natural or man-made calamities.</div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>5)What time will my package be delivered? Will I receive a telephone call?</h5>
                    es, you will receive a call before delivery of the package.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>6)What do I do if I miss the delivery driver?</h5>
                    Please call back again to re-arrange the delivery. This may attract additional transportation charges.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>7)Can I re-arrange a failed delivery?</h5>
                    Please call back again to re-arrange the delivery. This may attract additional transportation charges.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>8)It is my estimated delivery date and my package hasn't been delivered?</h5>
                    You may track the status of you booking on our website or you may write to us at info@world2door.ae
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>9)Can someone else sign for my package? Do I have to be there?</h5>
                    We need an authorization email at infor@world2door.ae instructing us to hand over the packages to another person.</div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>10)Can I change my delivery date?</h5>
                    Yes. You may change the delivery date but there could be storage charges depending on the length of the delay.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>11)Can I collect my cargo?</h5>
                    Yes. You may come to our warehouse and collect the cargo.</div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>12)Why haven’t all my items been delivered?</h5>
                    If all the items are not delivered, intimate us immediately at info@world2door.ae and we will investigate why all the items were not delivered.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>13)Can I claim for late delivery?</h5>
                    No.  Usually deliveries are on time unless delay due to customs.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>14)My bag is showing as delivered but I haven’t received it?</h5>
                    Please send us an e mail to cross check the status of shipment.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>15)I keep missing my delivery, what can I do?</h5>
                    Please request someone else to receive the delivery on your behalf with an email to us authorizing to deliver or you may come and collect your package from our warehouse at your convenience within the warehouse timings.
                  </div>


                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>16)Can you hold my package? Do you offer a storage service?</h5>
                    Yes. We can hold your package for long term storage. Please contact us for charges.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>17)What if the driver cannot access my address?</h5>
                    You may provide an alternative address or come and collect the package from our warehouse.0
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq13">
                    <h5>18)Can I redirect to a different delivery address?</h5>
                    Yes. You may change the final delivery address. If the distance is more than the earlier distance, additional charges will be applicable.
                  </div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq14"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    REFUNDS & CANCELATIONS
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq14">
                    <h5>1)Can I cancel the booking in case I change my mind?</h5>
                    We’d like to know what is it that made you change your mind. If there is a solution to the problem, we can get it fixed right away. If not, you may cancel the order by sending us an email at info@world2door.ae or call us at 04-4468444 at no cost before the order is collected.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq14">
                    <h5>2)Can I cancel after my order is picked up from the store/home/hostel/office?</h5>
                    Any cancelation post the package has been picked up, can be canceled after settling of cancelation fee which includes transportation & handling charges.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq14">
                    <h5>3)Can I cancel the booking after the export custom clearance is done?</h5>
                    We custom clear the shipment only after you finalize the order and make the payment. If you decide to cancel for any reason after the custom clearance is done, we cannot do it and the shipment will be shipped out.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq14">
                    <h5>4)Can I return the order if I receive it in damaged condition/for repairs?</h5>
                    Returning the damaged item depends on the terms agreed with the store. If you have insured the goods then you must contact the company immediately as opposed to shipping it back. There will be additional shipping cost to return the order and most often it is very expensive. </div>

                  <div className="collapse mb-3 mt-3" id="faq14">
                    <h5>5)Do I need to cancel the order if I decide to change the mode of shipment?</h5>
                    If you decide to change the mode of shipment from air to sea or sea to courier etc., you can send us a request on info@world2door.ae or call us at 04-4468444 for assistance.                   </div>

                  <div className="collapse mb-3 mt-3" id="faq14">
                    <h5>6)If I cancel the order after the payment is done, how will I get the refund?</h5>
                    The refund will be settled as per our cancellation policy. Please go through our Cancellation/Refund Policy on the website for more detailed information.</div>
                </div>

                <div className="faq_wrapper">
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    href="#faq15"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    MISCELLANEOUS
                  </a>
                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>1)What is a heavy weight shipment?</h5>
                    Any single piece weighing 50 kgs or above is considered as a heavy weight shipment.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>2)What is the maximum dimensions allowed?</h5>
                    For airfreight shipments the maximum dimensions allowed are 300 cms in length x 250 cms in width x 155 cms in height. If the height exceeds 155 cms, then airlines will charge more.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>3)What to do if I decide to make changes after placing the booking?</h5>
                    You can simply call us during the working hours at 04-4468444 to make the changes or write to us at info@world2door.ae mentioning the changes you want.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>4)Why is WORLD2Door asking me to make changes to my booking?</h5>
                    WORLD2Door will never ask you to make changes to your booking unless it is necessary. For e.g. we may ask you to change the shipping mode from air to sea, if the airline is not able to carry the shipment due to weight / dimensions restrictions or in case of cancellation of any route. In certain cases, we may ask you to opt or change the packing style incase the packages are not suitable for transport. In any of these case we will not make the changes without your authorization.</div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>5)How is the freight calculated?</h5>
                    The freight is calculated and charged to you based on the actual weight or volume weight whichever is higher. The weight and dimensions taken in our warehouse will be considered as final.</div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>6)What is volume and volume weight?</h5>
                    The amount of space that an object occupies is called volume. The airlines has a fixed conversion of volume to volume wt for calculating the freight rate. E.g. Courier companies consider 1cbm as 200 kgs while regular airlines consider 1cbm as 167 kgs.</div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>7)What is CBM?</h5>
                    CBM stands for “Cubic meter” which is a measurement of volume. This measurement is calculated by using formula L x W x H  meters.  In case the dimensions are taken in centimeters then the formula will be L x W x H cms/1000000</div>


                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>8)When do I use a volume calculator?</h5>
                    A volume calculator has been provided to help you find the volume (Cbm) in case you do not have the volume of the shipment.</div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>8)Why is calculating Volume important?</h5>
                    Calculating volume is important because all the transport carriers will charge the freight either on volume or weight whichever is higher.
                  </div>

                  <div className="collapse mb-3 mt-3" id="faq15">
                    <h5>9)My packages didn’t arrive as per the transit time mentioned on the website? Will you compensate in any way for late delivery?</h5>
                    The transit time mentioned on the website are only estimates. The transit time mentioned are subject to change due to various factors involved in the process of shipping your packages. There may be one or multiple factors causing the shipment to delay which are beyond our control. Hence WORLD2door will not be responsible for any compensation.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
