import React, { useEffect, useState } from "react";
import { galleryService } from "../../services/galleryService";
import blogService from "../../services/blogService";
import { useHistory, useParams } from "react-router-dom";

const BlogDetail = () => {
    const [blogs, setBlogs] = useState([]);
    const [loader, setLoader] = useState(false)


    let { id } = useParams();
    let img_url = process.env.REACT_APP_API_BASEURL;
  
    const getData = async () => {
      setLoader(true)
      await blogService
      .getsingleBlog(id)
      .then((res) => {
          setLoader(false)
          setBlogs(res.data);
        })
        .catch((error) => {
          setLoader(false)
          console.log(error);
        });
    };
  
    useEffect(() => {
      getData();
    }, []);

    const formatDate = (objectId) => {
        const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
        const date = new Date(timestamp);
    
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString(undefined, options);
      };
  return (
    <section className="pt-0 bg-white">
      <div className="header_title" style={{ minHeight: "450px" }}>
        <div className="container-fluid">
          <h1 className="text-center">News & Article</h1>
          <img
            src="/assets/images/gallerybanner.png"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container mt-4">
        <div className="row" style={{ marginTop: "165px" }}>
        {
          loader && (<div className="loading_spin text-center bg-transparent">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>) || 
          (blogs &&
            blogs.length > 0 &&
            blogs.map((item, index) => {
              return (
                <div className="col-lg-12 col-md-12  mb-4">
                  <div className="h-100">
                    <div>
                      <img
                        // style={{ borderRadius: "20px 20px 0px 0px" }}
                        // src="/assets/images/blog.png"
                         src={img_url + "/" + item?.image}
                        className="w-100"
                        alt="Not found"
                      />
                      <div className="p-3">
                        <p className="mb-0">
                          {/* <span
                            className="mb-2"
                            style={{
                              color: "#48B0BC",
                              border: "0.54px solid #D9D9D9",
                              borderRadius: "10px",
                              padding: "3px 12px",
                            }}
                          >
                            MARKETING
                          </span> */}
                          <span className="d-inline-block"><li>{formatDate(item._id)}</li> </span>
                        </p>
                        <h1 style={{color : "#212529"}}>{item?.title}</h1>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }))
        }
        </div>
      </div>
      <div className="header_title" style={{ minHeight: "450px" }}>
        <div className="container-fluid">
          <div className="col-sm-6" style={{ paddingLeft: "85px" }}>
            <h1 style={{ fontSize: "34px" }}>
              We will take care of all your moving needs from A to Z!
            </h1>
            <p>
              Magnis diam montes vehicula non habitant parturient sed. Nullam
              fermentum velit cursus tempor leo litora augue nec. Laoreet est
              sem cursus libero semper sed nulla purus ut habitant potenti.
              {/* <div className="d-flex justify-content-center"> */}
              <button
                className="btn getquoteBtn mt-4 mb-3 mx-2 d-block ms-0"
                // onClick={() => handleShow()}
              >
                <img
                  src="/assets/images/arrow_right.svg"
                  alt="Not found"
                  height={12}
                />
                <span className="px-2 d-inline-block fw-bold p-0">
                  Discover More
                </span>
              </button>
              {/* </div> */}
            </p>
            {/* <button>abc</button> */}
          </div>
          <img
            src="/assets/images/galleryfooter.png"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
    </section>
  )
}

export default BlogDetail