import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { quoteService } from '../../../services';

const getPlaceData = (address) => {
    return geocodeByAddress(address).then(results => {
        const contrypick = results[0].address_components.filter((item) => item.types.indexOf("country") >= 0)
        const citypick = results[0].address_components.filter((item) => (item.types.indexOf("locality") >= 0 || item.types.indexOf("administrative_area_level_1") >= 0))

        return getLatLng(results[0]).then(latlngRes => {
            let pl = {
                country: contrypick[0].long_name,
                country_short_name: contrypick[0].short_name,
                city: citypick[0].long_name,
                latitude: latlngRes.lat,
                longitude: latlngRes.lng
            }

            return pl
        })
    })
}

export const getPlaceInfo = (place) => {
    return getPlaceData(place).then(e => {
        return {
            origin_country: e.country,
            origin_country_short_name: e.country_short_name,
            origin_city: e.city,
            origin_latitude: e.latitude,
            origin_longitude: e.longitude,
        }
    })
}

export const manageItems = (bookedItems, actualItemOriginal, index, itemToken) => {
    return bookedItems.map((item, idx) => {

        let sendItem = index === idx ? item : actualItemOriginal[idx]

        return {
            "item_id": "",
            "item_description": item.item_description,
            "no_of_pkgs": item.no_of_pkgs,
            "dimension": {
                "kilogram": sendItem.kilogram,
                "length": sendItem.length,
                "width": sendItem.width,
                "height": sendItem.height,
            },

            "total_volume_CBM": sendItem.total_volume_CBM,
            "total_weight_KG": sendItem.total_weight_KG,
            "item_token": itemToken[idx]
        }

    })
}

export const getFreight = async (serviceType, postData) => {
    var key = serviceType.type+'_delivery'
    var getFinalQuotezKey = key.toLowerCase()
    var k = serviceType.type.toLowerCase()

    if(serviceType.typeWay.includes('AIR')) {
        return  await quoteService.checkAirFreightApi(postData).then((result) => {
            if (result.status === true) {
                let frtAmt = result.all_data.final[getFinalQuotezKey][`discount_${k}_grant_total`]
                return frtAmt
            }
        });
    }

    if(serviceType.typeWay.includes('SEA') ) {
        return await quoteService.checkSeaFreightApi(postData).then((result) => {
            if (result.status === true) {
                let frtAmt = result.all_data.final[getFinalQuotezKey][`discount_${k}_grant_total`]
                return frtAmt
            }
        });
    }

    if(serviceType.typeWay.includes('COURIER')) {
        return await quoteService.checkCourierFreightApi(postData).then((result) => {
            if (result.status === true) {
                let frtAmt = result.all_data.final[getFinalQuotezKey][`discount_${k}_grant_total`]
                return frtAmt
            }
        });
    }

    if(serviceType.typeWay.includes('LAND')) {
        return await quoteService.checkLandFreightApi(postData).then((result) => {
            if (result.status === true) {
                let frtAmt = result.all_data.final[getFinalQuotezKey][`discount_${k}_grant_total`]
                return frtAmt
            }
        });
    }
}

export const manageItemsF2 = (bookedItems, itemToken) => {
    return bookedItems.map((item, index) => {
        return {
            "item_description": item.item_description,
            "no_of_pkgs": item.no_of_pkgs,
            "after_packing_dimension": {
                "kilogram": item.kilogram,
                "length": item.length,
                "width": item.width,
                "height": item.height,
            },
            "item_token": itemToken[index],
            "packing_type":item.packing_type,
            "packing_charges": item.packing_charges ? item.packing_charges : 0,
            "freight_charges": item.freight_charges ? item.freight_charges : 0,
            "total_volume_CBM": item.total_volume_CBM,
            "total_weight_KG": item.total_weight_KG,
        }
    })
}