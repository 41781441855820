import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { storageService } from "../services/storageService";
import ReactPaginate from "react-paginate";

export default function SavedQuotes() {
  const history = useHistory();
  const [quotationList, setQuotationList] = useState();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [loader, setLoader] = useState(false);

  const getDate = (date) => {
    var ori_date = new Date(date).getDate();
    var tomonth = new Date(date).getMonth() + 1;
    var toyear = new Date(date).getFullYear();
    var original_date = ori_date + "-" + tomonth + "-" + toyear;
    return original_date;
  };

  const validateDays = (days) => {
    var date = days;
    var newdate = date.split("-").reverse().join("-");
    var someDate = new Date(newdate);
    someDate.setDate(someDate.getDate() + 7); //number  of days to add, e.x. 15 days
    var dateFormated = someDate.toISOString().substr(0, 10);
    return dateFormated;
  };

  const reverseDate = (reverse) => {
    return reverse.split("-").reverse().join("-");
  };

  useEffect(() => {
    getQuotationList();
  }, []);

  const getQuotationList = (currentPage = pagination.page) => {
    let postData = {
      page: currentPage,
      limit: pagination.limit,
    };
    setLoader(true);
    storageService
      .getQuotationapi(postData)
      .then((res) => {
        if (res.status === true) {
          setLoader(false);
          let array = [...res.data];
          array.map((val) => {
            val["str_date"] = getDate(val.created_at);
            val["end_date"] = validateDays(val.str_date);
          });
          setQuotationList(array);
          setPagination({ ...pagination, ...res.pagination });
        } else {
          setLoader(false);
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (selectedObject) => {
    getQuotationList(selectedObject.selected + 1);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-striped ">
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Id</th>
                <th>Date </th>
                <th>Valid Till</th>
                <th>Type</th>
                <th>Status</th>
                <th width="200" className="text-end">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {quotationList &&
                quotationList.length > 0 &&
                quotationList.map((item, key) => {
                  return (
                    <tr>
                      <td>
                        {" "}
                        {(pagination.page - 1) * pagination.limit + (key + 1)}
                      </td>
                      <td>{item.quote_token}</td>
                      <td>{item.str_date}</td>
                      <td>{reverseDate(item.end_date)}</td>
                      <td>{item.selected_courier_type}</td>
                      <td>
                        <span className="badge bg-success">
                          {item.quote_status}
                        </span>
                      </td>

                      <td className="text-end">
                        {/* <button className="btn-sm btn btn-primary">Revalidate</button> &nbsp; */}
                        <button
                          type="button"
                          className="btn-sm btn btn-dark"
                          onClick={() =>
                            history.push(
                              `/get-quote-detail/${item.quote_token}`
                            )
                          }
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {quotationList && quotationList.length <= 0 && (
            <p className="text-dark text-center bg-warning">
              List not available
            </p>
          )}

          {quotationList && quotationList.length > 0 && (
            <ReactPaginate
              initialPage={pagination.page - 1}
              pageCount={pagination.totalRecord / pagination.limit}
              pageRange={2}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              pageClassName="page-item"
              breakClassName="page-item"
              nextClassName="page-item"
              previousClassName="page-item"
            />
          )}
        </div>
      </div>
      {loader && (
        <div className="loader_lst">
          <img
            src="/assets/images/JOP.gif"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      )}
    </div>
  );
}
