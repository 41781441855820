import React, { Component } from 'react'
import moment from "moment";
import helperFunction from '../Components/Quotation/helperFunction';
import "./invoice.css"

export class InvoiceHtml extends Component {
  state = { 
  a : [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ],
  b : [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ]}

  // inWords = (num,totalamt) => {
  //   if ((num = num.toString()).length > 9) return "overflow";
  //   let n = ("000000000" + num)
  //     .substr(-9)
  //     .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  //   if (!n) return;
  //   var str = "";
  //   str +=
  //     n[1] != 0
  //       ? (this.state.a[Number(n[1])] || this.state.b[n[1][0]] + " " + this.state.a[n[1][1]]) + "crore "
  //       : "";
  //   str +=
  //     n[2] != 0
  //       ? (this.state.a[Number(n[2])] || this.state.b[n[2][0]] + " " + this.state.a[n[2][1]]) + "lakh "
  //       : "";
  //   str +=
  //     n[3] != 0
  //       ? (this.state.a[Number(n[3])] || this.state.b[n[3][0]] + " " + this.state.a[n[3][1]]) + "thousand "
  //       : "";
  //   str +=
  //     n[4] != 0
  //       ? (this.state.a[Number(n[4])] || this.state.b[n[4][0]] + " " + this.state.a[n[4][1]]) + "hundred "
  //       : "";
  //   str +=
  //     n[5] != 0
  //       ? (str != "" && totalamt.split('.').length === 1 ? "and " : "") +
  //       (this.state.a[Number(n[5])] || this.state.b[n[5][0]] + " " + this.state.a[n[5][1]]) 
  //       : "";
  //   return str.toUpperCase();
  // };


  numToWordsDhiram = (n) => {
    if (n < 0)
        return false;
    
    let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    let double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    let below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    
    if (n === 0) return 'Zero';
    
    function translate(n) {
        let word = "";
        if (n < 10) {
            word = single_digit[n] + ' ';
        } else if (n < 20) {
            word = double_digit[n - 10] + ' ';
        } else if (n < 100) {
            let rem = translate(n % 10);
            word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;
        } else if (n < 1000) {
            word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100);
        } else if (n < 1000000) {
            word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000);
        } else if (n < 1000000000) {
            word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000);
        } else {
            word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000);
        }
        return word;
    }
    
    let result = translate(n);
    return (result.trim()).toUpperCase();
  }

  getBookingData = (arr,type) => {
    let data = 0
    for(let i =0;i<arr.length;i++){
      data += Number(arr[i].actual[type])
    }
    return  helperFunction.handleDecimalPoints(data,3)
  }
  getChargableWeight = (arr,type) => {
    let data = 0
    let tweight = this.getBookingData(arr.quote_request_items,'total_weight_KG')
    for(let i =0;i<arr.quote_request_items.length;i++){
      data += Number(arr.quote_request_items[i].actual[type])
    }
    if(arr?.shipment_details?.shipment_type === "AIR" || arr?.shipment_details?.shipment_type === "SEA"){
      data = data * 167
    }else if(arr?.shipment_details?.shipment_type === "COURIER"){
      data = data * 200
    }else if(arr?.shipment_details?.shipment_type === "LAND"){
      data = data * 250
    }
    if(data < tweight){
      data = tweight
    }
    return  helperFunction.handleDecimalPoints(data,3)
  }
 
    render() {
        const { invoiceItem, quoteToken, 
          // jobId,
           carrierData, receiverData, customerData, bookingData } = this.props
           console.log(bookingData,'bookingData')
        const itemArr = invoiceItem.item;
        let totalTaxes = itemArr.reduce(function (sum, tax) {
            return sum + tax.taxable_amount;
        }, 0);
        let totalLcAmt = itemArr.reduce(function (sum, tax) {
            return sum + tax.glc_amount;
        }, 0);
        let lcAmtTotal = itemArr.reduce(function (sum, tax) {
          return sum + tax.lc_amount;
      }, 0);
        return (
            <table style={{"width":"1000px","max-width":"900px","margin":"20px auto 10px","background-color":"#fff","padding":"15px 20px","-webkit-border-radius":"3px",
            "-moz-border-radius":"3px","border-radius":"3px","-webkit-box-shadow":"0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)",lineHeight:'15px',
            "-moz-box-shadow":"0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)","box-shadow":"0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24)","border-top":"solid 10px green"}}>
           <thead>
                <tr>
                  <th style={{"-webkit-text-align":"left","text-align":"left","padding": "9px 0px"}} colSpan={9}>
                    <div className="top-bar" style={{"display":"flex","gap":"165px","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center"}}>
                      <div className="top-image ">
                        <img style={{"max-width":"120px"}} src="https://www.world2door.com/admin/assets/images/logo_dark.png" alt='logo' />
                      </div>
                      <div className="top-content ps-3">
                        <p style={{"font-weight":"700",fontFamily:"CAVOLINIBOLD","font-size":"24px","color":"#00b251"}}>KENS WORLD2DOOR CARGO LLC</p>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{border:'1px solid black'}}>
                  <td colSpan={9} style={{padding:'0'}}>
                    <table cellPadding={0} cellSpacing={0} style={{"width":"100%","padding-top":"0"}}>
                      <tbody>
                        <tr>
                          <td style={{"padding":"0 15px","-webkit-text-align":"center","text-align":"center",backgroundColor:'#a1bfa1'}} colSpan={2}>
                            <h1 style={{"color":"#1e1e2d","font-weight":"700","margin":"0","font-size":"18px","font-family":"sans-serif","padding":"4px 0 3px"}}>TAX INVOICE</h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr style={{border:'1px solid black'}}>
                  <td colSpan={9} style={{padding:'0'}}>
                    <table cellPadding={0} cellSpacing={0} style={{"width":"100%","padding-top":"0"}}>
                      <tbody>
                      <tr>
                          <td style={{"font-size":"15px","padding":"4px 2px","width":"50%","font-weight":"600","color":"#000","font-family":"system-ui",borderRight:'1px solid black',textAlign:'center'}}>
                            INVOICE NUMBER : {invoiceItem.invoice_number}</td>
                          <td style={{"font-size":"15px","padding":"4px 2px","font-weight":"600","color":"#000","width":"50%","font-family":"system-ui",textAlign:'center'}}>
                            INVOICE DATE : {moment(invoiceItem.created_date).format("DD-MM-YYYY")}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr style={{border:'1px solid black'}}>
                  <td colSpan={9} style={{padding:'0'}}>
                    <table cellPadding={0} cellSpacing={0} style={{"width":"100%","padding-top":"0"}}>
                      <tbody>
                        <tr style={{lineHeight:'12px'}}>
                          <td style={{"font-size":"15px","padding":"5px 2px","width":"50%","font-weight":"600","color":"#000","font-family":"system-ui",
                          "border-right":"1px solid #000"}}>
                            KENS WORLD2DOOR CARGO LLC</td>
                          <td style={{"font-size":"15px","padding":"5px 2px","width":"50%","font-weight":"600","color":"#000","font-family":"system-ui",}}>
                            Customer : {customerData?.name}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}>{customerData.name}</p> */}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            Suite No. 508, Office No. 24,</td>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Address : {bookingData?.user_detail[0]?.address}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}>{bookingData?.user_detail?.address}</p> */}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            Dusseldorf Business Point Building, Al Barsha 1</td>
                            <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                              {receiverData?.city && receiverData.city+"," || ''}
                              {receiverData?.state  && receiverData.state+","  || ""}
                              {receiverData?.country && receiverData?.country+',' || ''} {receiverData?.postal_code || ''}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            Dubai - UAE</td>
                            <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Ph : {customerData.mobile_number && customerData?.mobile_number.includes('undefined') && '+'+ customerData.mobile_number.split('undefined')[1] || customerData?.mobile_number}
                            </td>
                      
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            PH : +97144468444</td>
                            <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Email : {customerData.email}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            Email : info@world2door.com</td>
                            <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Region : DUBAI
                            </td>
                        
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            Office VAT ID(TRN) : 100480781200003</td>
                        <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            VAT ID (TRN) : {bookingData?.user_detail[0]?.taxation_details}
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr><tr>
                  <td colSpan={2} style={{backgroundColor:'#a1bfa1',textAlign:'center',border:'1px solid black',
                color:"#1e1e2d",fontWeight:"700",fontSize:"17px",fontFamily:"sans-serif",padding:"8px 0"}}>
                        SHIPMENT DETAILS
                  </td>
                </tr>
                <tr colSpan={3} style={{border:'1px solid black'}}>  
                  <td style={{"width":"50.1%",padding:'0'}}>
                    <table cellPadding={0} cellSpacing={0} style={{"width":"100%",borderRight:"1px solid #000000","margin-top":"0px",
                  }}>
                      <tbody>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                            Port of Loading : {carrierData.port_of_loading ? carrierData.port_of_loading : ""}</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                            Port of Discharge : {carrierData.port_of_discharge ? carrierData.port_of_discharge : ""}</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                            Place of Booking: Dubai</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                            Shipper : {customerData.sipper}</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                            Consignee : {receiverData.receiver_name.toUpperCase()}</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                            Sales Person : { bookingData.general_details.sales_person ? bookingData.general_details.sales_person.contact_name : ""}</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui"}}>
                            Customer Service :  {bookingData.general_details ? bookingData.general_details.customer_service : ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={{width:"50%",padding:'0'}}>
                    <table cellPadding={0} cellSpacing={0} style={{margin:'0',"width":"100%",height:'100%'}}>
                      <tbody>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Job Reference No : {quoteToken}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}></p> */}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                          MAWB / MBL No : {bookingData?.carrier_details?.m_awb_m_b_l || <span>&nbsp; &nbsp; &nbsp;</span>  } &nbsp; &nbsp; | &nbsp; &nbsp; HAWB / HBL No : {bookingData?.carrier_details?.m_awb_h_b_l}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Customer Reference : {bookingData?.general_details?.referral_name && bookingData.general_details.retailer_name || bookingData.general_details.referral_code}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}></p> */}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            No Of Packages : {bookingData?.quote_request_items && this.getBookingData(bookingData?.quote_request_items,"no_of_pkgs")}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}></p> */}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Gross Weight : {bookingData?.quote_request_items && this.getBookingData(bookingData?.quote_request_items,"total_weight_KG") + "KG"}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}></p> */}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Chargeable Weight (KG) : {bookingData?.quote_request_items && this.getChargableWeight(bookingData,"total_volume_CBM") + "KG"}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}></p> */}
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            Volume : {bookingData?.quote_request_items && this.getBookingData(bookingData?.quote_request_items,"total_volume_CBM") + "CBM"}
                            {/* <p style={{"margin-top":"0","display":"inline-block","font-weight":"400","color":"#455056","margin-bottom":"0"}}></p> */}
                            </td>
                        </tr>
                        {/* <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui",opacity:'0'}}>
                            f
                            </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </td>
                </tr>   
                {/* <tr>
                  <td style={{"height":"16px"}} colSpan={9} />
                </tr> */}
                <tr>
                  <td colSpan={9} style={{"border":"1px solid black",padding:'0'}}>
                    <table style={{"width":"100%",borderCollapse:"collapse"}}>
                      <thead>
                        <tr style={{"width":"100%",textAlign:'center'}}>
                          <th className='invoice-table-th' style={{"-webkit-text-align":"left","text-align":"left"}}>SR</th>
                          <th className='invoice-table-th' style={{"font-family":"system-ui"}} >Description</th>
                          <th className='invoice-table-th' style={{"font-family":"system-ui"}}>FC Amt</th>
                          <th className='invoice-table-th' style={{"font-family":"system-ui"}}>Curr</th>
                          <th className='invoice-table-th' style={{"font-family":"system-ui"}}>Ex Rate</th>
                          <th className='invoice-table-th' style={{"font-family":"system-ui"}}>LC Amt</th>
                          <th className='invoice-table-th' style={{"font-family":"system-ui"}}>Vat%</th>
                          <th className='invoice-table-th' style={{"font-family":"system-ui"}}>Vat Amt</th>
                          <th className='invoice-table-th-last-child' style={{"font-family":"system-ui"}}>Total LC</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceItem.item.map((val, index) => {
                              return (
                                  <tr style={{"width":"100%",textAlign:'center'}}>
                                      <td  style={{border:"1px solid black","padding":"5px 5px",borderLeft:'0'}}>{index + 1}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px",textAlign:'left'}} >{val.description || val.item_name}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px"}}>{val.rate_per_qty.toFixed(2)}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px"}}>{val.currency?.cc}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px"}}>{val.ex_rate}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px"}}>{val.lc_amount.toFixed(2)}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px"}}>{val.vat === 2 || val.vat === 4 ? 0 : 5}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px"}}>{val.taxable_amount.toFixed(2)}</td>
                                      <td  style={{border:"1px solid black","padding":"5px 5px","-webkit-text-align":"end","text-align":"end",borderRight:'0'}}>
                                        {val.glc_amount.toFixed(2)}</td>
                                  </tr>

                              )
                          })}
                        <tr style={{"width":"100%"}}>
                          <td style={{border:"1px solid black",borderLeft:'0'}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{"border":"1px solid black","padding":"5px 5px",textAlign:'center'}}>{lcAmtTotal && lcAmtTotal.toFixed(2)}</td> 
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{"border":"1px solid black","padding":"5px 5px",textAlign:'center'}}>{totalTaxes.toFixed(2)}</td> 
                          <td style={{"border":"1px solid black","padding":"5px 5px","-webkit-text-align":"end","text-align":"end",borderRight:'0'}}>{totalLcAmt.toFixed(2)}</td> 
                        </tr>
                        <tr>
                          <td style={{"height":"16px"}} />
                        </tr>
                        <tr>
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td style={{"padding":"5px 5px","font-family":"system-ui","font-weight":"700",textAlign:'right'}}>Total LC Amount : </td>
                          <td style={{"-webkit-text-align":"end","text-align":"end","padding":"5px 5px","font-family":"system-ui",}}>
                            {totalLcAmt.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td style={{"padding":"5px 5px","font-family":"system-ui","font-weight":"700",textAlign:'right'}}>VAT Amount : </td>
                          <td style={{"-webkit-text-align":"end","text-align":"end","padding":"5px 5px","font-family":"system-ui"}}>
                            {totalTaxes.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td /> 
                          <td style={{"padding":"5px 5px","font-family":"system-ui","font-weight":"700",textAlign:'right'}}>Grand Total : </td>
                          <td style={{"-webkit-text-align":"end","text-align":"end","padding":"5px 5px","font-family":"system-ui",fontWeight:'bold',}}>
                            {totalLcAmt.toFixed(2)}</td>
                        </tr>
                        <tr style={{"width":"100%"}}>
                          <td colSpan={9} style={{"padding":"26px 3px 25px","font-family":"system-ui","font-size":"14px"}}><strong> 
                            In Words : UAE DIRHAMS {totalLcAmt && String(totalLcAmt).split('.').length > 1 && 
                            this.numToWordsDhiram(Number(String(totalLcAmt).split('.')[0])) + "AND "+this.numToWordsDhiram(Number(String(totalLcAmt.toFixed(2)).split('.')[1]))+"FILS ONLY"
                            || this.numToWordsDhiram(Number(String(totalLcAmt).split('.')[0])) + " ONLY"
                            // this.inWords(String(totalLcAmt).split('.')[0],String(totalLcAmt)) + "AND "+this.inWords(String(totalLcAmt.toFixed(2)).split('.')[1],String(totalLcAmt))+"FILS ONLY"
                            // || this.inWords(String(totalLcAmt).split('.')[0],String(totalLcAmt)) + " ONLY"
                            }
                            {/* ONE THOUSAND THREE HUNDRED SEVENTY EIGHT AND NINTY THREE FILLS ONLY */}
                            </strong></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td colSpan={9} style={{border:'1px solid black',padding:'3px 0'}}>
                    <table cellPadding={0} cellSpacing={0} style={{"width":"100%","margin-top":"-4px"}}>
                      <tbody style={{borderTop:'0'}}>
                        <tr>
                          <td style={{"padding":"0 15px","text-align":"center",borderBottom:"1px solid #000000",backgroundColor:'#a1bfa1'}} colSpan={2}>
                            <h1 style={{"color":"#1e1e2d","font-weight":"700","margin":"3px","font-size":"16px","font-family":"sans-serif","padding":"3px"}}>Bank Details</h1>
                          </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            KENS WORLD2DOOR CARGO LLC</td>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            KENS WORLD2DOOR CARGO LLC</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            BANK : EMIRATES NBD</td>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            BANK : NATIONAL BANK OF RAS AL KHAIMAH – RAK BANK
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            A/C NUMBER : 1015405091401</td>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            A/C NUMBER : 0122916988001
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            IBAN : AE770260001015405091401</td>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            IBAN : AE960400000122916988001
                            </td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                            SWIFT CODE : EBILAEAD</td>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                          SWIFT CODE : NRAKAEAK</td>
                        </tr>
                        <tr>
                          <td style={{"font-size":"12px","padding":"5px 2px","width":"50%","font-weight":"500","color":"#000","font-family":"system-ui","border-right":"1px solid #000"}}>
                          CURRENCY : AED
                            </td>
                          <td style={{"font-size":"12px","padding":"5px 2px","font-weight":"500","color":"#000","width":"50%","font-family":"system-ui"}}>
                            CURRENCY : AED
                            </td>
                        </tr>
                <tr>
                  <td colSpan={9} style={{"padding":"8px 0 8px","font-family":"system-ui","font-size":"14px","-webkit-text-align":"center","text-align":"center",
                  borderTop:"1px solid #000000","margin-top":"-4px"}}>***This is a computer generated invoice and no signature is required***</td>
                </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
          </table>

        )
    }
}

export default InvoiceHtml