import React from "react";
import Slider from "react-slick";

const Testimonial = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // responsive: [
    //     {
    //         breakpoint: 993,
    //         settings: {
    //             slidesToShow: 2,
    //             slidesToScroll: 2,
    //         }
    //     },
    //     {
    //         breakpoint: 776,
    //         settings: {
    //             slidesToShow: 1,
    //             slidesToScroll: 1,
    //         }
    //     }

    // ]
  };

  return (
    <section className="testimonial pt-5 pb-5">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-12">
            <h2 className="text-center text-white section_heading">
              Testimonial
            </h2>
            <h5 className="text-white text-center">
              Your aspirations do not limit you. So why should your shopping
              list? With world2door.com shop and club multiple purchases to a
              single shipping order with zero limits on weight and have it
              shipped anywhere in the world. All done with a powerful website
              and mobile app. Here’s what customers say about us.
            </h5>
          </div>
          <div className="col-md-12 mx-auto">
            <Slider {...settings}>
              <div>
                <div className="tr_bx h-100 p-3 text-center">
                  <h5 className=" fw-bold mb-0">Mr. Esteban Espinoza</h5>
                  <p className="d-block mb-3">Chile</p>
                  <p className="qouteTxt">
                    I would like to thank World2Door for the excellent support
                    and assistance in every detail of the shipment process. We
                    were completely new in the shipment procedures and with
                    their help everything was clear, smooth and easy to do. We
                    imported a big container from Dubai, UAE to Santiago, Chile
                    and for that distance, the price was quite fair, the
                    delivery time was short and the overall service was
                    excellent.
                  </p>
                </div>
              </div>
              <div>
                <div className="tr_bx h-100 p-3 text-center">
                  <h5 className=" fw-bold mb-0">Mr. Hussain Naal</h5>
                  <p className="d-block mb-3">USA</p>
                  <p className="qouteTxt">
                    All stuff received. All good. And very good service from
                    both side (UAE-USA). Thank you very much.
                  </p>
                </div>
              </div>
              <div>
                <div className="tr_bx h-100 p-3 text-center">
                  <h5 className=" fw-bold mb-0">Mr. Sulaiman Abdulazi</h5>
                  <p className="d-block mb-3">Riyadh (KSA)</p>
                  <p className="qouteTxt">
                    My experience with World2Door has been nothing short of
                    excellent, I trusted them with an item that is worth over
                    $1000 and they did their best to help me receive it on time,
                    and in good condition. I also had to return the item, and
                    Mr. Rajat were very helpful and supportive by going beyond
                    his responsibilities and cooperating in returning the item
                    to the outlet which he did not have to.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
