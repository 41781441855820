import React from "react";

const RelocationBanner = () => {
  return (
    <section className="relocation-banner_bg" style={{ padding: "280px 0px" }}>
      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-md-8 mb-5">
            <div className="banner-text">
              <h1
                className="text-center text-white section_heading"
                style={{ fontSize: "50px" }}
              >
                World2Door makes relocating Simple and Easy
              </h1>
              {/* <div style={{ padding: "0px 11px" }}>
                <p className="text-center text-white fs-5 ms-4 me-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                  leo.
                </p>
              </div> */}

              <div className="d-flex justify-content-center">
                <button
                  className="btn getquoteBtn mt-5 mb-3 mx-2 text-center d-block"
                  // onClick={() => handleShow()}
                >
                  <img
                    src="/assets/images/arrow_right.svg"
                    alt="Not found"
                    height={12}
                  />
                  <span className="px-2 d-inline-block fw-bold p-0">
                    Discover More
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="banner-imag">
        <img src="/assets/images/iamx.png" alt="Not found" />
      </div>
    </section>
  );
};

export default RelocationBanner;
