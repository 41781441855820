import { DataService } from './dataService'

export const reLocationService = {
    
    quoteSubmit: async (data) => {
		return DataService.post('/user/relocation_mail', data)
	},
}


export default reLocationService;