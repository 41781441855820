import { combineReducers } from 'redux';
import {
  LOGIN,
  TEMPLOGIN,
  ITEM_GOODS,
  VOLUMECAL,
  VOLUMESEA,
  VOLUMECOURIER,
  VOLUMELAND,
  GETQUOTE,
  GETTOKEN,
  PROCEEDTOJOB,
  GETFREIGHT
} from './type';
import { Storage } from "../Storage"

const user = Storage.get("user-token");

const initialState = user
  ? { isLogged: true, user }
  : { isLogged: false, user: null };

const initialData = {
  volumeData: {},
  VolumeseaData: {},
  QuotesData: {},
  TokenData: {},
  volumeCourierdata: {},
  volumeLanddata: {},
  FreightData: {},
  itemGoods: 0
}

export const userDetailReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogged: Boolean(action.payload),
        user: action.payload
      };

    case TEMPLOGIN:
      return {
        ...state,
        isLogged: Boolean(false),
        user: action.payload
      };

    default:
      return state;
  }
}
export const retailerDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogged: Boolean(action.payload),
        user: action.payload
      };

    default:
      return state;
  }
}

export const volumeDetailReducer = (state = initialData, action) => {
  switch (action.type) {

    case VOLUMECAL:
      return {
        ...state,
        volumeData: action.payload
      };

    default:
      return state;
  }
}

export const volumeDetailSeaReducer = (state = initialData, action) => {
  switch (action.type) {

    case VOLUMESEA:
      return {
        ...state,
        VolumeseaData: action.payload
      };

    default:
      return state;
  }
}

export const QuotesDetailReducer = (state = initialData, action) => {
  switch (action.type) {

    case GETQUOTE:
      return {
        ...state,
        QuotesData: action.payload
      };

    default:
      return state;
  }
}

export const GetTokenReducer = (state = initialData, action) => {
  switch (action.type) {

    case GETTOKEN:
      return {
        ...state,
        TokenData: action.payload
      };

    default:
      return state;
  }
}

export const volumeDetailCourierReducer = (state = initialData, action) => {
  switch (action.type) {

    case VOLUMECOURIER:
      return {
        ...state,
        VolumeCourierdata: action.payload
      };

    default:
      return state;
  }
}

export const volumeDetailLandReducer = (state = initialData, action) => {
  switch (action.type) {

    case VOLUMELAND:
      return {
        ...state,
        VolumeLanddata: action.payload
      };

    default:
      return state;
  }
}

export const proceedDetailReducer = (state = initialData, action) => {
  switch (action.type) {

    case PROCEEDTOJOB:
      return {
        ...state,
        ProdceedData: action.payload
      };

    default:
      return state;
  }
}

export const getFreightReducer = (state = initialData, action) => {
  switch (action.type) {
    case GETFREIGHT:
      return {
        ...state,
        FreightData: action.payload
      };

    default:
      return state;
  }
}

export const itemGoodsReducer = (state = initialData, action) => {
  switch (action.type) {
    case ITEM_GOODS:
      return {
        ...state,
        itemGoods: action.payload
      };

    default:
      return state;
  }
}

export default combineReducers({
  userDetails: userDetailReducer,
  volumeDetail: volumeDetailReducer,
  retailerDetails: retailerDetailReducer,
  VolumeSeaDetail: volumeDetailSeaReducer,
  QuotesDetails: QuotesDetailReducer,
  TokenDetails: GetTokenReducer,
  VolumeCourierDetail: volumeDetailCourierReducer,
  VolumeLandDetail: volumeDetailLandReducer,
  ProceedDetail: proceedDetailReducer,
  FrieghtDetail: getFreightReducer,
  itemGoods: itemGoodsReducer,
})

