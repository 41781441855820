import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import DatePicker from "react-datepicker";
import BookingchartPage from "../BookingchartPage";
import IncentivechartPage from "../IncentivechartPage";
import commonApicall from "../commonApicall";
import { toast } from "react-toastify";
import BookingTable from "./BookingTable"

import { storageService } from "../../services";
import { useParams } from "react-router-dom";

/* const StoreList = [
  { label: "All Stores", value: "All Stores" },
  { label: "Stores 1", value: 1 },
  { label: "Stores 2", value: 2 },
  { label: "Stores 3", value: 3 },
  { label: "Stores 4", value: 4 },
]; */

const ManagementBooking = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loader, setLoader] = useState(false);
  const [periodType, setPeriodType] = useState();
  const [graphicalView, setGraphicalView] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const retailerData = useSelector((state) => state.retailerDetails);

  useEffect(() => {
    getBookings()
  }, []);

  useEffect(() => {
    getStore()
  },[])

  const getBookings = (storeIds='') => {
    let param = {};
    // if (startDate) {
    //   param["start_date"] = startDate;
    // } else if (endDate) {
    //   param["end_date"] = endDate;
    // } else if (searchValue) {
    //   param["search"] = searchValue;
    // }

    if(storeIds.length > 0) {
      param.store_ids = storeIds
    }
    
    setLoader(true);
    /* commonApicall.getListData(param, function (status, message, data) {
      setLoader(false);
      if (status === true) {
        setBookingList(data);
      } else {
        toast.error(message);
      }
    }); */

    commonApicall.getBookingListData(param, function (status, message, data) {
      setLoader(false);
      if (status === true) {
        setBookingList(data);
      } else {
        toast.error(message);
      }
    });


    
  }

  const getStore = () => {
    let getData = {
      id: retailerData.user.data._id,
    };
    storageService
      .getStores(getData)
      .then((res) => {
        if(res.data.length > 0){
          /* let stores = res.data.map(item => {
            return ({ label: item.store_name, value: item._id })
          })
          setStoreList(stores); */
          setStoreList(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStoreBooking = (storeId) => {
    // getBookings('629f29a0ef7fd477ea003c66')
    getBookings(storeId)
  }



  return (
    <div className="container-fluid mt-5">
      <div className="white_box h-100 pb-1">
        <div className="d-flex mb-2">
          <div className="row px-2 flex-grow-1">
            <div className="col-md-3 px-2">
              <label>Period</label>
              <select
                className="form-control w-100"
                onChange={(e) => setPeriodType(e.target.value)}
              >
                <option value="" selected disabled>
                  --select--
                </option>
                <option value="quaterly">Quaterly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                <option value="bydate">By Date</option>
              </select>
            </div>

            <div className="col-md-3 px-2">
              {periodType == "yearly" && (
                <>
                  {/* <div className="row mb-3">
              <div className="col-md-5"> */}
                  <label>Year</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showYearPicker
                    dateFormat="yyyy"
                  />
                  {/* </div>
               </div> */}
                </>

              )}

              {periodType == "bydate" && (
                <div className="d-flex">

                  <div className="mx-2">
                    <label>From</label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>

                  <div className="mx-2">
                    <label>To</label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
              )}

              {periodType == "quaterly" && (
                <>
                  {/* <div className="row mb-3">
                  <div className="col-md-6"> */}
                  <label>Select Quaterly</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="yyyy, QQQ"
                    showQuarterYearPicker
                  />
                  {/* </div>
                </div> */}
                </>

              )}

              {periodType == "monthly" && (
                <>
                  <label>Select Month </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </>
              )}
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn themebutton mt-3 w-sm"
              onClick={() => setGraphicalView(!graphicalView)}
            >
              {graphicalView ? "Table View" : "Graphical View"}
            </button>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-3">
            <label>Select Stores</label>

            <select
                className="form-control w-100"
                onChange={(e) => handleStoreBooking(e.target.value)}
              >
                <option value="">Select All</option>
                {
                  storeList.map(item => {
                    return <option key={item._id} value={item._id}>{item.store_name}</option>
                  })
                }
                
              </select>
            {/* <ReactMultiSelectCheckboxes
              options={storeList}
              className="storeSelect form-control w-100"
              placeholder="Search Stores"
            /> */}
          </div>
        </div>

        {/* <div className="col-md-3">
                          <input type="text" placeholder="Search by Id, Store Name" className="form-control" />
                      </div> */}
        {!graphicalView && (
          <div className="table-responsive">
            <BookingTable listdata={bookingList} />
            {bookingList && bookingList.length < 0 && (
              <p className="mb-0 p-1 bg-warning text-dark text-center">
                No Data Available
              </p>
            )}
          </div>
        )}

        {graphicalView && (
          <div className="row">
            <div className="col-md col-sm-6 mt-3">
              <div className="white_box h-100">
                {/* <BookingchartPage /> */}
              </div>
            </div>
            <div className="col-md col-sm-6 mt-3">
              <div className="white_box h-100">
                {/* <IncentivechartPage /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  )

}

export default ManagementBooking