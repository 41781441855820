import React, { useEffect, useState } from "react";
import { galleryService } from "../../services/galleryService";
import blogService from "../../services/blogService";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const history = useHistory();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  let img_url = process.env.REACT_APP_API_BASEURL;

  const getData = async () => {
    setLoader(true);
    let param = { ...pagination, page: currentPage };
    await blogService
      .getBlog(param)
      .then((res) => {
        setLoader(false);
        setPagination({ ...pagination, ...res.pagination });
        setBlogs(res.data);
      })
      .catch((error) => {
        setLoader(false)
        console.log(error);
      });
  };

  const onPageChangeEvent = (e) => {
    setLoader(true);
    setCurrentPage(e.selected + 1);
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  const formatDate = (objectId) => {
    const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000;
    const date = new Date(timestamp);

    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };



  return (
    <section className="pt-0 bg-white">
      <div className="header_title" style={{ minHeight: "450px" }}>
        <div className="container-fluid">
          <h1 className="text-center">News & Article</h1>
          <img
            src="/assets/images/gallerybanner.png"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container mt-4">
        <div className="row" style={{ marginTop: "165px" }}>
        {
          loader && (<div className="loading_spin text-center bg-transparent">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>) || 
          (blogs &&
            blogs.length > 0 &&
            blogs.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6  mb-4" key={index}>
                  <div
                    className="blog-card h-100 cursor-pointer"
                    onClick={() =>
                      history.push(`/blog-detail/${item._id}`, item)
                    }
                  >
                    <div>
                      <img
                        style={{ borderRadius: "20px 20px 0px 0px" }}
                        // src="/assets/images/blog.png"
                        src={img_url + "/" + item?.image}
                        className="w-100"
                        alt="Not found"
                      />
                      <div className="p-3">
                        <p className="mb-0 pb-1">
                          {/* <span
                            className="mb-2"
                            style={{
                              color: "#48B0BC",
                              border: "0.54px solid #D9D9D9",
                              borderRadius: "10px",
                              padding: "3px 12px",
                            }}
                          >
                            MARKETING
                          </span> */}
                          <span className="d-inline-block">
                            <li>{formatDate(item._id)}</li>{" "}
                          </span>
                        </p>
                        <h4>{item?.title.slice(0, 10)}</h4>
                        <p>{item?.description.slice(0, 110)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) || <p className="text-center pb-4">Data not found</p>)
        }
        </div>
      </div>
      <div className="header_title" style={{ minHeight: "450px" }}>
        <div className="container-fluid">
          <div className="col-sm-6" style={{ paddingLeft: "85px" }}>
            <h1 style={{ fontSize: "34px" }}>
              We will take care of all your moving needs from A to Z!
            </h1>
            <p>
              {/* Magnis diam montes vehicula non habitant parturient sed. Nullam
              fermentum velit cursus tempor leo litora augue nec. Laoreet est
              sem cursus libero semper sed nulla purus ut habitant potenti. */}
              <button className="btn getquoteBtn mt-4 mb-3 mx-2 d-block ms-0">
                <img
                  src="/assets/images/arrow_right.svg"
                  alt="Not found"
                  height={12}
                />
                <span className="px-2 d-inline-block fw-bold p-0">
                  Discover More
                </span>
              </button>
            </p>
          </div>
          <img
            src="/assets/images/galleryfooter.png"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      {/* <div className="text-center">
        {(loader && <Spinner animation="grow" size="sm" />) || ""}
      </div>
      {blogs?.length <= 0 && !loader && (
        <div className="text-center w-100 bg-warning my-5">
          <h6 className="py-2"> Blogs not available </h6>
        </div>
      )} */}
      {blogs &&
        blogs.length > 0 &&
        pagination.totalRecord > pagination.limit && (
          <ReactPaginate
            previousLabel={
              <img src="assets/images/prev.svg" height={15} alt="not found" />
            }
            forcePage={currentPage - 1}
            nextLabel={
              <img src="assets/images/next.svg" height={15} alt="not found" />
            }
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(pagination.totalRecord / pagination.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={(e) => onPageChangeEvent(e)}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )}
    </section>
  );
};

export default BlogList;
