import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { storageService } from "../services/storageService";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { useHistory } from "react-router"
import commonApicall from "./commonApicall";

export default function MyBookings() {
  const history = useHistory()
  const [loader, setLoader] = useState(false);
  const [bookingList, setBookingList] = useState();
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [bookingstatus, setBookingstatus] = useState("BOOKING_REQUESTED")

  useEffect(() => {

    getBookingload();
  }, [bookingstatus]);

  const getBookingload = (current = pagination.page) => {
    let postData = {
      page: current,
      limit: pagination.limit,
      // status: bookingstatus,
    };
    setLoader(true);
    commonApicall.getBookingList(postData, function (status, message, res) {
      setLoader(false);
      if (status === true) {
        setBookingList(res.data);
        setPagination({ ...pagination, ...res.pagination });
        setLoader(false);
      } else {
        toast.error(message);
      }
    });
  }

  const handlePageChange = (selectedObject) => {
    getBookingload(selectedObject.selected + 1);
  };


  // console.log("cities ==> ", cities)

  return (
    <>
      <div className="d-flex justify-content-between mb-3">

        <h5 className="fw-bold">My Bookings</h5>

        <div className="w-40">
          {/* <label>Booking Status</label>
        <select className="form-control" name="" onChange={(e)=>setBookingstatus(e.target.value)}>
          <option selected value="BOOKING_REQUESTED">BOOKING REQUESTED</option>
          <option value="BOOKING_CONFIRMED">BOOKING CONFIRMED</option>
          <option value="PICKUP_IN_PROCESS">PICKUP IN PROCESS</option>
          <option value="SHIPMENT_AT_WAREHOUSE">SHIPMENT AT WAREHOUSE</option>
          <option value="SHIPMENT_FINALIZED">SHIPMENT FINALIZED</option>
          <option value="UNDER_CUSTOMS_CLEARANCE">
            UNDER CUSTOMS CLEARANCE
          </option>
          <option value="DEPARTED">DEPARTED</option>
          <option value="ARRIVED">ARRIVED</option>
          <option value="READY_FOR_DELIVERY">READY FOR DELIVERY</option>
          <option value="DELIVERED">DELIVERED</option>
        </select> */}
        </div>

      </div>

      <div className="row">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>S. No.</th>
                <th>Booking Info.</th>
                <th>Origin - Destination</th>
                {/* <th>Invoice (AED)</th> */}
                {/* <th>Advance Paid</th> */}
                {/* <th>Quote Amt (AED)</th> */}
                <th>Service Type</th>
                <th>Shipment Status</th>
                <th>Agreed Amount</th>
                <th>Payment Status</th>
                <th>Date</th>
                {/* <th>Doc Status</th>
                <th>Advance Adj (AED)</th>
                <th>OS Amount (AED)</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {bookingList &&
                bookingList.length > 0 &&
                bookingList.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td> {(pagination.page - 1) * pagination.limit +
                        (key + 1)}</td>
                      <td>
                        <p className="mb-0">
                          {/* <b>Booking ID -</b>  */}
                          {item.job_token}
                          {/* <br />
                          <b>Actual -</b> 
                          {item.actual_item.kilogram} KG
                           | {item.actual_item.total_volume_CBM.toFixed(4)} CBM | {item.actual_item.package} Package
                          <br />
                          <b>Booking -</b> 
                          {item.booking_item.kilogram} KG
                           | {item.booking_item.total_volume_CBM.toFixed(4)} CBM | {item.booking_item.package} Package */}
                        </p>
                      </td>
                      <td>
                        {item.origin} - {item.destination}
                      </td>
                      <td>{item.servic_type}</td>
                      <td>{item.job_status}</td>
                      <td>{item.payment_information.agreed_amount}</td>
                      <td>{item.payment_information.payment_status}</td>
                      {/* <td>
                        <p className="mb-0">
                          <b>Total -</b> 0.00
                          <br />
                          <b>Pending -</b> Nill
                        </p>
                      </td>
                      <td>{item.advance_paid || "0.00"} AED</td> */}
                      <td>
                        {moment(item.created_at).format("YYYY-MM-DD")}
                      </td>
                      {/* <td>
                        <span className="badge bg-info">  {item.job_status}</span>
                      </td>
                      <td>
                        <span className="badge bg-warning">Pending</span>
                      </td>

                      <td>{item.advance_paid || "0.00"} AED</td>
                      <td>{item.advance_paid || "0.00"} AED</td> */}
                      <td>
                        <span
                          onClick={() => history.push(`/booking-details/${item._id}`, item)}
                        >
                          <Link to={`/booking-details/${item._id}`}>

                            <img
                              src="/assets/images/view.svg"
                              height="25"
                              alt="Not found"
                            />
                          </Link>
                        </span>


                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {bookingList && bookingList.length <= 0 && (
            <p className="text-dark text-center bg-warning">
              List not available
            </p>
          )}
          {bookingList && bookingList.length > 0 && (
            <ReactPaginate
              initialPage={pagination.page - 1}
              pageCount={pagination.totalRecord / pagination.limit}
              pageRange={2}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              pageClassName="page-item"
              breakClassName="page-item"
              nextClassName="page-item"
              previousClassName="page-item"
            />
          )}
        </div>
      </div>
      {
        loader && <div className="loader_lst">
          <img
            src="/assets/images/JOP.gif"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      }
    </>
  );
}
