import React, { useContext } from 'react'
import moment from "moment";
import { BookingDetailContext } from '../BookingDetails'

export default function Tracking() {
    const { state, disptach } = useContext(BookingDetailContext);
    if (!state) {
        return 'Loading...'
    } else {
        const info = state.tackingDetails
        return (
            <div>

                <div id="trackingSec" className="trackingSec">
                    <div className="overflow-auto">
                        {Array.isArray(info.job_status_values) &&
                            info.job_status_values.map((item, key) => {
                                return (
                                    <div className={item.change_date !== 0 ? "active" : ""}>
                                        <div className={`trackingText-${key + 1}`}>{item.text}</div>
                                        <span className="checkicon"></span>
                                        <span>{item.job_status.replace(/_/g, " ")} </span>
                                        <span> {item.tracking_date !== 0 && moment(item.tracking_date).format("DD-MMM-YYYY")} </span>
                                    </div>
                                );
                            })}
                    </div>
                </div>

            </div>
        )
    }
}
