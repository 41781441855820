import React, { useState, useEffect } from "react";
import { storageService } from "../services/storageService";

const REACT_APP_API_BASEURL = process.env.REACT_APP_API_BASEURL;

export default function MyDocuments() {
  const [documentList, setDocumentList] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = () => {
    setLoader(true)
    storageService
      .getDocumentApi()
      .then((res) => {
        if (res.status === true) {
          setDocumentList(res.data);
          setLoader(false)
        } else {
          setLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <form className="row">
          <div className="col-md">
            <div className="form-group mb-3">
              <input
                className="form-control rounded_input"
                placeholder="Booking Id"
              />
            </div>
          </div>
          <div className="col-md">
            <div className="form-group mb-3">
              <input
                className="form-control rounded_input"
                placeholder="Description"
              />
            </div>
          </div>
          <div className="col-md">
            <div className="form-group mb-3">
              <button className="themebutton">Search</button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-md-12 my-4">
        <h4 className="mb-0 fw-bold">Documents List</h4>
      </div>

      <div className="table-responsive">
        <table className="table table-striped ">
          <thead>
            <tr>
              <th>S.no.</th>
              {/* <th>Document Name</th> */}
              <th>Attachment </th>
              {/* <th>Date Uploaded</th> */}
              {/* <th>Document Type</th> */}
              <th>Courier Type</th>
              <th>Booking Ref#</th>
              {/* <th>Remarks</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {documentList &&
              documentList.length >= 0 &&
              documentList.map((item, key) => {
                return (
                  <tr>
                    <td>{key + 1}</td>
                    {/* <td>Document Name</td> */}
                    <td>
                      <img
                        width="100px"
                        src={
                          item.attached_file_invoice &&
                          REACT_APP_API_BASEURL +
                          "/" +
                          item.attached_file_invoice[0]
                        }
                      />
                    </td>
                    {/* <td>01/05/2021</td> */}
                    {/* <td>PDF</td> */}
                    <td>{item.selected_courier_type}</td>
                    <td>{item.quote_token}</td>
                    {/* <td>Lorem Ipsum dolor sit amet dummy text</td> */}
                    <td>
                      <img
                        height="20"
                        src="/assets/images/delete.svg"
                        alt="Not found"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {loader && (
        <div className="loader_lst">
          <img
            src="/assets/images/JOP.gif"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      )}
    </div>
  );
}
