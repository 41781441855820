import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Row, Nav, Tab } from "react-bootstrap-v5";
import { useHistory } from 'react-router-dom';
import AutoComplete from "./AutoComplete";
import {
  quoteService,
  productService,
  signupQuoteService,
} from "../../services";
import { toast } from "react-toastify";
import simpleReactValidator from "simple-react-validator";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import getRandomkeys from "./StringGenerator";
import helperFunction from "../../Components/Quotation/helperFunction"

// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Storage } from "../../Storage/Storage";

export default function VolumeCalculatorcopy({ show, onClose, onSubmit }) {
    const history = useHistory();
    const getState = history.location.state;
    //console.log('getState..........',getState)
  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      messages: {
        required: "required",
      },
    })
  );
 const [responseData, setResponseData] = useState();
 const [resultDataCourier, setResultDataCourier] = useState(false)

  useEffect(() => {
    if(getState && getState.res) setResponseData(getState.res)
    if(getState && getState.resultDataCourier) setResultDataCourier(getState.resultDataCourier)
  }, []);

  
  return (
    <section
      style={{ backgroundColor: "#fff" }}
      show={show}
      onHide={() => onClose()}
      size="xl"
    >
      <div className="modal-body">
        <div className="row">
         
              <>
                {responseData && (
                  <Row>
                    <Col md={6} className="mb-3">
                      <h5 className="mb-3 center">Result</h5>
                      <div>
                        <pre>{JSON.stringify(responseData, null, 2)}</pre>
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <h5 className="mb-3 center">Request</h5>

                      <p>
                        <pre> {JSON.stringify(responseData, null, 2)}</pre>
                      </p>
                      <p className="text-left">
                        {/* <a href="javascript:void(0)" onClick={() => toggleForm()}>
                          Back
                      </a> */}
                      </p>
                    </Col>
                  </Row>
                )}

                {resultDataCourier && (
                  <Row>
                    <Col md={6} className="mb-3">
                      <h5 className="mb-3 center">Result</h5>
                      <div>
                        <p>
                          <pre>
                            {" "}
                          Fedex Courier rates : &nbsp;{" "}
                            {JSON.stringify(resultDataCourier.fedex, null, 2)}
                          </pre>

                          <hr />

                          <pre>
                            {" "}
                          Ups Courier rates : &nbsp;{" "}
                            {JSON.stringify(resultDataCourier.ups, null, 2)}
                          </pre>
                        </p>

                        <hr />

                        <p>
                          <pre>
                            {" "}
                          Port Delivery : &nbsp;{" "}
                            {JSON.stringify(resultDataCourier.Port_res, null, 2)}
                          </pre>

                          <hr />

                          <pre>
                            Door Delivery : &nbsp;{" "}
                            {JSON.stringify(resultDataCourier.Door_res, null, 2)}
                          </pre>
                        </p>
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <h5 className="mb-3 center">Request</h5>
                      <p>
                        <pre>
                          {JSON.stringify(resultDataCourier.req, null, 2)}
                        </pre>
                      </p>
                      <p className="text-left">
                        {/* <a href="javascript:void(0)" onClick={() => toggleForm()}>
                          Back
                      </a> */}
                      </p>
                    </Col>
                  </Row>
                )}
              </>
        
        </div>
      </div>
      <script
        async
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDPEH1Sl7gE5ZVxx_TQ0koqa69dnnRlryw&libraries=places"
      ></script>

    </section>
  );
}
