import { BOOKING_DETAIL, TRACKING_DETAIL } from "../../Redux/type"

const initialState = {
    bookingDetails: {},
    tackingDetails: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BOOKING_DETAIL:
            return {
                ...state,
                bookingDetails: action.payload
            }

        case TRACKING_DETAIL:
            return {
                ...state,
                tackingDetails: action.payload
            }
    
        default:
            return { ...state }
    }
}

export default reducer