import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { Form } from "react-bootstrap-v5";
import { quoteService, productService } from "../../services";
// import { GetquoteAction } from "../Redux/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AutoComplete from "../../Container/home/AutoComplete";
import QuoteCalculator from "./GetQuoteCalculator";
import {
  VolumeDetailsAction,
  VolumeSeaAction,
  VolumeCourierAction,
  VolumeTntlandAction,
  GetquoteAction,
  GetTokenAction
} from "../../Redux/action";
import { Storage } from "../../Storage/Storage";
import getRandomkeys from "../../Container/home/StringGenerator";
import simpleReactValidator from "simple-react-validator";
import Loader from "../../Components/Loader";
import TableService from "./TableService";
import helperFunction from "./helperFunction";
import OrderSummary from "./OrderSummary";
import PackageModal from "./PackageModal";

import { connect } from "react-redux";
import UserAuthentication from "./UserAuthentication";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsFillInfoCircleFill } from "react-icons/bs";

function GetQuote(props) {
  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );

  const [customErr, setCustomErr] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState();
  const [spinner, setSpinner] = useState();
  const [isQuote, setIsQuote] = useState(false);
  const [transistType, setTransistType] = useState(false);
  const [addOnsarray, setAddOnsarray] = useState([]);
  const [resetQuotes, setResetQuotes] = useState(false);
  const [showAuth, setShowAuth] = useState(false);

  const Tokenstate = useSelector((state) => state.TokenDetails.TokenData);
  const UserInfo = useSelector((state) => state.userDetails);


  const Volumestate = props.Airdata;
  const Volumeseastate = props.Seadata;
  const Volumecourierstate = props.Courierdata;
  const Volumelandstate = props.Landdata;

  let VolumestateItems;

  if (
    Volumestate &&
    Volumestate.request_data &&
    Volumestate.request_data.items.length > 0
  ) {
    VolumestateItems = Volumestate.request_data;
  } else if (
    Volumeseastate &&
    Volumeseastate.request_data &&
    Volumeseastate.request_data.items.length > 0
  ) {
    VolumestateItems = Volumeseastate.request_data;
  } else if (
    Volumecourierstate &&
    Volumecourierstate.request_data &&
    Volumecourierstate.request_data.items.length > 0
  ) {
    VolumestateItems = Volumecourierstate.request_data;
  } else if (
    Volumelandstate &&
    Volumelandstate.request_data &&
    Volumelandstate.request_data.items.length > 0
  ) {
    VolumestateItems = Volumelandstate.request_data;
  }

  // const [selectArray, setSelectArray] = useState([])
  const [packingResult, setPackingResult] = useState();
  const [goodsValue, setGoodsValue] = useState({
    items_goods: VolumestateItems?.items_goods,
    insurance_premium: 0,
    minimum_aed: 0,
    insurance_amount: 0
  });
  const [packingType, setPackingType] = useState();
  const [totalpackingCharge, setTotalpackingCharge] = useState(0);
  const [packingwithService, setPackingwithService] = useState(0);

  const [insuranceData, setInsuranceData] = useState([]);
  const [storageArray, setStorageArray] = useState([]);
  const [newQuote, setNewQuote] = useState({
    from: "",
    to: "",
    No_of_package: "",
    weight: "",
    volume: "",
  });
  const [storageValue, setStorageValue] = useState({
    storage_value: "",
    storage_charges: 0,
    handling_in: 0,
    handling_out: 0,
    minimum: 0,
    storage_amount: 0,
  });

  const [subTotal, setSubTotal] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [deliverytotal, setDeliverytotal] = useState();
  const [deliverytype, setDeliverytype] = useState();
  const [orginValue, setOrginValue] = useState({
    city: VolumestateItems?.origin_city,
    country: VolumestateItems?.origin_country,
    country_short_name: VolumestateItems?.origin_country_short_name,
    latitude: VolumestateItems?.origin_latitude,
    longitude: VolumestateItems?.origin_longitude,
  });
  const [destinationValue, setDestinationValue] = useState({
    city: VolumestateItems?.destination_city,
    country: VolumestateItems?.destination_country,
    country_short_name: VolumestateItems?.destination_country_short_name,
    latitude: VolumestateItems?.destination_latitude,
    longitude: VolumestateItems?.destination_longitude,
  });

  const [isChecked, setIsChecked] = useState(false);
  const [freightCharge, setFreightCharge] = useState();
  const [clearAll, setClearAll] = useState(true);
  const [updatePackingBoolean, setUpdatePackingBoolean] = useState(true);
  const [freightAmount, setFreightAmount] = useState(0);
  const tokenPromo = VolumestateItems?.referral_token !== '' ? VolumestateItems?.referral_token : VolumestateItems?.promo_code
  const [refCodeData, setRefCodeData] = useState(tokenPromo);
  const [percentageValue, setPercentageValue] = useState();
  const [retailerPercentageValue, setRetailerPercentageValue] = useState(0);
  const [packingChargeAmount, setPackingChargeAmount] = useState();
  const [retailerDiscountValue, setRetailerDiscountValue] = useState(0);
  const [subTotalInit, setSubTotalInit] = useState(0)

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [refferalData, setRefferalData] = useState({})

  const isRetailer = Storage.get("user-token") === "retailer"
  const isStore = Storage.get("user-token") === "store"
  const [nonePackingData, setNonePackingData] = useState({})
  const [callPackage, setCallPackage] = useState(0);


  useEffect(() => {

    let srcParam = new URLSearchParams(history.location.search).values()
    let array = []
    for (const values of srcParam) {
      array.push(values)
    }

    let param = {
      quote_token: array[0]
    };
    quoteService.getQuoteDetailApi(param).then((response) => {
      // if(result.status === true){
      //   console.log(response)
      // }
    })
  }, [])




  useEffect(() => {

    let addOnshere = [];

    VolumestateItems.items.forEach((data) => {
      addOnshere.push({
        dimension: {
          kilogram: Number(data.dimension.kilogram),
          length: Number(data.dimension.length),
          width: Number(data.dimension.width),
          height: Number(data.dimension.height),
        },
        after_packing_dimension: {
          kilogram: 0,
          length: 0,
          width: 0,
          height: 0,
        },
        item_description: data.item_description,
        item_id: data.item_id,
        no_of_pkgs: Number(data.no_of_pkgs),
        quote_request_id: data.quote_request_id,
        quote_token: data.quote_token,
        total_volume_CBM: Number(data.total_volume_CBM),
        total_weight_KG: Number(data.total_weight_KG),
        after_packing_total_volume_CBM: 0,
        after_packing_total_weight_KG: 0,
        user_id: data.user_id,
        packing_type: "",
        packing_charges: 0,
        freight_charges: 0,
        item_token: data.item_token,
      });
    });

    setAddOnsarray(addOnshere);
  }, []);

  useEffect(() => {
    setNewQuote({
      from: VolumestateItems.origin_city,
      to: VolumestateItems.destination_city,
      No_of_package: VolumestateItems.number_of_packages,
      weight: VolumestateItems.weight,
      volume: VolumestateItems.volume,
    });

    setOrginValue({
      city: VolumestateItems?.origin_city,
      country: VolumestateItems?.origin_country,
      country_short_name: VolumestateItems?.origin_country_short_name,
      latitude: VolumestateItems?.origin_latitude,
      longitude: VolumestateItems?.origin_longitude,
    });
    setDestinationValue({
      city: VolumestateItems?.destination_city,
      country: VolumestateItems?.destination_country,
      country_short_name: VolumestateItems?.destination_country_short_name,
      latitude: VolumestateItems?.destination_latitude,
      longitude: VolumestateItems?.destination_longitude,
    });
    setGoodsValue({
      ...goodsValue,
      items_goods: VolumestateItems?.items_goods
    });
  }, [VolumestateItems, VolumestateItems?.items])

  useEffect(() => {
    // getPackingList();
    getInsuranceList();
    getStorageList();
  }, []);

  // insurancelist api
  const getInsuranceList = async () => {
    let insuranceCall;
    if (isRetailer) {
      insuranceCall = quoteService.retailerInsuranceList()
    } else if (isStore) {
      insuranceCall = quoteService.storeInsuranceList()
    } else {
      insuranceCall = quoteService.insuranceList()
    }

    await insuranceCall.then((result) => {
      if (result.status === true) {
        setInsuranceData(result.data);
        setGoodsValue({
          ...goodsValue,
          items_goods: VolumestateItems?.items_goods,
          insurance_amount: 0,
          insurance_premium: result.data[0].insurance_premium,
          minimum_aed: result.data[0].minimum_aed,
        });
      }
    });
  };

  // storagelist api
  const getStorageList = async () => {

    let storageCall;
    if (isRetailer) {
      storageCall = quoteService.retailerStorageList()
    } else if (isStore) {
      storageCall = quoteService.storeStorageList()
    } else {
      storageCall = quoteService.storageList()
    }

    await storageCall.then((result) => {
      if (result.status === true) {
        setStorageArray(result.data.storages);
        storageValue.minimum = result.data.minimum;
        if (Array.isArray(result.data.storages)) {
          result.data.storages.map((item, key) => {
            if (item.tarrif === "storage_charges") {
              storageValue.storage_charges = item.rate
            } if (item.tarrif === "handling_in") {
              storageValue.handling_in = item.rate
            } if (item.tarrif === "handling_out") {
              storageValue.handling_out = item.rate
            }
          })
        }
      }
    });
  };

  // storage event
  const storageHandler = (e) => {
    const { value } = e.target;
    let initialFreight = 0;

    if (value > 0) {
      setFreightAmount();
      let storageResult;
      storageResult = helperFunction.storageCalculation(
        value,
        storageArray,
        VolumestateItems
      );

      if (storageResult <= 100) {
        storageValue.storage_amount = 100;
        setStorageValue({
          ...storageValue,
          storage_value: value
        })
        initialFreight = subTotalfun(packingwithService || deliverytotal, goodsValue.insurance_amount, 100);
        setSubTotalInit(initialFreight.toFixed(0))
      } else if (!storageResult) {
        storageValue.storage_amount = 0;
        setStorageValue({
          ...storageValue,
          storage_value: value
        })
      } else {
        storageValue.storage_amount = Math.round(storageResult);
        setStorageValue({
          ...storageValue,
          storage_value: value
        })
        initialFreight = subTotalfun(
          packingwithService || deliverytotal,
          goodsValue.insurance_amount,
          Math.round(storageResult)
        );
        setSubTotalInit(initialFreight.toFixed(0))
      }

    } else {
      initialFreight = subTotalfun(packingwithService || deliverytotal, goodsValue.insurance_amount, 0);
      storageValue.storage_amount = "";
      setStorageValue({
        ...storageValue,
        storage_value: value
      })
      setSubTotalInit(initialFreight);

      if (updatePackingBoolean === false) {
        setFreightAmount();
      } else {
        setFreightAmount(deliverytotal);
      }
    }

    getDiscountbyPercentage(percentageValue, initialFreight);
    if (isRetailer) {
      let retailerDiscountAmt = helperFunction.getReatailerDiscount(retailerPercentageValue, initialFreight)
      setRetailerDiscountValue(retailerDiscountAmt)
    }
  };


  const onSwitchAction = (e) => {
    if (e.target.checked) {
      if (!deliverytotal) {
        setCustomErr("Please Select Atleast One Service");
        toast.info("Please Select Atleast One Service")
      } else {
        insuranceHandler(true)
        setIsSwitchOn(true);
      }
    } else {
      insuranceHandler(false)
      setIsSwitchOn(false);
    }
  };

  // insurance event
  const insuranceHandler = (event) => {
    const value = event ? VolumestateItems?.items_goods : null;
    let initFreight = 0;
    // goodsValue.items_goods = Number(value);
    setGoodsValue({
      ...goodsValue,
      items_goods: Number(value)
    });
    let insuranceResult;
    if (VolumestateItems && deliverytotal && value > 0) {
      insuranceResult = helperFunction.insuranceCalculation(
        deliverytotal,
        value,
        insuranceData
      );
    }
    if (!value) {
      setGoodsValue({
        ...goodsValue,
        insurance_amount: 0
      })
      initFreight = subTotalfun(packingwithService || deliverytotal, 0, storageValue.storage_amount);
      setSubTotalInit(initFreight.toFixed(0))
    } else {
      if (insuranceResult <= 150) {
        setGoodsValue({
          ...goodsValue,
          insurance_amount: 150
        })
        initFreight = subTotalfun(packingwithService || deliverytotal, 150, storageValue.storage_amount);
        setSubTotalInit(initFreight.toFixed(0))
      } else {
        setGoodsValue({
          ...goodsValue,
          insurance_amount: Number(insuranceResult)
        })
        initFreight = subTotalfun(
          packingwithService || deliverytotal,
          Number(insuranceResult),
          storageValue.storage_amount
        );
        setSubTotalInit(initFreight.toFixed(0))
      }
    }
    if (value) {
      if (updatePackingBoolean === false) {
        setFreightAmount();
      } else {
        setFreightAmount(deliverytotal);
      }
    }
    getDiscountbyPercentage(percentageValue, initFreight)
    if (isRetailer) {
      let retailerDiscountAmt = helperFunction.getReatailerDiscount(retailerPercentageValue, initFreight)
      setRetailerDiscountValue(retailerDiscountAmt)
    }
  };

  // console.log("percentage ....", percentageValue);
  // console.log("refcode ....", refCodeData)

  const codeHandler = (value) => {
    if (value || deliverytotal) {
      if (!isStore && !isRetailer) {
        let postToken = {
          referral_token: refCodeData,
        };
        productService.productReferralCode(postToken).then((res) => {
          if (res.status === true) {
            setRefferalData({ ...res.data })

            let packingAndService = (+value + +packingChargeAmount);
            let afterCalcTotal = subTotalfun(packingAndService, goodsValue.insurance_amount, storageValue.storage_amount);
            setSubTotalInit(afterCalcTotal.toFixed(0))

            getDiscountbyPercentage(res.data.customer_discount, afterCalcTotal)

            // value ? getDiscountbyPercentage(res.data.customer_discount, value) : getDiscountbyPercentage(res.data.customer_discount, afterCalcTotal)
            setPercentageValue(res.data.customer_discount);
          } else {
            // toast.error("invalid referral code");
            let postPromo = {
              promo_code: refCodeData,
            };
            productService.productPromoCode(postPromo).then((res) => {
              if (res.status === true) {
                setRefferalData({ ...res.data })
                let packingAndService = (+value + +packingChargeAmount);
                let afterCalcTotal = subTotalfun(packingAndService, goodsValue.insurance_amount, storageValue.storage_amount);
                setSubTotalInit(afterCalcTotal.toFixed(0))

                getDiscountbyPercentage(res.data.customer_discount, afterCalcTotal)
                setPercentageValue(res.data.customer_discount);
              } else {
                toast.error("Invalid code");
              }
            });
          }
        });
      } else {
        let discountValue = Storage.get('user-auth')?.retailer_detail[0]?.store_discount
        setRefferalData(Storage.get('user-auth')?.retailer_detail[0]);

        let packingAndService = (+value + +packingChargeAmount);
        let afterCalcTotal = subTotalfun(packingAndService, goodsValue.insurance_amount, storageValue.storage_amount);
        setSubTotalInit(afterCalcTotal)

        getDiscountbyPercentage(discountValue, afterCalcTotal)
        setPercentageValue(discountValue);
      }
    } else {
      setCustomErr("Please Select Atleast One Service");
    }

    // } else {
    //   setDiscountAmount();
    // }

    // if (codetype === "promotion_code") {
    //   let postToken = {
    //     promo_code: promoCodeData,
    //   };
    //   productService.productPromoCode(postToken).then((res) => {
    //     if (res.status === true) {
    //       getDiscountbyPercentage(res.data.customer_discount);
    //     } else {
    //       toast.error("invalid promo code");
    //     }
    //   });
    // } else {
    //   setDiscountAmount();
    // }
  };

  // console.log("ref ...", refferalData)

  const getDiscountbyPercentage = (percentageCode, serviceamt) => {
    let percentageAmount = 0;
    if (percentageCode) {
      percentageAmount =
        (Number(serviceamt || freightAmount) *
          Number(percentageCode)) /
        100;

      setDiscountAmount(percentageAmount.toFixed(0));
    }
    let currentAmt = totalBydiscount(serviceamt, percentageAmount);
    setSubTotal(currentAmt.toFixed(0));
  };

  function totalBydiscount(TotalAmount, percentageAmount) {
    let amtAfterDiscount = Number(TotalAmount) - Number(percentageAmount || discountAmount)
    return amtAfterDiscount;
  }

  const onCloseClick = () => {
    setIsQuote(!isQuote);
  };

  /// radio handlechange function
  const deliveryHandleradio = (event, type, tType) => {

    if (UserInfo.isLogged) {
      const { value } = event.target;
      // setDiscountAmount(Number(value) - Number(discount));

      setFreightAmount(value);
      setDeliverytype(type);
      setTransistType(tType);

      if (VolumestateItems && goodsValue.items_goods) {
        helperFunction.insuranceCalculation(
          value,
          goodsValue.items_goods,
          insuranceData
        );
      }

      let param = {
        selected_courier_type: type,
        quote_token: VolumestateItems.quote_token,
      };
      if (Storage.get("user-token") === "retailer") {
        quoteService.retailerSelectedCourier(param).then((res) => {
          if (res.status === true) {
            let totalSub = subTotalfun(
              value,
              goodsValue.insurance_amount,
              storageValue.storage_amount
            );
            getDiscountbyPercentage(percentageValue, totalSub)
            let retailerDiscountAmt = helperFunction.getReatailerDiscount(retailerPercentageValue, totalSub)
            setRetailerDiscountValue(retailerDiscountAmt)
          }
        });
      } else if (Storage.get("user-token") === "store") {
        quoteService.storeSelectedCourier(param).then((res) => {
          if (res.status === true) {
            refCodeData && codeHandler(value)
          }
        });
      } else {
        quoteService.postSelectedCourier(param).then((res) => {
          if (res.status === true) {
            // refCodeData && codeHandler(value)
          }
        });
      }

      // Call this function for reset packing, storage and insurance
      setCallPackage(1)
      handlePackingNone(value)

    } else {
      setShowAuth(!showAuth)
    }
  };

  // Insurance Calculation Function
  const subTotalfun = (tp, ti, ts) => {
    const totalSub = Number(tp) + Number(ti) + Number(ts);
    return totalSub
  };

  ///// function start for Packing Module

  useEffect(() => {
    if (clearAll) {
      setPackingResult();
      setFreightCharge();
      setPackingType();
      setTotalpackingCharge(0);
      setPackingChargeAmount(0);
      // let packinglistarray = [...VolumestateItems.items];
      // packinglistarray.map((pack, id) => {
      //   if (packingKey === id) {
      //     packinglistarray[id]["individual_packing_charge"] = null;
      //     packinglistarray[id]["individual_freight_charge"] = null;
      //   }
      // });
    }
  }, [clearAll]);

  const [show, setShow] = useState(false);

  const packingClose = () => {
    setShow(false);
    // setIsChecked(false);
    if (updatePackingBoolean) {
      setFreightCharge();
      setPackingType();
      setTotalpackingCharge(0);
      setPackingChargeAmount(0);
      setPackingResult();

      let addOnsthis = [];
      VolumestateItems.items.forEach((data) => {
        addOnsthis.push({
          dimension: {
            kilogram: Number(data.dimension.kilogram),
            length: Number(data.dimension.length),
            width: Number(data.dimension.width),
            height: Number(data.dimension.height),
          },
          after_packing_dimension: {
            kilogram: 0,
            length: 0,
            width: 0,
            height: 0,
          },
          item_description: data.item_description,
          item_id: data.item_id,
          no_of_pkgs: Number(data.no_of_pkgs),
          quote_request_id: data.quote_request_id,
          quote_token: data.quote_token,
          total_volume_CBM: Number(data.total_volume_CBM),
          total_weight_KG: Number(data.total_weight_KG),
          after_packing_total_volume_CBM: 0,
          after_packing_total_weight_KG: 0,
          user_id: data.user_id,
          packing_type: "",
          packing_charges: 0,
          freight_charges: 0,
          item_token: data.item_token,
        });
      });

      setAddOnsarray(addOnsthis);
    } else {
      setClearAll(false);
    }
    // setRadiopackingresult();
    // setRadiotype();
  };

  const packingShow = () => {
    if (!deliverytotal) {
      setCustomErr("Please Select Atleast One Service");
      setShow(false);
      toast.info("Service field is required, please check");
    } else {
      setShow(true);
    }
  };

  const updatePacking = (packingdata) => {
    if (packingdata) {
      const packingChargeOnly = packingdata.freight + packingdata.charges;
      let initiateFreight = subTotalfun(
        packingChargeOnly + Number(deliverytotal),
        goodsValue.insurance_amount,
        storageValue.storage_amount
      );

      const finalPackingCharge = Number(initiateFreight);
      setSubTotalInit(finalPackingCharge);

      const totalservicepack = Number(deliverytotal) + Number(packingChargeOnly);
      setPackingwithService(totalservicepack);

      getDiscountbyPercentage(percentageValue, finalPackingCharge)
      if (isRetailer) {
        let retailerDiscountAmt = helperFunction.getReatailerDiscount(retailerPercentageValue, finalPackingCharge)
        setRetailerDiscountValue(retailerDiscountAmt)
      }

      setPackingChargeAmount(packingChargeOnly.toFixed(0));
      setTotalpackingCharge(finalPackingCharge);
      setShow(false);
      setUpdatePackingBoolean(false);
      setFreightAmount();
      setFreightCharge(packingdata.freight)

      let postItem = {
        items: packingdata.packing,
        quote_token: VolumestateItems && VolumestateItems.quote_token,
      };

      if (Storage.get("user-token") === "retailer") {
        setLoader(true);
        quoteService
          .updateRetailerPackingApi(postItem)
          .then((res) => {
            if (res.status === true) {
              setLoader(false);
              let packdata = packingdata.packing;
              setAddOnsarray(packdata);
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setLoader(true);
        quoteService
          .updatePackingApi(postItem)
          .then((res) => {
            if (res.status === true) {
              setLoader(false);
              let packdata = packingdata.packing;
              setAddOnsarray(packdata);
              toast.success(res.message);
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  /**
   * Set zero to add ons, packing and discout 
   * @param {*} totalOrderAmt 
   */

  const handlePackingNone = (totalOrderAmt) => {
    setSubTotal(Number(totalOrderAmt))
    setSubTotalInit(Number(totalOrderAmt))
    setDeliverytotal(Number(totalOrderAmt))
    insuranceHandler(false)
    setIsSwitchOn(false)
    storageHandler({
      target: {
        value: 0
      }
    })
    setDiscountAmount(0)
    setPercentageValue(0)
  }


  const handleAuth = () => {
    setShowAuth(!showAuth)
  }

  const handleProceed = (e) => {
    window.location.reload();
  }

  const proceedHandle = () => {
    if (!deliverytotal) {
      setCustomErr("Please Select Atleast One Service");
    }

    let postData = {
      packing: packingChargeAmount ? packingChargeAmount : 0,
      insurance: goodsValue,
      storage: storageValue,
      type: deliverytype,
      selected_freight: deliverytotal,
      subtotal_amount: subTotalInit,
      packingarray: addOnsarray,
      stateItem: VolumestateItems,
      discount_value: discountAmount,
      freightChargeOnly: freightCharge,
      completeAmount: subTotal ? subTotal : deliverytotal,
      transist_type: transistType
    };

    // const validate = validator.current.allValid();
    // if (validate) {
    if (deliverytotal) {
      let apiData = {
        insurance: goodsValue,
        storage: storageValue,
        quote_token: VolumestateItems.quote_token
      }
      if (
        Storage.get("user-token") === "retailer"
      ) {
        quoteService.proceedToFollowRetailerApi(apiData).then((response) => {
          if (response.status === true) {
            toast.success("Quotation has been sent on your mail");
            window.location.href = "/";
          }
        }).catch((error) => {
          console.log(error);
        })
      } else if (Storage.get("user-token") === "store") {

        const storeDetail = Storage.get("user-auth")

        /* you have to comment out this below code, when api wil be provided for store proceeding for the job */

        // let postStoreData = {
        //   // ...apiData,
        //   selected_courier_type: deliverytype,
        //   quote_token: deliverytype,
        //   add_ons: {
        //     packing: addOnsarray,
        //     insurance: goodsValue,
        //     storage: storageValue,
        //     comments: "",
        //   },
        //   packingCharge: packingChargeAmount || 0,
        //   freight: Number(deliverytotal) || 0,
        //   bookingAmount: Math.round(subTotal ? subTotal : deliverytotal) || 0,
        //   discount_amount: discountAmount || 0,
        //   referral_token_details: refferalData
        // }

        const pickup_array = [{
          pickup_address_type: '',
          pickup_address: storeDetail?.address,
          full_name: storeDetail?.store_name,
          phone_number: storeDetail?.phone_number,
          email: storeDetail?.store_email,
          what_to_pickup: '',
          invoice_id: '',
          attached_file_invoice: '',
          pickup_date: '',
          pickup_time: '',
          salutation: '',
        }]

        let postProceedData = {
          pickup_locations: pickup_array,
          delivery_locations: {
            full_name: storeDetail?.store_name,
            phone_number: storeDetail?.store_name,
            email: storeDetail?.store_name,
            delivery_address: '',
            salutation: '',
          },
          quote_token: Tokenstate,
          selected_courier_type: deliverytype,
          add_ons: {
            packing: addOnsarray,
            insurance: goodsValue,
            storage: storageValue,
            comments: "",
          },
          insurance: goodsValue?.insurance_amount || 0,
          storage: storageValue?.storage_amount || 0,
          packingCharge: packingChargeAmount ? packingChargeAmount : 0,
          freight: Number(deliverytotal) || 0,
          bookingAmount: Math.round(subTotal ? subTotal : deliverytotal) || 0,
          discount_amount: discountAmount || 0,
        };

        // console.log("postProceedData .....", postProceedData);

        quoteService.proceedToFollowStoreApi(apiData).then((response) => {
          if (response.status === true) {
            toast.success("Quotation has been sent on your mail");
            window.location.href = "/";
          }
        }).catch((error) => {
          console.log(error);
        })
      } else {
        apiData.referral_token_details = { ...refferalData }
        quoteService.proceedToFollowApi(apiData).then((response) => {
          if (response.status === true) {
            dispatch(GetquoteAction(postData));
            history.push("/get-instruction");
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    } else {
      toast.error("Service field is required, please check");
    }
  };

  const setQuote = (e) => {
    if (e.target.name === "Origin") {
      newQuote.from = e.target.value;
      setNewQuote({ ...newQuote });
    } else if (e.target.name === "Destination") {
      newQuote.to = e.target.value;
      setNewQuote({ ...newQuote });
    } else if (e.target.name === "Pkgs") {
      newQuote.No_of_package = e.target.value;
      setNewQuote({ ...newQuote });
    } else if (e.target.name === "Kg") {
      newQuote.weight = e.target.value;
      setNewQuote({ ...newQuote });
    } else if (e.target.name === "CBM") {
      newQuote.volume = e.target.value;
      setNewQuote({ ...newQuote });
    }
  };

  const getOriginData = (e) => {
    if (e === "") {
      setOrginValue("");
    } else {
      let originData = {
        country: e.country,
        country_short_name: e.country_short_name,
        city: e.city,
        latitude: e.latitude,
        longitude: e.longitude,
      };
      setOrginValue(originData);
      newQuote.from = e.city;
      setNewQuote({ ...newQuote });
    }
  };

  const getDestinationData = (e) => {
    if (e === "") {
      setDestinationValue("");
    } else {
      let destinationData = {
        country: e.country,
        country_short_name: e.country_short_name,
        city: e.city,
        latitude: e.latitude,
        longitude: e.longitude,
      };
      setDestinationValue(destinationData);
      newQuote.to = e.city;
      setNewQuote({ ...newQuote });
    }
  };

  const reQuote = () => {
    let random_key = getRandomkeys();

    var num = VolumestateItems.volume
    num = num.toString(); //If it's not already a String
    num = num.slice(0, (num.indexOf(".")) + 4);

    let postData = {
      deliverer:VolumestateItems?.deliverer,
      origin_country: orginValue.country,
      origin_country_short_name: orginValue.country_short_name,
      origin_city: orginValue.city,
      origin_latitude: orginValue.latitude,
      origin_longitude: orginValue.longitude,
      destination_country: destinationValue.country,
      destination_country_short_name: destinationValue.country_short_name,
      destination_city: destinationValue.city,
      destination_latitude: destinationValue.latitude,
      destination_longitude: destinationValue.longitude,
      number_of_packages: VolumestateItems.number_of_packages,
      volume: num,
      weight: VolumestateItems.weight,
      items_goods: Number(VolumestateItems.items_goods),
      items: VolumestateItems.items,
      quote_token: random_key,
      selected_courier_type: deliverytype,
      referral_token: refCodeData || ""
    };

    if (Storage.get("user-token") === "retailer") {
      setLoader(true);
      //  dispatch(GetTokenAction(random_key));
      apiCallFunctionRetailer(postData);
    } else if (Storage.get("user-token") === "store") {
      setLoader(true);
      //  dispatch(GetTokenAction(random_key));
      apiCallFunctionStore(postData);
    } else {
      setLoader(true);
      // dispatch(GetTokenAction(random_key));
      apiCallFunction(postData);
    }
  };

  const apiCallFunctionRetailer = (data) => {
    quoteService
      .postQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeDetailsAction(result));
          setDeliverytype("")
          setDeliverytotal();
          setFreightAmount(0);
          setSubTotal(0)
          setPackingChargeAmount(0)
          setDiscountAmount(0)
          setPercentageValue(0)
          setGoodsValue({
            ...goodsValue,
            insurance_amount: 0
          })
          setStorageValue({
            ...storageValue,
            storage_amount: 0
          })
          setLoader(false);
        } else {
          toast.error(result.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    quoteService
      .postSeaQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeSeaAction(result));
          setDeliverytype("")
          setDeliverytotal();
          setFreightAmount(0);
          setSubTotal(0)
          setPackingChargeAmount(0)
          setDiscountAmount(0)
          setPercentageValue(0)
          setGoodsValue({
            ...goodsValue,
            insurance_amount: 0
          })
          setStorageValue({
            ...storageValue,
            storage_amount: 0
          })
          setLoader(false);
        } else {
          toast.error(result.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    quoteService
      .postCourierQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeCourierAction(result));
          setDeliverytype("")
          setDeliverytotal();
          setFreightAmount(0);
          setSubTotal(0)
          setPackingChargeAmount(0)
          setDiscountAmount(0)
          setPercentageValue(0)
          setGoodsValue({
            ...goodsValue,
            insurance_amount: 0
          })
          setStorageValue({
            ...storageValue,
            storage_amount: 0
          })
          setLoader(false);
        } else {
          toast.error(result.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    quoteService
      .postLandQuoteRetialer(data)
      .then((result) => {
        if (result.status === true) {
          dispatch(VolumeTntlandAction(result));
          setDeliverytype("")
          setDeliverytotal();
          setFreightAmount(0);
          setSubTotal(0)
          setPackingChargeAmount(0)
          setDiscountAmount(0)
          setPercentageValue(0)
          setGoodsValue({
            ...goodsValue,
            insurance_amount: 0
          })
          setStorageValue({
            ...storageValue,
            storage_amount: 0
          })
          setLoader(false);
        } else {
          toast.error(result.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const apiCallFunction = (data) => {
    //for courier
    productService.getQuoteToken().then((token) => {
      data.quote_token = token.data;
      dispatch(GetTokenAction(token.data));
      quoteService
        .getCourierQuote(data)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            dispatch(VolumeCourierAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // for air
      quoteService
        .postQuote(data)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            dispatch(VolumeDetailsAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // for sea
      quoteService
        .postSeaQuote(data)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            dispatch(VolumeSeaAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // for land
      quoteService
        .getTntQuote(data)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            dispatch(VolumeTntlandAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });

  };

  const apiCallFunctionStore = (data) => {
    // for courier
    productService.getQuoteToken().then((token) => {
      data.quote_token = token.data;
      quoteService
        .postCourierQuoteStore(data)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            dispatch(VolumeCourierAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // for air
      quoteService
        .postQuoteStore(data)
        .then((result) => {
          if (result.status === true) {
            dispatch(VolumeDetailsAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
            setLoader(false);
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // for sea
      quoteService
        .postSeaQuoteStore(data)
        .then((result) => {
          if (result.status === true) {
            dispatch(VolumeSeaAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
            setLoader(false);
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // for land

      quoteService
        .postLandQuoteStore(data)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            dispatch(VolumeTntlandAction(result));
            setDeliverytype("")
            setDeliverytotal();
            setFreightAmount(0);
            setSubTotal(0)
            setPackingChargeAmount(0)
            setDiscountAmount(0)
            setPercentageValue(0)
            setGoodsValue({
              ...goodsValue,
              insurance_amount: 0
            })
            setStorageValue({
              ...storageValue,
              storage_amount: 0
            })
          } else {
            toast.error(result.message);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    })
  };

  return (
    <>
      <section className="pb-5 pt-0">
        <div className="header_title header_title_sp">
          <div className="container-fluid text-center">
            <h1>
              {newQuote && newQuote.from}
              <span className="px-md-4 px-2">
                <img
                  src="/assets/images/arrow_right.svg"
                  alt="Not found"
                />
              </span>
              {newQuote && newQuote.to}
            </h1>
            <p>
              Pkgs {newQuote && newQuote.No_of_package} | Weight{" "}
              {newQuote && newQuote.weight}kg | Volume{" "}
              {newQuote && newQuote.volume} (CBM)
            </p>

            <div
              className="calculator container mt-5 mb-3 text-start"
              style={{ position: "relative", zIndex: 2 }}
            >
              <div className="px-3 newOriginSelect">
                <label>From</label>
                <AutoComplete
                  getData={(e) => getOriginData(e)}
                  prefillState={orginValue}
                  placeholder="Origin"
                  name="Origin"
                  onChange={(e) => setQuote(e)}
                  // value={newQuote && newQuote.from}
                  // value={"Dubai - United Arab Emirates"}
                  className="calculate_input"
                />
              </div>
              <div className="px-3 newOriginSelect">
                <label>To</label>
                <AutoComplete
                  getData={(e) => getDestinationData(e)}
                  prefillState={destinationValue}
                  placeholder="Destination"
                  name="Destination"
                  onChange={(e) => setQuote(e)}
                  value={newQuote && newQuote.to}
                  className="calculate_input"
                />
              </div>
              <div className="col px-3">
                <label>No. of Pkgs</label>
                <br />
                <span>{newQuote && newQuote.No_of_package}</span>
              </div>
              <div className="col px-3">
                <label>Weight (KG)</label>
                <br />
                <span>{newQuote && newQuote.weight}</span>
              </div>
              <div className="col px-3 ">
                <label>Volume (CBM)</label>
                <br />
                <span>{newQuote && newQuote.volume}</span>
                <br />
              </div>
              <div className="col py-0 pl-3 pe-0 custom">
                <button
                  className="themebutton px-4 ms-auto"
                  onClick={() => {
                    reQuote(true);
                  }}
                >
                  Re-quote
                </button>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      This option allows you to change the From and Destination address and new quote.
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <BsFillInfoCircleFill className="fs-4 pb-2" />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
            <div className="container text-end  col py-0 pl-3 pe-0 " style={{ paddingRight: 160 }}>
              <p
                className=" pointer mb-5 fw-9 volumeDesign custom2"
                onClick={() => {
                  setIsQuote(true);
                }}
              >
                <button className="themebutton px-4 ms-auto" > Edit Cargo Details</button>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      This option allows you to edit the cargo details like the no. of pkgs , weight of each package and the Dimension of each package and get a new quote.
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <BsFillInfoCircleFill className="fs-4 pb-2" />
                  </span>
                </OverlayTrigger>
              </p>
            </div>
            <img
              src="/assets/images/country.jpg"
              className="bannerimg"
              alt="Not found"
            />
          </div>
        </div>
        <div className="container-fluid mt-5">
          <div className="row">
            <div className="col-md-8">
              <TableService
                totaldeliveryrate={deliverytotal}
                errormessage={customErr}
                onDeliveryHandler={(e, type, tType) =>
                  deliveryHandleradio(e, type, tType)
                }
                typofservice={deliverytype}
                VolumestateItems={VolumestateItems}
              />
              {
                !isRetailer &&
                <div className="white_box mt-4">
                  <h5 className="mb-1">Select Add - Ons (if required)</h5>
                  <p className="text-danger">
                    Note - Packing and insurance recommended
                  </p>
                  <div className="row mt-3">
                    <div className="col-md-4 border-end">
                      <div className="form-group mb-3">
                        <label>
                          <span className="d-block fw-bold">PACKING</span>
                        </label>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              This is an Add On service where you can get your packages repacked. There are 4 types of packing. One can use the appropriate packing depending upon the kind of packing required.
                            </Tooltip>
                          }
                        >
                          <span className="d-inline-block">
                            <BsFillInfoCircleFill className="fs-4 pb-2" />
                          </span>
                        </OverlayTrigger>
                        <div>
                          <button
                            className="btn themebutton mt-3 w-sm"
                            type="button"
                            onClick={() => packingShow()}
                          >
                            Add & View Packing
                          </button>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-4 border-end">
                      <div className="form-group mb-3">
                        <label>
                          <span className="d-block  fw-bold">INSURANCE
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  This is an Add On service where you can get Transit Insurance cover for any damages or loss of goods during the transit till the agreed delivery place.
                                 </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <BsFillInfoCircleFill className="fs-4 pb-2" />
                              </span>
                            </OverlayTrigger>
                          </span>

                          <small>Insurance amount</small>
                        </label>

                        <input
                          className="form-control"
                          type="number"
                          name="items_goods"
                          min="0"
                          readOnly={deliverytotal ? false : true}
                          // onChange={(e) => setGoodsValue({
                          //   ...goodsValue,
                          //   items_goods: e.target.value
                          // })}
                          value={goodsValue.items_goods}
                        // value={itemGoodInsurance}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label>
                          <small>Select if insurance required.</small>
                        </label>
                        <Form>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Calculate Insurance"
                            checked={isSwitchOn}
                            // onChange={onSwitchAction}
                            onChange={(e) => onSwitchAction(e)}
                          />

                        </Form>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group mb-3">
                        <label>
                          <span className="d-block  fw-bold">STORAGE
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  This is an Add On service where you may storage the goods at the Origin in case you do not want to ship the goods immediately.
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <BsFillInfoCircleFill className="fs-4 pb-2" />
                              </span>
                            </OverlayTrigger>
                          </span>
                          <small>Enter number of Days</small>
                        </label>
                        <input
                          className="form-control"
                          name="storageValue"
                          type="number"
                          min="0"
                          readOnly={deliverytotal ? false : true}
                          value={storageValue.storage_value}
                          onChange={(event) => {
                            if(event.target.value.length < 4 && event.target.value >= 0)
                            { storageHandler(event)}
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">

                    <button
                      type="button"
                      className="themebutton"
                      // onClick={() => UserInfo.isLogged ? proceedHandle() : handleAuth()}
                      onClick={() => proceedHandle()}
                    >
                      Proceed
                    </button>


                  </div>
                </div>

              }
            </div>
            <OrderSummary
              initServiceAmt={deliverytotal}
              deliverType={deliverytype}
              packingamount={packingChargeAmount}
              insurance={goodsValue.insurance_amount}
              storage={storageValue.storage_amount}
              changeRefcode={setRefCodeData}
              initialrefCode={refCodeData}
              Total={subTotal}
              freight={freightAmount}
              discount={discountAmount}
              retailerDiscount={retailerDiscountValue}
              item={VolumestateItems}
              percentValue={percentageValue}
              setPercentage={setPercentageValue}
              retailerPercentage={retailerPercentageValue}
              setRetailerPercentage={setRetailerPercentageValue}
              subTotalInit={subTotalInit}
              onchange={(type) => codeHandler(type)}
            />
          </div>
          {/* {
            !isRetailer &&
            <div className="row">
              <div className="col mt-3">
                <button
                  type="button"
                  className="themebutton"
                  // onClick={() => UserInfo.isLogged ? proceedHandle() : handleAuth()}
                  onClick={() => proceedHandle()}
                >
                  Proceed
                </button>
              </div>
              <div className="col mt-3 text-end"></div>
            </div>
          } */}

        </div>
      </section>

      <QuoteCalculator
        show={isQuote}
        //  onSubmit={(e) => onSubmitClick(e)}
        onClose={() => onCloseClick()}
        orginValue={orginValue}
        destinationValue={destinationValue}
        StateList={VolumestateItems}
        couriertype={deliverytype}
        setType={setDeliverytype}
        setInitTotal={setDeliverytotal}
        setFreight={setFreightAmount}
        setSub={setSubTotal}
        setPacking={setPackingChargeAmount}
        setinsurance={setGoodsValue}
        setStore={setStorageValue}
        insuranceFun={getInsuranceList}
        storageFun={getStorageList}
        setdiscount={setDiscountAmount}
        setpercentage={setPercentageValue}
        setResetQts={setResetQuotes}
        setSubTotalInit={setSubTotalInit}
      />

      {/* {PackingCalculator()} */}
      <PackageModal
        isShow={show}
        onHide={packingClose}
        // SinglePacking={singlePackingChangeHandler}
        // AllPacking={allPackingChangeHandler}
        originData={orginValue}
        destinationData={destinationValue}
        volumestate={VolumestateItems}
        serviceType={deliverytype}
        serviceAmount={deliverytotal}
        saveHandle={(e) => updatePacking(e)}
        settingSpin={setSpinner}
        resetPack={resetQuotes}

        callPackage={callPackage}
        setCallPackage={setCallPackage}
      />

      {/* User Authentication */}

      {showAuth && <UserAuthentication toggleAuth={handleAuth} handleProceed={handleProceed} />}

      <Loader isShow={loader} />

      {spinner && (
        <div className="loading_spin text-center bg-transparent">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  Airdata: state.volumeDetail.volumeData,
  Seadata: state.VolumeSeaDetail.VolumeseaData,
  Courierdata: state.VolumeCourierDetail.VolumeCourierdata,
  Landdata: state.VolumeLandDetail.VolumeLanddata,
});

export default connect(mapStateToProps)(GetQuote);