import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Form, Col, Row, Nav, Tab } from "react-bootstrap-v5";
import AutoComplete from "./AutoComplete";
import {
  quoteService,
  productService,
  signupQuoteService,
} from "../../services";
import { toast } from "react-toastify";
import simpleReactValidator from "simple-react-validator";
import "react-phone-input-2/lib/style.css";
import getRandomkeys from "./StringGenerator";
import helperFunction from "../../Components/Quotation/helperFunction";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Storage } from "../../Storage/Storage";

export default function VolumeCalculatorcopy({ show, onClose, onSubmit }) {
  const history = useHistory();
  const getState = history.location.state;
  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      messages: {
        required: "required",
      },
    })
  );
  const [loader, setLoader] = useState();
  const [Keyword, setKeyword] = useState(null);
  const [Code, setCode] = useState("");
  const [itemValue, setItemValue] = useState("");
  const [productList, setProductList] = useState("");
  const [visiblility, setVisiblility] = useState(true);
  const [resultData, setResultData] = useState(true);
  const [resultDataCourier, setResultDataCourier] = useState(); 
  const [valLocation, setValLocation] = useState(false);
  const [OrginValue, setOrginValue] = useState("");
  const [DestinationValue, setDestinationValue] = useState("");
  const [packages, setPackages] = useState("");
  const [goodValue, setGoodValue] = useState("");
  const [filterGoodValue, setFilterGoodValue] = useState("");
  const [deliverer, setDeliverer] = useState("");

  const [knownProduct, setKnowProduct] = useState([]);

  const initial = { CBM: "", TKG: "", PC: "", KG: "", LN: "" };

  const [volumeList, setVolumeList] = useState([initial]);
  const [tableType, setTableType] = useState("table");

  const [grandTotal, setGrandTotal] = useState({
    number_of_packages: 0,
    volume: 0,
    weight: 0,
  });
  const [isClick, setIsClick] = useState(false);

  const [productItem, setProductItem] = useState([]);
  const [productItemForArray, setProductItemForArray] = useState([]);

  useEffect(() => {
    let tpkg = 0,
      tCBM = 0,
      tKg = 0;

    volumeList.map((item) => {
      if (item.PC) {
        tpkg += Number(item.PC);
      }
      if (item.TKG) {
        tCBM += Number(item.CBM);
      }
      if (item.CBM) {
        tKg += Number(item.TKG);
      }
    });

    let tpqn = 0,
      tproductCBM = 0,
      tproductKg = 0;

    productItem.map((item) => {
      if (item.no_of_pkgs) {
        tpqn += Number(item.no_of_pkgs);
        setPackages(item.no_of_pkgs);
      }
      if (item.total_volume_CBM) {
        tproductCBM += Number(item.total_volume_CBM);
      }
      if (item.total_weight_KG) {
        tproductKg += Number(item.total_weight_KG);
      }
    });

    setGrandTotal({
      number_of_packages: tpkg ? tpkg : tpqn,
      volume: tCBM
        ? tCBM.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
        : tproductCBM.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0],
      weight: tKg ? tKg : tproductKg,
    });
  }, [volumeList, productItem]);

  const onClickAdd = () => {
    let arry = [...volumeList];
    arry.push(initial);
    setVolumeList(arry);
  };

  const onClickDelete = (ind) => {
    let arry = [...volumeList];
    arry.splice(ind, 1);
    setVolumeList(arry);
  };

  const onChangeEvent = (key, event) => {
    let { name, value } = event.target;
    let arry = [...volumeList];
    let obj = arry[key];

    if (name != "KG" && name != "PD") {
      obj["CBM"] = getCBM(obj, name, value);
    }

    if (name === "KG" || name === "PC") {
      obj["TKG"] = getTKg(obj, name, value);
    }

    obj[name] = value;
    arry.splice(key, 1, obj);
    setVolumeList(arry);
  };

  const getTKg = (obj, act, count) => {
    switch (act) {
      case "KG":
        return obj.PC * count;
      case "PC":
        return count * obj.KG;
    }
  };

  const getCBM = (obj, act, count) => {
    switch (act) {
      case "PC":
        return (
          Number(count) *
          ((Number(obj.LN) * Number(obj.WI) * Number(obj.HI)) / 1000000)
        )
          .toString()
          .match(/^-?\d+(?:\.\d{0,3})?/);
      case "LN":
        return (
          Number(obj.PC) *
          ((Number(count) * Number(obj.WI) * Number(obj.HI)) / 1000000)
        )
          .toString()
          .match(/^-?\d+(?:\.\d{0,3})?/);
      case "WI":
        return (
          Number(obj.PC) *
          ((Number(obj.LN) * Number(count) * Number(obj.HI)) / 1000000)
        )
          .toString()
          .match(/^-?\d+(?:\.\d{0,3})?/);
      case "HI":
        return (
          Number(obj.PC) *
          ((Number(obj.LN) * Number(obj.WI) * Number(count)) / 1000000)
        )
          .toString()
          .match(/^-?\d+(?:\.\d{0,3})?/);
    }
  };

  // const isVisible = true;
  const toggleForm = () => {
    setVisiblility(!visiblility);
    setVolumeList([initial]);
    setOrginValue("");
    setDestinationValue("");
  };

  const getOriginData = (e) => {
    if (e === "") {
      setOrginValue("");
    } else {
      let originData = {
        origin_country: e.country,
        origin_country_short_name: e.country_short_name,
        origin_city: e.city,
        origin_latitude: e.latitude,
        origin_longitude: e.longitude,
      };
      setOrginValue(originData);
    }
  };

  const getDestinationData = (e) => {
    if (e === "") {
      setDestinationValue("");
    } else {
      let destinationData = {
        destination_country: e.country,
        destination_country_short_name: e.country_short_name,
        destination_city: e.city,
        destination_latitude: e.latitude,
        destination_longitude: e.longitude,
      };
      setDestinationValue(destinationData);
    }
  };

  useEffect(() => {
    let oldArray = [...knownProduct];
    volumeList.map((item) =>
      oldArray.push({
        item_id: "",
        item_description: item.PD,
        no_of_pkgs: item.PC,
        dimension: {
          kilogram: item.KG,
          length: item.LN,
          width: item.WI,
          height: item.HI,
        },
        total_volume_CBM: parseFloat(grandTotal.volume),
        total_weight_KG: grandTotal.weight,
      })
    );
    setKnowProduct(oldArray);
  }, [itemValue]);

  //// this below code for dropdown product

  const handleChange = (getvalue) => {
    setItemValue(getvalue);
    new_conditionFun(getvalue);
  };

  const onSecondClickDelete = (indtwo, data) => {
    let newArraytwo = [...productItem];
    newArraytwo.splice(indtwo, 1);
    setProductItem(newArraytwo);

    const filteredProduct = productItemForArray.filter(
      (item) => item.item_id !== data
    );
    setProductItemForArray(filteredProduct);

    let dropdownArray = [...itemValue];
    dropdownArray.splice(indtwo, 1);
    setItemValue(dropdownArray);
  };
  //   console.log("---item",productItem)
  const new_conditionFun = (my_selected_arr) => {
    let newArrayWithQunatity = [],
      firstItemArray = [];
    if (my_selected_arr) {
      my_selected_arr.map((elem, i) => {
        let obj = productItem.find((o) => o.item_id === elem.data._id);
        if (productItem.length && obj !== undefined) {
          firstItemArray.push({
            item_id: elem.data._id,
            item_description: elem.data.product_name,
            no_of_pkgs: "",
            dimension: {
              kilogram: elem.data.product_dimentions[0].weight,
              length: elem.data.product_dimentions[0].length,
              width: elem.data.product_dimentions[0].width,
              height: elem.data.product_dimentions[0].height,
            },
            total_volume_CBM: elem.data.product_dimentions[0].volume,
            total_weight_KG: elem.data.product_gross_weight,
          });
          elem.data?.product_dimentions.length &&
            elem.data?.product_dimentions.map((product) => {
              newArrayWithQunatity.push({
                item_id: elem.data._id,
                item_description: elem.data.product_name,
                no_of_pkgs: "",
                dimension: {
                  kilogram: product.weight,
                  length: product.length,
                  width: product.width,
                  height: product.height,
                },
                total_volume_CBM: product.volume,
                total_weight_KG: elem.data.product_gross_weight,
              });
            });
        } else {
          firstItemArray.push({
            item_id: elem.data._id,
            item_description: elem.data.product_name,
            no_of_pkgs: "",
            dimension: {
              kilogram: elem.data.product_dimentions[0].weight,
              length: elem.data.product_dimentions[0].length,
              width: elem.data.product_dimentions[0].width,
              height: elem.data.product_dimentions[0].height,
            },
            total_volume_CBM: elem.data.product_dimentions[0].volume,
            total_weight_KG: elem.data.product_gross_weight,
          });
          elem.data?.product_dimentions.length &&
            elem.data?.product_dimentions.map((product) => {
              newArrayWithQunatity.push({
                item_id: elem.data._id,
                item_description: elem.data.product_name,
                no_of_pkgs: "",
                dimension: {
                  kilogram: product.weight,
                  length: product.length,
                  width: product.width,
                  height: product.height,
                },
                total_volume_CBM: product.volume,
                total_weight_KG: elem.data.product_gross_weight,
              });
            });
        }
      });
      setProductItem(firstItemArray);
      setProductItemForArray(newArrayWithQunatity);
      setItemValue(my_selected_arr);
    }
  };

  const mychangeEvent = (key, event, data) => {
    let newArraytwo = [...productItem];
    let newArrayProduct = [...productItemForArray];
    newArraytwo.map((elem, i) => {
      if (i === key) {
        elem.no_of_pkgs = event.target.value;
      }
    });
    newArrayProduct.map((item) => {
      if (item.item_id === data) {
        item.no_of_pkgs = event.target.value;
      }
    });
    setProductItem(newArraytwo);
    setProductItemForArray(newArrayProduct);
  };

  //// end here

  const submitQuoteData = (type, apiType) => {

    let postData = null;
    let random_key = getRandomkeys();

    if (type == "table") {
      if (volumeList.length >= 1) {
        let knownData = volumeList.map((item) => ({
          item_id: "",
          item_description: item.PD,
          no_of_pkgs: Number(item.PC),
          dimension: {
            kilogram: Number(item.KG),
            length: Number(item.LN),
            width: Number(item.WI),
            height: Number(item.HI),
          },
          total_volume_CBM: Number(item.CBM),
          total_weight_KG: Number(item.PC * item.KG),
          item_token: helperFunction.stringGenerator(24),
        }));

        postData = {
          ...OrginValue,
          ...DestinationValue,
          number_of_packages: grandTotal.number_of_packages,
          volume:
            grandTotal.volume <= 0.0 ? 0.001 : parseFloat(grandTotal.volume),
          weight: grandTotal.weight,
          items_goods: Number(goodValue),
          items: knownData,
          quote_token: random_key,
          deliverer:deliverer
        };
      }
      var validate =
        +validator.current.fieldValid("description") &&
        validator.current.fieldValid("search") &&
        validator.current.fieldValid("PC") &&
        validator.current.fieldValid("KG") &&
        validator.current.fieldValid("LN") &&
        validator.current.fieldValid("WI") &&
        validator.current.fieldValid("HI") &&
        validator.current.fieldValid("good value") &&
        validator.current.fieldValid("deliverer")
    } else if (type == "filter") {
      if (productItemForArray.length > 0) {
        let grandWeight = 0;
        let grandVolume = 0;
        let array = [];
        productItemForArray &&
          productItemForArray.map((ele) => {
            array.push({
              ...ele,
              total_weight_KG: Number(ele.no_of_pkgs * ele.dimension.kilogram),
              total_volume_CBM: Number(ele.no_of_pkgs * ele.total_volume_CBM),
              item_token: helperFunction.stringGenerator(24),
            });
            grandWeight += Number(ele.no_of_pkgs * ele.dimension.kilogram);
            grandVolume += Number(ele.no_of_pkgs * ele.total_volume_CBM);
          });

        postData = {
          ...OrginValue,
          ...DestinationValue,
          number_of_packages: grandTotal.number_of_packages,
          volume: parseFloat(grandVolume),
          weight: grandWeight,
          items_goods: Number(filterGoodValue),
          items: array,
          quote_token: random_key,
          deliverer:deliverer
        };
      }
      var validate =
        +validator.current.fieldValid("package description") &&
        validator.current.fieldValid("search") &&
        validator.current.fieldValid("filter") &&
        validator.current.fieldValid("quantity") &&
        validator.current.fieldValid("value good") &&
        validator.current.fieldValid("deliverer");
    };

    if (apiType === "air") {
      airApicall(validate, postData);
    } else if (apiType === "sea") {
      seaApicall(validate, postData);
    } else if (apiType === "courier") {
      courierApicall(validate, postData);
    } else if (apiType === "land") {
      landApicall(validate, postData);
    }
  }

  const airApicall = (validate, postData) => {
    if (validate) {
      setLoader(true);
      quoteService
        .postQuote(postData)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            //setResultData({ res: result.data, req: result.request_data });
            //toggleForm();
            toast.success(result.message);
            // window.open('/get-estimate', {res: result.data, req: result.request_data, resultDataCourier: resultDataCourier })

            let jRes = JSON.stringify(result.data, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );
            let jReq = JSON.stringify(result.request_data, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );

            showJsonInWindow(jRes, jReq);
          } else {
            setLoader(false);
            toast.error(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      validator.current.showMessages();
    }
  };

  const seaApicall = (validate, postData) => {
    if (validate) {
      setLoader(true);
      quoteService
        .postSeaQuote(postData)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            // setResultData({ res: result.data, req: result.request_data });
            // toggleForm();
            toast.success(result.message);

            let jRes = JSON.stringify(result.data, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );
            let jReq = JSON.stringify(result.request_data, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );

            showJsonInWindow(jRes, jReq);
          } else {
            setLoader(false);
            toast.error(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      validator.current.showMessages();
    }
  };

  const landApicall = (validate, postData) => {
    if (validate) {
      setLoader(true);
      quoteService
        .getTntQuote(postData)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            // setResultData({ res: result.data, req: result.request_data });
            // toggleForm();
            toast.success(result.message);
            let jRes = JSON.stringify(result.data, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );
            let jReq = JSON.stringify(result.request_data, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );

            showJsonInWindow(jRes, jReq);
          } else {
            setLoader(false);
            toast.error(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      validator.current.showMessages();
    }
  };

  const courierApicall = (validate, postData) => {
    if (validate) {
      setLoader(true);
      quoteService
        .getCourierQuote(postData)
        .then((result) => {
          if (result.status === true) {
            setLoader(false);
            /* setResultDataCourier({
              Port_res: result.all_data.port_delivery,
              Door_res: result.all_data.door_delivery,
              fedex: result.all_data.fedex_courier_rates,
              ups: result.all_data.ups_courier_rates,
              req: result.request_data,
            }); */
            // toggleForm();
            toast.success(result.message);

            let resData = {};
            if (result.all_data.fedex_courier_rates) {
              resData.fedex_courier_rates =
                result.all_data.fedex_courier_rates[0];
            }
            if (result.all_data.ups_courier_rates) {
              resData.ups_courier_rates = result.all_data.ups_courier_rates[0];
            }
            if (result.all_data?.dhl_courier_rates) {
              resData.dhl_courier_rates = result.all_data.dhl_courier_rates[0];
            }
            resData.door_delivery = result.all_data.door_delivery;
            let jRes = JSON.stringify(resData, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );
            let jReq = JSON.stringify(result.request_data, null, 4).replace(
              /["{[,\}\]]/g,
              ""
            );

            showJsonInWindow(jRes, jReq);
          } else {
            setLoader(false);
            toast.error(result.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      validator.current.showMessages();
    }
  };

  const showJsonInWindow = (jRes, jReq) => {
    jRes = jRes.replace(new RegExp("_", "g"), " ");
    jReq = jReq.replace(new RegExp("_", "g"), " ");

    var myWindow = window.open("", "MsgWindow", "width=500,height=500");
    myWindow.document.write("Result:");
    myWindow.document.write(
      "<pre><div style='background-color:aliceblue;font-family: monospace;font-size: 16px;'>" +
      jRes +
      "</div></pre>"
    );
    myWindow.document.write("Requested:");
    myWindow.document.write(
      "<pre><div style='background-color:antiquewhite;font-family: monospace;font-size: 16px;'>" +
      jReq +
      "</div></pre>"
    );
  };

  const searchHandler = (event) => {
    if (event.length >= 3) {
      setKeyword(event);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getProductlist();
    }, 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [Keyword]);

  const getProductlist = () => {
    let searchData = {
      search: Keyword,
    };

    if (searchData) {
      productService.getProduct(searchData).then((result) => {
        if (result.status === true) {
          let Productarray = result.data.map((item) => ({
            value: item._id,
            label: item.product_name,
            data: item,
          }));
          setProductList(Productarray);
        } else {
          // toast.error(result.message);
        }
      });
    }
  };

  const calculaterChanger = (type) => {
    setTableType(type);
    // if(type === "table"){
    //   setProductItemForArray([])
    // }
    // if(type === "filter"){
    //   setVolumeList([])
    // }
  }

  console.log(DestinationValue, OrginValue)

  return (
    <section
      style={{ backgroundColor: "#fff" }}
      show={show}
      onHide={() => onClose()}
      size="xl"
    >
      <div className="modal-body">
        <div className="row">
          {visiblility ? (
            <Tab.Container
              id="left-tabs-example"
              className={valLocation ? "active" : "ddd"}
              defaultActiveKey="first_"
            >
              <div className="col-12 px-3">
                <h5 className="mb-3">Select Location</h5>
                <Form className="pb-4 ">
                  <Row>
                    <Col md={6}>
                      <label>From</label>
                      <AutoComplete getData={(e) => getOriginData(e)} />
                      {validator.current.message(
                        "search",
                        OrginValue,
                        "required",
                        { className: "text-danger text-capitalize" }
                      )}
                    </Col>
                    <Col md={6}>
                      <label>To</label>
                      <AutoComplete getData={(e) => getDestinationData(e)} />
                      {validator.current.message(
                        "search",
                        DestinationValue,
                        "required",
                        { className: "text-danger text-capitalize" }
                      )}
                    </Col>
                  </Row>
                </Form>
                <hr />
              </div>
              <Row>






              <Col md={8} className="col-md-6 d-flex ms-4 ps-0">
                          <div className="form-check mt-1 position-relative pb-3">
                            <input
                              className="form-check-input mt-2"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="RETAILER"
                              checked={deliverer === "RETAILER" ? true : false}
                              onChange={(e) => setDeliverer(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Retailer
                            </label>
                            {validator.current.message(
                              "deliverer",
                              deliverer,
                              "required",
                              {
                                className: "text-danger customMsg",
                              }
                            )}
                          </div>
                          <div className="form-check ms-3 mt-1">
                            <input
                              className="form-check-input mt-2"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="SELF"
                              checked={deliverer === "SELF" ? true : false}
                              onChange={(e) => setDeliverer(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              Self
                            </label>
                          </div>
                          <div className="form-check ms-3 mt-1">
                            <input
                              className="form-check-input mt-2"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              value="WORLD2DOOR"
                              checked={
                                deliverer === "WORLD2DOOR" ? true : false
                              }
                              onChange={(e) => setDeliverer(e.target.value)}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              World2door
                            </label>
                          </div>
                        </Col>









                <h5 className="mb-3 text-center">Cargo Details</h5>

                <Col md={8} className="mx-auto text-center">
                  <Nav variant="pills" activeKey="first_" className="mb-4">
                    <Nav.Item className="col mx-1">
                      <Nav.Link
                        className="outline-primary square-gradient"
                        eventKey="first_"
                        onClick={() => calculaterChanger("table")}
                      >
                        (Weight & Dimension) Of Each <br /> Package Available
                        <br />
                        <b>Get Rates</b>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="col mx-1">
                      <Nav.Link
                        className="outline-primary square-gradient"
                        eventKey="second"
                        onClick={() => calculaterChanger("filter")}
                      >
                        (Weight & Dimension) Of Each <br /> Package Not
                        Available <br />
                        <b>Get Rates</b>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first_">
                      <div className="col-12 px-3">
                        <hr />
                        <h5 className="mb-3 center">
                          Enter Product Information
                        </h5>
                        <div className="table-responsive">
                          <table className="table table-bordered text-center volume-calcu">
                            <thead>
                              <tr className="lh-sm">
                                <th rowspan="2" className="align-middle">
                                  S.no.
                                </th>
                                <th rowspan="2" className="align-middle">
                                  Item
                                  <br />
                                  Description
                                </th>
                                <th rowspan="2" className="align-middle">
                                  No. of Pkgs
                                </th>
                                <th colspan="4">
                                  Weight & Dimension per Package
                                </th>
                                <th rowspan="2" className="align-middle">
                                  Total Volume
                                  <br />
                                  (CBM)
                                </th>
                                <th rowspan="2" className="align-middle">
                                  Total Weight
                                  <br />
                                  (KG)
                                </th>
                                <th rowspan="2" className="align-middle">
                                  Action
                                </th>
                              </tr>

                              <tr>
                                <th>Kilogram</th>
                                <th>Length</th>
                                <th>width</th>
                                <th>Height</th>
                              </tr>
                            </thead>
                            <tbody>
                              {volumeList.length > 0 &&
                                volumeList.map((list, key) => (
                                  <tr key={key}>
                                    <td>{key + 1}</td>

                                    <td>
                                      <input
                                        className={
                                          "tbl-input" +
                                          (!list.PD && isClick
                                            ? " border-danger"
                                            : "")
                                        }
                                        type="text"
                                        onChange={(e) => onChangeEvent(key, e)}
                                        name="PD"
                                        value={list.PD}
                                        placeholder="Description"
                                      />
                                      {validator.current.message(
                                        "description",
                                        list.PD,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        className={
                                          "tbl-input" +
                                          (!list.PC && isClick
                                            ? " border-danger"
                                            : "")
                                        }
                                        type="number"
                                        min="1"
                                        onChange={(e) => onChangeEvent(key, e)}
                                        name="PC"
                                        value={list.PC}
                                        placeholder="No. of Count"
                                      />
                                      {validator.current.message(
                                        "PC",
                                        list.PC,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        min="1"
                                        className={
                                          "tbl-input" +
                                          (!list.KG && isClick
                                            ? " border-danger"
                                            : "")
                                        }
                                        type="number"
                                        onChange={(e) => onChangeEvent(key, e)}
                                        name="KG"
                                        value={list.KG}
                                        placeholder="KG"
                                      />

                                      {validator.current.message(
                                        "KG",
                                        list.KG,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        min="1"
                                        className={
                                          "tbl-input" +
                                          (!list.LN && isClick
                                            ? " border-danger"
                                            : "")
                                        }
                                        type="number"
                                        onChange={(e) => onChangeEvent(key, e)}
                                        name="LN"
                                        value={list.LN}
                                        placeholder="CM"
                                      />
                                      {validator.current.message(
                                        "LN",
                                        list.LN,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        min="1"
                                        className={
                                          "tbl-input" +
                                          (!list.WI && isClick
                                            ? " border-danger"
                                            : "")
                                        }
                                        type="number"
                                        onChange={(e) => onChangeEvent(key, e)}
                                        name="WI"
                                        value={list.WI}
                                        placeholder="CM"
                                      />
                                      {validator.current.message(
                                        "WI",
                                        list.WI,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        min="1"
                                        className={
                                          "tbl-input" +
                                          (!list.HI && isClick
                                            ? " border-danger"
                                            : "")
                                        }
                                        type="number"
                                        onChange={(e) => onChangeEvent(key, e)}
                                        name="HI"
                                        value={list.HI}
                                        placeholder="CM"
                                      />
                                      {validator.current.message(
                                        "HI",
                                        list.HI,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        className="tbl-input"
                                        type="number"
                                        disabled
                                        name="CBM"
                                        value={
                                          list.CBM <= 0.0 ? 0.001 : list.CBM
                                        }
                                        placeholder="CBM"
                                      />
                                    </td>

                                    <td>
                                      <input
                                        className="tbl-input"
                                        type="number"
                                        disabled
                                        name="TKG"
                                        value={list.TKG}
                                        placeholder="KG"
                                      />
                                    </td>

                                    <td>
                                      <img
                                        className="btn"
                                        height="20"
                                        src="/assets/images/delete.svg"
                                        onClick={() => onClickDelete(key)}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <th className="text-end">Total</th>
                                <th colSpan="1"></th>
                                <th colSpan="1">
                                  {grandTotal.number_of_packages}
                                </th>
                                <th colSpan="4"></th>
                                <th className="text-center">
                                  {grandTotal.volume <= 0.0
                                    ? 0.001
                                    : grandTotal.volume}
                                </th>
                                <th className="text-center">
                                  {grandTotal.weight}
                                </th>
                                <th></th>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="row">
                            <div className="col-md-4 text-left">
                              <p className="me-2 mb-0 fw-bold">
                                Total Shipment Value
                              </p>
                            </div>
                            <div className="col-md-6">
                              <input
                                className="form-control"
                                type="number"
                                min="1"
                                name="goods_value"
                                onChange={(e) => setGoodValue(e.target.value)}
                              />

                              {validator.current.message(
                                "good value",
                                goodValue,
                                "required",
                                { className: "text-danger" }
                              )}
                            </div>
                          </div>

                          <div className="text-right">
                            <button
                              className="themebutton px-4 ms-auto"
                              onClick={() => onClickAdd()}
                            >
                              + Add Item
                            </button>
                          </div>
                        </div>

                        <hr />

                        <div className="row text-center ">
                          <div className="col-md-12">
                            <div className="d-flex">
                              <button
                                type="button"
                                className="themebutton px-4 me-3"
                                onClick={() => submitQuoteData("table", "air")}
                              >
                                GET AIR RATES
                              </button>
                              <button
                                type="button"
                                className="themebutton px-4 me-3"
                                onClick={() => submitQuoteData("table", "sea")}
                              >
                                GET SEA RATES
                              </button>
                              <button
                                type="button"
                                className="themebutton px-4 me-3"
                                onClick={() =>
                                  submitQuoteData("table", "courier")
                                }
                              >
                                GET COURIER RATES
                              </button>
                              <button
                                type="button"
                                className="themebutton px-4 "
                                onClick={() => submitQuoteData("table", "land")}
                              >
                                GET LAND RATES
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="col-12  px-3">
                        <hr />
                        <div className="d-flex justify-content-center mb-3">
                          {/* <h5 className="mb-3 center">
                            Enter Product Information
                          </h5> */}

                          {/* <input
                            type="text"
                            className="form-control"
                            name="search"
                            value={selectedValue}
                            onChange={(event) => handleChange(event)}
                          />
                          <ul>
                            {productList &&
                              productList.map((item) => (
                                item ?
                                <li>{item.product_name}</li>
                                :
                                null
                              ))}
                          </ul> */}
                          <div className="col-6">
                            <h5 className="mb-3 text-center">
                              Select Your Product
                            </h5>

                            {productList && (
                              <ReactMultiSelectCheckboxes
                                className="Volumeselect form-control"
                                options={productList}
                                value={itemValue}
                                onChange={handleChange}
                                onInputChange={(e) => searchHandler(e)}
                                placeholder="Search Product"
                                name="item_description"
                              // setState={productList}
                              />
                            )}
                            {validator.current.message(
                              "filter",
                              itemValue,
                              "required",
                              { className: "text-danger" }
                            )}
                          </div>
                        </div>

                        <div className="table-responsive_">
                          <table className="table table-bordered text-center volume-calcu">
                            <thead>
                              <tr className="lh-sm">
                                <th rowspan="2" className="align-middle">
                                  S.no.
                                </th>
                                <th rowspan="2" className="align-middle">
                                  Item Description
                                </th>
                                <th rowspan="2" className="align-middle">
                                  Quantity
                                </th>
                                <th rowspan="2" className="align-middle">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {productItem &&
                                productItem.length > 0 &&
                                productItem.map((item, key) => (
                                  <tr key={key}>
                                    <td>{key + 1}</td>

                                    <td>
                                      <input
                                        type="text"
                                        value={item.item_description}
                                        className="form-control"
                                        readOnly
                                      ></input>
                                      {validator.current.message(
                                        "package description",
                                        item.item_description,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        className="tbl-input"
                                        type="number"
                                        min="1"
                                        name="number_of_packages"
                                        value={item.no_of_pkgs}
                                        onChange={(e) =>
                                          mychangeEvent(key, e, item.item_id)
                                        }
                                      />
                                      {validator.current.message(
                                        "quantity",
                                        item.no_of_pkgs,
                                        "required",
                                        { className: "text-danger customMsg" }
                                      )}
                                    </td>
                                    <td>
                                      <img
                                        className="btn"
                                        height="20"
                                        src="/assets/images/delete.svg"
                                        onClick={() =>
                                          onSecondClickDelete(key, item.item_id)
                                        }
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>

                          <div className="row justify-content-end">
                            <div className="col-md-4 text-end">
                              <p className="me-2 mb-0 fw-bold">
                                Total Shipment Value
                              </p>
                            </div>
                            <div className="col-md-3">
                              <input
                                className="form-control"
                                type="number"
                                min="1"
                                name="goods_value"
                                onChange={(e) =>
                                  setFilterGoodValue(e.target.value)
                                }
                              />
                              <div>
                                {validator.current.message(
                                  "value good",
                                  filterGoodValue,
                                  "required",
                                  { className: "text-danger" }
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row text-center ">
                            <div className="col-md-12">
                              <div className="d-flex">
                                <button
                                  type="button"
                                  className="themebutton px-4 me-3"
                                  onClick={() =>
                                    submitQuoteData("filter", "air")
                                  }
                                >
                                  GET AIR RATES
                                </button>
                                <button
                                  type="button"
                                  className="themebutton px-4 me-3"
                                  onClick={() =>
                                    submitQuoteData("filter", "sea")
                                  }
                                >
                                  GET SEA RATES
                                </button>
                                <button
                                  type="button"
                                  className="themebutton px-4 me-3"
                                  onClick={() =>
                                    submitQuoteData("filter", "courier")
                                  }
                                >
                                  GET COURIER RATES
                                </button>
                                <button
                                  type="button"
                                  className="themebutton px-4 "
                                  onClick={() =>
                                    submitQuoteData("filter", "land")
                                  }
                                >
                                  GET LAND RATES
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          ) : (
            <>
              {resultData.res && resultData.req && (
                <Row>
                  <Col md={6} className="mb-3">
                    <h5 className="mb-3 center">Result</h5>
                    <div>
                      <pre>{JSON.stringify(resultData.res, null, 2)}</pre>
                    </div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <h5 className="mb-3 center">Request</h5>

                    <p>
                      <pre> {JSON.stringify(resultData.req, null, 2)}</pre>
                    </p>
                    <p className="text-left">
                      <a href="javascript:void(0)" onClick={() => toggleForm()}>
                        Back
                      </a>
                    </p>
                  </Col>
                </Row>
              )}

              {resultDataCourier && (
                <Row>
                  <Col md={6} className="mb-3">
                    <h5 className="mb-3 center">Result</h5>
                    <div>
                      <p>
                        <pre>
                          {" "}
                          Fedex Courier rates : &nbsp;{" "}
                          {JSON.stringify(resultDataCourier.fedex, null, 2)}
                        </pre>

                        <hr />

                        <pre>
                          {" "}
                          Ups Courier rates : &nbsp;{" "}
                          {JSON.stringify(resultDataCourier.ups, null, 2)}
                        </pre>
                      </p>

                      <hr />

                      <p>
                        <pre>
                          {" "}
                          Port Delivery : &nbsp;{" "}
                          {JSON.stringify(resultDataCourier.Port_res, null, 2)}
                        </pre>

                        <hr />

                        <pre>
                          Door Delivery : &nbsp;{" "}
                          {JSON.stringify(resultDataCourier.Door_res, null, 2)}
                        </pre>
                      </p>
                    </div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <h5 className="mb-3 center">Request</h5>
                    <p>
                      <pre>
                        {JSON.stringify(resultDataCourier.req, null, 2)}
                      </pre>
                    </p>
                    <p className="text-left">
                      <a href="javascript:void(0)" onClick={() => toggleForm()}>
                        Back
                      </a>
                    </p>
                  </Col>
                </Row>
              )}
            </>
          )}
        </div>
      </div>
      <script
        async
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDPEH1Sl7gE5ZVxx_TQ0koqa69dnnRlryw&libraries=places"
      ></script>

      {loader && (
        <div className="loader_lst">
          <img
            src="/assets/images/JOP.gif"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      )}
    </section>
  );
}

// extra useless code

// const submitSeaQuoteData = () => {
//   setLoader(true);
//   let random_key = getRandomkeys();
//   let knownData = volumeList.map((item) => ({
//     item_id: "",
//     item_description: item.PD,
//     no_of_pkgs: Number(item.PC),
//     dimension: {
//       kilogram: Number(item.KG),
//       length: Number(item.LN),
//       width: Number(item.WI),
//       height: Number(item.HI),
//     },
//     total_volume_CBM: Number(item.CBM),
//     total_weight_KG: Number(item.PC * item.KG),
//     item_token: helperFunction.stringGenerator(24),
//   }));

//   let postData = {
//     ...OrginValue,
//     ...DestinationValue,
//     number_of_packages: grandTotal.number_of_packages
//       ? grandTotal.number_of_packages
//       : packages,
//     volume:
//       grandTotal.volume <= 0.0
//         ? 0.001
//         : parseFloat(grandTotal.volume)
//         ? parseFloat(grandTotal.volume)
//         : itemValue.data.product_volume,
//     weight: grandTotal.weight
//       ? grandTotal.weight
//       : itemValue
//       ? itemValue.data.product_gross_weight
//       : grandTotal.weight,
//     items_goods: Number(goodValue),
//     items: JSON.stringify(knownData),
//     quote_token: random_key,
//   };

//   if (type == "table") {
//     var validate =
//       validator.current.fieldValid("search") &&
//       validator.current.fieldValid("PD") &&
//       validator.current.fieldValid("PC") &&
//       validator.current.fieldValid("KG") &&
//       validator.current.fieldValid("LN") &&
//       validator.current.fieldValid("WI") &&
//       validator.current.fieldValid("HI") &&
//       validator.current.fieldValid("good value");
//   } else if (type == "filter") {
//     var validate =
//       validator.current.fieldValid("search") &&
//       validator.current.fieldValid("filter") &&
//       validator.current.fieldValid("quantity") &&
//       validator.current.fieldValid("value good");
//   }

// };

// const submitTntData = () => {
//   setLoader(true);
//   let random_key = getRandomkeys();
//   let knownData = volumeList.map((item) => ({
//     item_id: "",
//     item_description: item.PD,
//     no_of_pkgs: Number(item.PC),
//     dimension: {
//       kilogram: Number(item.KG),
//       length: Number(item.LN),
//       width: Number(item.WI),
//       height: Number(item.HI),
//     },
//     total_volume_CBM: Number(item.CBM),
//     total_weight_KG: Number(item.PC * item.KG),
//     item_token: helperFunction.stringGenerator(24),
//   }));

//   let postData = {
//     ...OrginValue,
//     ...DestinationValue,
//     number_of_packages: grandTotal.number_of_packages
//       ? grandTotal.number_of_packages
//       : packages,
//     volume:
//       grandTotal.volume <= 0.0
//         ? 0.001
//         : parseFloat(grandTotal.volume)
//         ? parseFloat(grandTotal.volume)
//         : itemValue.data.product_volume,
//     weight: grandTotal.weight
//       ? grandTotal.weight
//       : itemValue
//       ? itemValue.data.product_gross_weight
//       : grandTotal.weight,
//     items_goods: Number(goodValue),
//     items: JSON.stringify(knownData),
//     quote_token: random_key,
//   };

//   if (type == "table") {
//     var validate =
//       validator.current.fieldValid("search") &&
//       validator.current.fieldValid("PD") &&
//       validator.current.fieldValid("PC") &&
//       validator.current.fieldValid("KG") &&
//       validator.current.fieldValid("LN") &&
//       validator.current.fieldValid("WI") &&
//       validator.current.fieldValid("HI") &&
//       validator.current.fieldValid("good value");
//   } else if (type == "filter") {
//     var validate =
//       validator.current.fieldValid("search") &&
//       validator.current.fieldValid("filter") &&
//       validator.current.fieldValid("quantity") &&
//       validator.current.fieldValid("value good");
//   }

// };

// const submitCourierData = () => {
//   setLoader(true);
//   let random_key = getRandomkeys();
//   let knownData = volumeList.map((item) => ({
//     item_id: "",
//     item_description: item.PD,
//     no_of_pkgs: Number(item.PC),
//     dimension: {
//       kilogram: Number(item.KG),
//       length: Number(item.LN),
//       width: Number(item.WI),
//       height: Number(item.HI),
//     },
//     total_volume_CBM: Number(item.CBM),
//     total_weight_KG: Number(item.PC * item.KG),
//     item_token: helperFunction.stringGenerator(24),
//   }));

//   let postData = {
//     ...OrginValue,
//     ...DestinationValue,
//     number_of_packages: grandTotal.number_of_packages
//       ? grandTotal.number_of_packages
//       : packages,
//     volume:
//       grandTotal.volume <= 0.0
//         ? 0.001
//         : parseFloat(grandTotal.volume)
//         ? parseFloat(grandTotal.volume)
//         : itemValue.data.product_volume,
//     weight: grandTotal.weight
//       ? grandTotal.weight
//       : itemValue
//       ? itemValue.data.product_gross_weight
//       : grandTotal.weight,
//     items_goods: Number(goodValue),
//     items: JSON.stringify(knownData),
//     quote_token: random_key,
//   };

//   if (type == "table") {
//     var validate =
//       validator.current.fieldValid("search") &&
//       validator.current.fieldValid("PD") &&
//       validator.current.fieldValid("PC") &&
//       validator.current.fieldValid("KG") &&
//       validator.current.fieldValid("LN") &&
//       validator.current.fieldValid("WI") &&
//       validator.current.fieldValid("HI") &&
//       validator.current.fieldValid("good value");
//   } else if (type == "filter") {
//     var validate =
//       validator.current.fieldValid("search") &&
//       validator.current.fieldValid("filter") &&
//       validator.current.fieldValid("quantity") &&
//       validator.current.fieldValid("value good");
//   }

//   if (validate) {
//     quoteService
//       .getCourierQuote(postData)
//       .then((result) => {
//         if (result.status === true) {
//           setLoader(false);
//           /* setResultDataCourier({
//             Port_res: result.all_data.port_delivery,
//             Door_res: result.all_data.door_delivery,
//             fedex: result.all_data.fedex_courier_rates,
//             ups: result.all_data.ups_courier_rates,
//             req: result.request_data,
//           }); */
//           // toggleForm();
//           toast.success(result.message);

//           let resData = {};
//           if (result.all_data.fedex_courier_rates) {
//             resData.fedex_courier_rates =
//               result.all_data.fedex_courier_rates[0];
//           }
//           if (result.all_data.ups_courier_rates) {
//             resData.ups_courier_rates = result.all_data.ups_courier_rates[0];
//           }
//           resData.door_delivery = result.all_data.door_delivery;
//           let jRes = JSON.stringify(resData, null, 4).replace(
//             /["{[,\}\]]/g,
//             ""
//           );
//           let jReq = JSON.stringify(result.request_data, null, 4).replace(
//             /["{[,\}\]]/g,
//             ""
//           );

//           showJsonInWindow(jRes, jReq);
//         } else {
//           setLoader(false);
//           toast.error(result.message);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   } else {
//     validator.current.showMessages();
//   }
// };

