import React, { useEffect } from "react";
import Home from "../Container/home/Home";
import About from "../Components/About";
import Track from "../Components/Track";
import ProtectedRoutes from "./ProtectedRoutes";
import { Route } from "react-router-dom";
import CountryGuide from "../Components/CountryGuide";
import Faq from "../Components/Faq";
import HowItWorks from "../Components/HowItWorks";
import Login from "../Container/auth/Login";
import ForgetPassword from '../Container/auth/ForgetPassword';
import SavedQuotes from "../Components/SavedQuotes";
import MyBookings from "../Components/MyBookings";
import MyInvoices from "../Components/MyInvoices";
import MyDocuments from "../Components/MyDocuments";
import OrderHistory from "../Components/OrderHistory";
import ProhibitedItems from "../Components/ProhibitedItems";
import Profile from "../Components/Profile";
import Services from "../Container/services/Services";
import RefundPolicy from "../Components/RefundPolicy";
import CancellationPolicy from "../Components/CancellationPolicy";
import InsuranceTermsConditions from "../Components/InsuranceTermsConditions";
import TermsCondition from "../Components/TermsCondition";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import PublicRoutes from "./PublicRoutes";
import BookingDetails from "../Components/BookingDetails";
import GetQuote from "../Components/Quotation/GetQuote";
import Register from "../Container/auth/Register";
import SignUpOtp from "../Container/auth/SignUpOtp";
import RetailerLogin from "../Container/auth/RetailerLogin";
import StoreLogin from "../Container/auth/StoreLogin";
import Verification from "../Container/auth/Verification";
import { store } from "../Redux/store";
import TipsAndMyths from "../Components/TipsAndMyths";
import Retailer from "../Components/Retailer";
import GetInstruction from "../Components/Quotation/GetInstruction";
import BookingConfirm from "../Components/BookingConfirm";
import Store from "../Components/Store";
import Dashboard from "../Components/Dashboard";
import StoreBooking from "../Components/StoreBooking";
import VolumeCalculatorcopy from "../Container/home/VolumeCalculatorcopy";
import EstimateQuote from "../Container/home/EstimateQuote";
import StoreDetails from "../Components/StoreDetails";
import AllBooking from "../Components/Bookings/AllBookings";
import Product from "../Components/Product";
import StoreProfile from "../Components/StoreProfile";
import AllBookingStore from "../Components/AllBookingStore";
import AllBookingsManagement from "../Components/Bookings/AllBookingsManagement";
import ManagementLogin from "../Container/auth/ManagementLogin";
import GetQuoteDetail from "../Components/Quotation/GetQuoteDetail";
import Pickup from "../Components/Quotation/quoteDetails/Pickup.jsx";
import ResetPassword from "../Container/auth/ResetPassword";
import EnquiryManagementCRMList from "../Components/EnquiryManagementCRMList.js";
import RetailerInvoice  from "../Components/Bookings/RetailerInvoice.js";
import Relocation from "../Components/Relocation/Relocation";
import GalleryList from "../Components/Gallery/GalleryList";
import BlogList from "../Components/Blogs/BlogList";
import BlogDetail from "../Components/Blogs/BlogDetail";

const isLogin = store.getState().userDetails
const { isLogged } = isLogin

const MyRoutes = ({ isStateChange }) => {
  useEffect(() => {
    isStateChange(userDetails());
  }, [window.location.pathname]);
  return (
    <>
      <Route exact path="/reset_password">
        <PublicRoutes component={ResetPassword} />
      </Route>
      <Route exact path="/login">
        <PublicRoutes component={Login} />
      </Route>
      <Route exact path="/register">
        <PublicRoutes component={Register} />
      </Route>
      <Route exact path="/verify-otp/:id">
        <PublicRoutes component={SignUpOtp} />
      </Route>
      <Route exact path="/retailer_login">
        <PublicRoutes component={RetailerLogin} />
      </Route>
      <Route exact path="/store_login">
        <PublicRoutes component={StoreLogin} />
      </Route>
      <Route exact path="/management_login">
        <PublicRoutes component={ManagementLogin} />
      </Route>

      <Route exact path="/getquote/:id">
        {
          isLogged 
          ? <ProtectedRoutes Cmp={GetQuoteDetail} />
          : <PublicRoutes component={GetQuoteDetail} />
        }
      </Route>

      {/* <Route component={Retailer} exact path="/retailer" /> */}

      <Route exact path="/retailer">
        <ProtectedRoutes Cmp={Retailer} />
      </Route>

      <Route exact path="/my-saved-quotes">
        <ProtectedRoutes Cmp={SavedQuotes} />
      </Route>

      <Route exact path="/my-bookings">
        <ProtectedRoutes Cmp={MyBookings} />
      </Route>

      <Route exact path="/my-invoice">
        <ProtectedRoutes Cmp={MyInvoices} />
      </Route>

      <Route exact path="/my-documents">
        <ProtectedRoutes Cmp={MyDocuments} />
      </Route>

      <Route exact path="/orders-history">
        <ProtectedRoutes Cmp={OrderHistory} />
      </Route>

      <Route exact path="/booking-details/:id">
        <ProtectedRoutes Cmp={BookingDetails} />
      </Route>

      <Route exact path="/store">
        <ProtectedRoutes Cmp={Store} />
      </Route>
      <Route exact path="/store-details/:id">
        <ProtectedRoutes Cmp={StoreDetails} />
      </Route>
      <Route exact path="/dashboard">
        <ProtectedRoutes Cmp={Dashboard} />
      </Route>
      <Route exact path="/bookings">
        <ProtectedRoutes Cmp={StoreBooking} />
      </Route>
      <Route exact path="/all-bookings">
        <ProtectedRoutes Cmp={AllBooking} />
      </Route>
      <Route exact path="/all-bookings-store">
        <ProtectedRoutes Cmp={AllBookingStore} />
      </Route>
      <Route exact path="/all-bookings-management">
        <ProtectedRoutes Cmp={AllBookingsManagement} />
      </Route>
      <Route exact path="/product">
        <ProtectedRoutes Cmp={Product} />
      </Route>
      <Route exact path="/store-profile">
        <ProtectedRoutes Cmp={StoreProfile} />
      </Route>
      <Route exact path="/get-quote-detail/:id">
        <ProtectedRoutes Cmp={GetQuoteDetail} />
      </Route>
      <Route exact path="/enquiry-management-c-r-m-list">
        <ProtectedRoutes Cmp={EnquiryManagementCRMList} />
      </Route>
      <Route exact path="/retailer-invoice/:job_id">
        <ProtectedRoutes Cmp={RetailerInvoice} />
      </Route>

      {/* <Redirect to="/" from="*" /> */}

      <Route component={Home} exact path="/" />
      <Route component={Services} exact path="/services" />
      <Route component={Verification} exact path="/verification/:usertoken" />
      <Route component={About} exact path="/about" />
      <Route component={HowItWorks} exact path="/how-it-works" />
      <Route component={Track} exact path="/track" />
      <Route component={CountryGuide} exact path="/country-guide" />
      <Route component={Faq} exact path="/faq" />
      <Route component={ProhibitedItems} exact path="/prohibited-items" />
      <Route component={Profile} exact path="/my-profile" />
      <Route component={RefundPolicy} exact path="/refund-policy" />
      <Route component={CancellationPolicy} exact path="/cancellation-policy" />
      <Route
        component={InsuranceTermsConditions}
        exact
        path="/insurance-terms-conditions"
      />
      <Route component={TermsCondition} exact path="/terms-and-conditions" />
      <Route component={PrivacyPolicy} exact path="/privacy-policy" />
      <Route component={GetQuote} exact path="/quote_detail" />
      <Route component={GetQuote} exact path="/get-quote" />
      <Route component={GetInstruction} exact path="/get-instruction" />
      <Route component={BookingConfirm} exact path="/booking-confirm" />
      <Route component={TipsAndMyths} exact path="/tips-and-myths" />
      <Route component={VolumeCalculatorcopy} exact path="/get-rates" />
      <Route component={EstimateQuote} exact path="/get-estimate" />
      <Route component={ForgetPassword} exact path='/forget_password' />;
      <Route component={Pickup} exact path="/pickup" />
      <Route component={Relocation} exact path="/relocation" />
      <Route component={GalleryList} exact path="/gallery" />
      <Route component={BlogList} exact path="/blog" />
      <Route component={BlogDetail} exact path="/blog-detail/:id" />
    </>
  );
};

export default MyRoutes;

export const userDetails = () => {
  return store.getState().userDetails;
};
store.subscribe(userDetails);
