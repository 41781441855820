import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal, Col, Row, Table, Form, Spinner } from 'react-bootstrap-v5'
import { QuoteDetailContext } from '../GetQuoteDetail'
import { QUOTE_DETAIL } from '../../../Redux/type';
import { quoteService } from '../../../services';
import helperFunction from '../helperFunction';

export default function Cargo({ showCargoItems, handleCargo, reQuote }) {
    const { state, dispatch } = useContext(QuoteDetailContext);
    const [cargoItems, setCargoItems] = useState([])

    useEffect(() => {
        if (state) {
            const itmes = JSON.parse(JSON.stringify(state.quoteDetails.quote_request_items))
            setCargoItems(itmes)
        }
    }, [])

    useEffect(() => {
        
    }, [cargoItems])

    const handleChangeItem = (e, index) => {
        const {name, value} = e.target
        let items = [...cargoItems]
        items[index].actual[name] = value

        if(items[index].actual.length !== 0 && items[index].actual.width !== 0 && items[index].actual.height !== 0){
            let dimension = {length: items[index].actual.length, width: items[index].actual.width, height: items[index].actual.height }
            
            items[index].actual.total_volume_CBM = Number(items[index].actual.no_of_pkgs) * helperFunction.calculateVolume(dimension)
            items[index].actual.total_weight_KG = Number(items[index].actual.no_of_pkgs) * Number(items[index].actual.kilogram)
        }

        setCargoItems(items)
    }

    const handleAddItem = () => {
        const item = {
            actual: {
                item_description: "",
                kilogram: 0,
                length: 0,
                width: 0,
                height: 0,
                no_of_pkgs: 0,
                total_volume_CBM: 0,
                total_weight_KG: 0
            },
            item_token: helperFunction.stringGenerator(24),
        }
        setCargoItems([...cargoItems, item])
    }

    const handleRemoveItem = (index) => {
        let items = [...cargoItems]
        items.splice(index, 1)
        setCargoItems(items)
    }

    const handleClose = () => handleCargo(false);
    const handleReQuote = () => {
        
        let resData = {
                ...state.quoteDetails,
                quote_request_items: cargoItems
            }
        dispatch({ type: QUOTE_DETAIL, payload: resData })
        reQuote(cargoItems)
        handleClose()
    }

    if (!state) {
        return 'Loading...'
    } else {
        const info = state.quoteDetails

        return (
            <Modal show={showCargoItems} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row className="justify-content-md-center">
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th rowSpan="2">S.No.</th>
                                        <th rowSpan="2">Item Description</th>
                                        <th rowSpan="2">Pkgs (No.)</th>
                                        <th colSpan="4">Weight & Dimension per Package</th>
                                        <th rowSpan="2">Total Volume (CBM) </th>
                                        <th rowSpan="2">Total Weight (KG)</th>
                                        <th rowSpan="2" width="50">Action</th>
                                    </tr>
                                    <tr>
                                        <th>Kilogram</th>
                                        <th>Length</th>
                                        <th>width</th>
                                        <th>Height</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cargoItems.length > 0
                                        && cargoItems.map((item, index) => {
                                            return <tr key={item.actual.item_description + index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="tbl-input"
                                                        name="item_description"
                                                        value={item.actual.item_description}
                                                        onChange={(e) => handleChangeItem(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="tbl-input"
                                                        name="no_of_pkgs"
                                                        value={item.actual.no_of_pkgs}
                                                        onChange={(e) => handleChangeItem(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="tbl-input"
                                                        name="kilogram"
                                                        value={item.actual.kilogram}
                                                        onChange={(e) => handleChangeItem(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="tbl-input"
                                                        name="length"
                                                        value={item.actual.length}
                                                        onChange={(e) => handleChangeItem(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="tbl-input"
                                                        name="width"
                                                        value={item.actual.width}
                                                        onChange={(e) => handleChangeItem(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="tbl-input"
                                                        name="height"
                                                        value={item.actual.height}
                                                        onChange={(e) => handleChangeItem(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    {item.actual.total_volume_CBM}
                                                </td>

                                                <td>
                                                    {item.actual.total_weight_KG}
                                                </td>
                                                <td>
                                                    <img
                                                        className="btn"
                                                        height="20"
                                                        src="../assets/images/delete.svg"
                                                        onClick={() => handleRemoveItem(index)}
                                                        alt="Remove"
                                                    />
                                                </td>
                                            </tr>
                                        })

                                    }

                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Button variant="primary" onClick={() => handleAddItem()}>Add</Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleReQuote()}>Get Rate</Button>
                </Modal.Footer>

            </Modal>
        )
    }
}
