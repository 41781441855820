import React, { useEffect, useState, useRef } from 'react'
import { authServices } from '../../services';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import simpleReactValidator from "simple-react-validator";

export default function ResetPassword() {

  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );

  const [passwordDetail, setPasswordDetails] = useState({
    user_password: '',
    confirm_password: ''
  })

  const history = useHistory();

  useEffect(() => {
    let srcParam = new URLSearchParams(history.location.search).values()

    let resData = []
    for (const values of srcParam) {
      resData.push(values)
    }
    setPasswordDetails({ ...passwordDetail, first_token: resData[0], second_token: resData[1], t: resData[2] })

  }, [])

  const Handlechange = (event) => {
    const { value, name } = event.target;
    setPasswordDetails({ ...passwordDetail, [name]: value });
  };
  const HandleSubmit = () => {


    let validate = validator.current.allValid();

    if (validate) {
      authServices.resetPassword(passwordDetail).then((result) => {
        if (result.status === true) {
           history.push("/login");
           toast.success(result.message);
        } else {
           toast.error(result.message);
        }
      });
    } else {
      validator.current.showMessages();
    }

  };

  return (
    <div className="loginpage">
      <img src="assets/images/login_bg.jpg" className="loginbg" />
      <div className="flex-grow-1 text-center loginchar">
      </div>
      <div className="loginbox">

        <div><h2 className="mb-5">Reset Password</h2></div >

        <div className='w-100'>

          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="New Password"
              onChange={(event) => Handlechange(event)}
              name="user_password"
            />
            <label for="floatingPassword">New Password
            </label>
          </div>
          {validator.current.message("New Password", passwordDetail.user_password, "required|min:8", {
            className: "text-danger",
          })}
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="floatingConfirmPassword"
              placeholder="Confirm Password"
              onChange={(event) => Handlechange(event)}
              name="confirm_password"
            />
            <label for="floatingConfirmPassword">Confirm Password
            </label>

          </div>
          {validator.current.message("Confirm Password", passwordDetail.confirm_password, `required|in:${passwordDetail.user_password}`, {
            className: "text-danger",
          })}
          <div className="mt-3">
            <button onClick={() => HandleSubmit()} className="themebutton w-100" type="button">
              Submit
            </button>
          </div>
        </div>

      </div>
    </div>
  )
}