import React from "react";

const WhyChooseUs = () => {
  return (
    <section className="pt-5 pb-5 section_bg">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <p
              style={{ color: "#C27E0E", fontSize: "20px" }}
              className="section_heading mb-2"
            >
              Why Choose us
            </p>
            <h2
              className="mb-2"
              style={{ color: "#101518", width: "65%", fontSize: "38px" }}
            >
              We’ll take care of your stuff like it was our own
            </h2>
            {/* <p
              style={{ width: "90%", fontSize: "16px" }}
              className="text-muted"
            >
              Curae lobortis orci aliquam consectetuer mollis. Habitant faucibus
              scelerisque etiam accumsan euismod penatibus vestibulum diam.
            </p> */}
            <div className="row">
              <div className="mt-3 mb-3 col-md-6 d-flex align-items-center">
                <span>
                  <img
                    src="/assets/images/usericon.png"
                    style={{ width: "65px", height: "65px" }}
                    className="me-3 myicon "
                    alt="Not found"
                  />
                </span>
                <h6 style={{ fontSize: "18px" }}>
                  {" "}
                  Only Experienced / Trusted / Professional Teams handle the
                  jobs.{" "}
                </h6>
              </div>
              <div className="mt-3 mb-3 col-md-6 d-flex align-items-center">
                <span>
                  <img
                    src="/assets/images/light.png"
                    style={{ width: "65px", height: "65px" }}
                    className="me-3 myicon "
                    alt="Not found"
                  />
                </span>
                <h6 style={{ fontSize: "18px" }}>
                  In depth knowledge about Relocations.
                </h6>
              </div>
              <div className="mt-3 mb-3 col-md-6 d-flex align-items-center">
                <span>
                  <img
                    src="/assets/images/handsIcon.png"
                    style={{ width: "65px", height: "65px" }}
                    className="me-3 myicon "
                    alt="Not found"
                  />
                </span>
                <h6 style={{ fontSize: "18px" }}>
                  Extremely low damage record certified by Insurance Company.
                </h6>
              </div>
              <div className="mt-3 mb-3 col-md-6 d-flex align-items-center">
                <span>
                  <img
                    src="/assets/images/truck.png"
                    style={{ width: "65px", height: "65px" }}
                    className="me-3 myicon "
                    alt="Not found"
                  />
                </span>
                <h6 style={{ fontSize: "18px" }}>
                  Provides multiple shipping options ( Air/Sea/Land Courier) to
                  suit the need of the customer.
                </h6>
              </div>
              <div className="mt-3 mb-3 col-md-6 d-flex align-items-center">
                <span>
                  <img
                    src="/assets/images/moneytransfer.png"
                    style={{ width: "65px", height: "65px" }}
                    className="me-3 myicon "
                    alt="Not found"
                  />
                </span>
                <h6 style={{ fontSize: "18px" }}>
                  Very competitive shipping rates on all modes of shipping.
                </h6>
              </div>
              <div className="mt-3 mb-3 col-md-6 d-flex align-items-center">
                <span>
                  <img
                    src="/assets/images/hands.png"
                    style={{ width: "65px", height: "65px" }}
                    className="me-3 myicon "
                    alt="Not found"
                  />
                </span>
                <h6 style={{ fontSize: "18px" }}>
                  Trusted by many Multinational Retailers to ship their
                  expensive products worldwide for their retail customers.
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-5 align-items-center d-flex">
            <img
              src="/assets/images/curiortransfer.png"
              style={{ height: "360px" }}
              className="pe-3 ms-5"
              alt="Not found"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
