import React, { useContext } from 'react'
import { BookingDetailContext } from '../BookingDetails'

export default function AddOne() {

    const {state, disptach} = useContext(BookingDetailContext);
    if(!state) {
        return 'Loading...'
    } else {
        const info = state.bookingDetails.payment_information
        return (
            <div>
                <h5 className="color-green">Add-ons</h5>
                <table width="100%" style={{ minWidth: '100%' }} className="table table-striped">
                    <tbody>
                        <tr>
                            <td>
                            Packing
                            </td>
                            <td className="text-right">
                               { info.breakdown.packing_charge } AED 
                            </td>
                            
                        </tr>
                         <tr>
                            <td>
                                Insurance
                            </td>
                            <td className="text-right">
                                { info.breakdown.insurance } AED
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                Storage
                            </td>
                            <td className="text-right">
                            { info.breakdown.storage } AED
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    
}
