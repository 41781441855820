import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// import { Alert } from "react-bootstrap-v5";
import { Storage } from "../../Storage";
import { UserDetails } from "../../Redux/action";
import { useDispatch } from "react-redux";
import { authServices } from "../../services";
import simpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

const Login = () => {
  // const fcmtoken = Storage.get("FCM");
  const [, setForceUpdate] = useState("");
  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );

  const [myLogin, setLogin] = useState({
    user_login: "",
    user_password: "",
    user_firebase_token: "abcdefghijklm",
    user_device_token: "WEB",
    user_device_type: "WEB",
  });

  //   const [showFeild, setShowFeild] = useState("phone");
  const dispatch = useDispatch();

  const history = useHistory();

  const Handlechange = (event) => {
    setLogin({ ...myLogin, [event.target.name]: event.target.value });
  };

  

  const HandleSubmit = (event) => {
    event.preventDefault();
    let validate = validator.current.allValid();
    if (validate) {
      authServices.login(myLogin).then((result) => {
        if (result.status === true) {
            Storage.set("user-token", result.data);
            Storage.set("user-auth", result.data);
            dispatch(UserDetails({ data: result.data }));
            history.push({
              pathname: "/",
              msg: result.message,
            });
        } else if(result.response_status===203) {
          history.push(`/verify-otp/${myLogin.user_login}`,result);
        }else{
          toast.error(result.message);
        }
      });
    } else {
      validator.current.showMessages();
    }
  };

  return (
    <div className="loginpage">
      <img
        src="assets/images/login_bg.jpg"
        className="loginbg"
        alt="Not found"
      />
      <div className="flex-grow-1 text-center loginchar">
        {/* <img src="/user/assets/images/shopping_in_UAE.svg" height="500" /> */}
      </div>
      <div className="loginbox">
        <h2 className="mb-5">Sign in to World2Door</h2>
        <form className="w-100" onSubmit={(event) => HandleSubmit(event)}>
          {/* {showFeild === 'phone' ?
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" id="floatingInput" placeholder="Phone Number" onChange={(event) => Handlechange(event)} name="user_email_phone_number" />
                            <label for="floatingInput">Phone Number</label>
                            <span className="text-primary pointer pt-1" onClick={() => (setShowFeild('email'), setLogin({ ...myLogin, user_email_phone_number: '' }))}> <u>Login via email </u></span>
                            {validator.current.message('', myLogin.user_email_phone_number, 'required|numeric|size:10', { className: 'text-danger' })}
                        </div>
                        : */}
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingEmail"
              placeholder="Email"
              onChange={(event) => Handlechange(event)}
              name="user_login"
            />
            <label for="floatingEmail">Username (Email)</label>
            {/* <span className="text-primary pointer pt-1" onClick={() => (setShowFeild('phone'), setLogin({ ...myLogin, user_email_phone_number: '' }))}> <u>Login via phone number </u></span> */}
            {validator.current.message("email", myLogin.user_login, "required", {
              className: "text-danger",
            })}
          </div>

          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              onChange={(event) => Handlechange(event)}
              name="user_password"
            />
            <label for="floatingPassword">Password</label>
            {validator.current.message(
              "Password",
              myLogin.user_password,
              "required|min:8",
              { className: "text-danger" }
            )}
          </div>

          <p className="my-3">
            <Link to="/forget_password" className="text-primary">
              Forgot Password?
            </Link>
          </p>

          <div className="mt-3">
            <button className="themebutton w-100" type="submit">
              Login
            </button>
          </div>

          <div className="text-center mt-4">
            <p className="mb-1">Don’t have a World2Door Account?</p>

            <div className="row mt-3 Signupbtn">
              <div className="col-6 mx-auto">
                <Link to="/register" className="themebutton w-100">
                  Customer Sign up
                </Link>
              </div>

              {/* <div className="col-12">
                <hr />
                <p className="my-3">
                  Already have a Account ? then login as a{" "}
                </p>
              </div> */}
{/* 
              <div className="col-12 mt-3">
                <div className="row justify-content-center">
                  <div className="col-4">
                    <Link to="/retailer_login" className="themebutton">
                      Retailer
                    </Link>
                  </div>
                  <div className="col-4">
                    <Link to="/store_login" className="themebutton">
                      Store
                    </Link>
                  </div>
                  <div className="col-4">
                    <Link to="/management_login" className="themebutton">
                      Management
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
