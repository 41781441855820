import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import ConfirmationModel from "../ConfirmationModel";
import simpleReactValidator from "simple-react-validator";
import { quoteService } from "../../services";
import { toast } from "react-toastify";
import { Storage } from "../../Storage";
import { proceedAction } from "../../Redux/action";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import Loader from "../Loader";
import {Country,State,City} from "country-state-city";

export default function GetInstruction() {
  const dispatch = useDispatch();
  const [pickCountry, setPickCountry] = useState(Country.getAllCountries());
  const [pickCountryCode, setPickCountryCode] = useState("");
  const [pickCities, setPickCities] = useState([]);
  const [pickStates, setPickStates] = useState([]);
  const [delCountry, setDelCountry] = useState(Country.getAllCountries());
  const [delCountryCode, setDelCountryCode] = useState("");
  const [delCities, setDelCities] = useState([]);
  const [delStates, setDelStates] = useState([]);
  const Tokenstate = useSelector((state) => state.TokenDetails.TokenData);
  const QuotesState = useSelector((state) => state.QuotesDetails.QuotesData);
  const [loader, setLoader] = useState(false);
  const Volumestate = useSelector((state) => state.volumeDetail.volumeData);
  const [isQuote, setIsQuote] = useState(false);
  const [manageTab, setManageTab] = useState(true);
  const [tabactive, setTabactive] = useState();
  const [, setForceUpdate] = useState("");
  const [confirmationData, setConfirmationData] = useState();
  const [isShepmentshow, setIsShepmentshow] = useState("single");

  const validator = useRef(
    new simpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );

  // console.log(QuotesState,'QuotesState')

  const pickupArry = {
    pickup_address_type: "",
    pickup_address: "",
    pickup_country: "",
    pickup_state: "",
    pickup_city: "",
    pickup_postal_code: "",
    full_name: "",
    phone_number: "",
    email: "",
    what_to_pickup: "",
    invoice_id: "",
    attached_file_invoice: [],
    pickup_date: "",
    pickup_time: "",
    salutation: "",
    file_name: ""
  };

  const [pickupLocation, setPickupLocation] = useState([pickupArry]);
  const [deliverylocations, setDeliverylocations] = useState({
    full_name1: "",
    phone_number1: "",
    email1: "",
    delivery_address: "",
    delivery_country: "",
    delivery_state: "",
    delivery_city: "",
    delivery_postal_code: "",
    salutation1: "",
  });
  const addFunction = () => {
    let newArry = [...pickupLocation];
    newArry.push(pickupArry);
    setPickupLocation(newArry);
  };
console.log(deliverylocations,'deliverylocations')
  const payHandler = (type) => {
    let tabchange;

    pickupLocation.forEach((item) => {
      Object.values(item).forEach((value) => {
        if (!value) {
          return (tabchange = false);
        } else {
          return (tabchange = true);
        }
      });
    });

    // setTabactive(tabchange);//code comment live issue

    let validate = 0;
    let validate1 = 0;
    if (pickupLocation && (QuotesState?.stateItem?.deliverer && QuotesState.stateItem.deliverer==="WORLD2DOOR")) {
      validate =
        +validator.current.fieldValid("Pickup Type") &&
        validator.current.fieldValid("Pickup Address") &&
        validator.current.fieldValid("Pickup Full Name") &&
        validator.current.fieldValid("Pickup Phone Number") &&
        validator.current.fieldValid("Pickup Date") &&
        // validator.current.fieldValid("Pickup shipment") &&
        // validator.current.fieldValid("Invoice");
        validator.current.fieldValid("Salutation");
    }
    if (deliverylocations) {
      validate1 =
        +validator.current.fieldValid("Delivery Address") &&
        validator.current.fieldValid("Delivery Full Name") &&
        validator.current.fieldValid("Delivery Phone Number") &&
        validator.current.fieldValid("Delivery Email");
      validator.current.fieldValid("Delivery Salutation");
    }

    if ((QuotesState?.stateItem?.deliverer && QuotesState.stateItem.deliverer==="WORLD2DOOR" && validate && validate1) || validate1) {

      let pickup_array = (QuotesState?.stateItem?.deliverer && QuotesState.stateItem.deliverer==="WORLD2DOOR" && pickupLocation.map((item) => {
        return {
          pickup_address_type: item.pickup_address_type,
          pickup_address: item.pickup_address,
          pickup_country : item.pickup_country,
          pickup_state : item.pickup_state,
          pickup_city : item.pickup_city,
          pickup_postal_code : item.pickup_postal_code,
          full_name: item.full_name,
          phone_number: item.phone_number,
          email: item.email || "",
          what_to_pickup: item.what_to_pickup || "",
          invoice_id: item.invoice_id || "",
          attached_file_invoice: item.attached_file_invoice,
          pickup_date: item.pickup_date, // getPostedDate(item.pickup_date),
          pickup_time: item.pickup_time || "",
          salutation: item.salutation,
        }
      })) || []

      var postData = {
        pickup_locations: pickup_array,
        delivery_locations: {
          full_name: deliverylocations.full_name1,
          phone_number: deliverylocations.phone_number1,
          email: deliverylocations.email1,
          delivery_address: deliverylocations.delivery_address,
          delivery_country : deliverylocations.delivery_country,
          delivery_state : deliverylocations.delivery_state,
          delivery_city : deliverylocations.delivery_city,
          delivery_postal_code : deliverylocations.delivery_postal_code,
          salutation: deliverylocations.salutation1,
        },
        quote_token: Tokenstate,
        selected_courier_type: QuotesState && QuotesState.type,
        add_ons: {
          packing: QuotesState && QuotesState.packingarray,
          insurance: QuotesState && QuotesState.insurance,
          storage: QuotesState && QuotesState.storage,
          comments: "",
        },
        insurance: QuotesState?.insurance?.insurance_amount || 0,
        storage: QuotesState?.storage?.storage_amount || 0,
        packingCharge: QuotesState?.packing || 0,
        freight: Number(QuotesState?.selected_freight) || 0,
        bookingAmount: Math.round(QuotesState?.completeAmount) || 0,
        discount_amount: QuotesState?.discount_value || 0,
        deliverer : QuotesState.stateItem.deliverer
      };

      setConfirmationData(postData);

      if (type === "paynow") {
        setIsQuote(true);
      } else {
        setLoader(true);
        if (Storage.get("user-token") === "retailer") {
          quoteService
            .proceedAddToJobRetailer(postData)
            .then((res) => {
              if (res.status === true) {
                toast.success("Proceed Successfully");
                dispatch(proceedAction(res.job_data));
                setLoader(false);
                history.push("/booking-confirm");
              } else {
                toast.error(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          quoteService
            .proceedAddToJob(postData)
            .then((res) => {
              if (res.status === true) {
                toast.success("Proceed Successfully");
                dispatch(proceedAction(res.job_data));
                setLoader(false);
                history.push("/booking-confirm");
              } else {
                toast.error(res.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    } else {
      validator.current.showMessages();
      toast.info("validation is required, please check the field");
    }
  };
  const onCloseClick = () => {
    setIsQuote(!isQuote);
  };

  const onAddressChange = (e) => {
    let { name, value } = e.target;
    setDeliverylocations({ ...deliverylocations, [name]: value });
  };

  const phoneHandler = (event, g, key) => {

    // if (g.target.value) {
    //   var num = g.target.value
    //     .split(" ")
    //     .slice(1)
    //     .join("")
    //     .replace(/[^a-zA-Z0-9]/g, "");
    // }
    let phonearray = [...pickupLocation];
    phonearray.map((phone, pkey) => {

      if (key === pkey) {
        //  phone.phone_number = num;// code without country code
        phone.phone_number = g.target.value;
      }
    });
    setPickupLocation(phonearray);
  };

  const phoneHandlertwo = (event, g) => {
    // if (g.target.value) {
    //   var num = g.target.value
    //     .split(" ")
    //     .slice(1)
    //     .join("")
    //     .replace(/[^a-zA-Z0-9]/g, "");
    // }
    //setDeliverylocations({ ...deliverylocations, phone_number1: num });// code without country code
    setDeliverylocations({ ...deliverylocations, phone_number1: g.target.value });
  };

  const onChangeEvent = (event, key) => {
    const { name, value } = event.target;
    let newArray = [...pickupLocation];

    newArray.map((elm, i) => {
      for (const id in elm) {
        if (Object.hasOwnProperty.call(elm, id)) {
          if (key === i) {
            if (name === id) {
              elm[id] = value;
            }
          }
        }
      }
    });
    setPickupLocation(newArray);
  };

  const uploadFile = (e, key) => {
    const formData = new FormData();
    let selectedFile = e.target.files[0];
    /* old code by BK

    // let fileList = []
    // if (selectedFile) {
    //   for (const objkey of selectedFile) {
    //     fileList.push(objkey?.name)
    //     formData.append('document', objkey)
    //   }
    // }
    */

    /* rizwan code dec 2022 */
    formData.append('document', selectedFile)

    quoteService
      .uploadFile(formData)
      .then((res) => {
        let document_Img_array = [...pickupLocation];
        document_Img_array.map((val, i) => {
          const fileInvoice = [...val.attached_file_invoice];
          const fileNameArray = [...val.file_name];
          if (i === key) {
            fileInvoice.push(res.data);
            fileNameArray.push(selectedFile?.name)
            val.attached_file_invoice = fileInvoice;
            val.file_name = fileNameArray // e.target.files.name;
          }
        });
        setPickupLocation(document_Img_array);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const convertDate = (e, key) => {
    const { value } = e.target;
    let pickArray = [...pickupLocation];
    pickArray.map((val, i) => {
      if (i === key) {
        val.pickup_date = value;
      }
    });
    setPickupLocation(pickArray);
  };

  function getPostedDate(datefilled) {
    var today = datefilled;
    let manageble =
      today.getFullYear() +
      "-" +
      ("00" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + today.getDate()).slice(-2);

    return manageble
  }

  const history = useHistory();

  const shipmentHandler = (e) => {
    setIsShepmentshow(e.target.value);
  };

  const removeFunction = (key) => {
    let deletearray = [...pickupLocation];
    deletearray.splice(key, 1);
    setPickupLocation(deletearray);
  }

  const pickupHandler = (e, i) => {
    const { value } = e.target;
    let pickarray = [...pickupLocation];
    pickarray[i].pickup_address_type = value
    setPickupLocation(pickarray);
  }

  const ShowPayButton = () => {
    return <div className="row">
      <div className="col-4 mt-3 border-end">
        <small>Wish to add more cargo to your shipment ?</small>
        <button
          className="themebutton"
          type="button"
          onClick={() => payHandler("paylater")}
        >
          Pay Later
        </button>
      </div>
      <div className="col-4 mt-3 border-end">
        <small>I am ready to ship immediately</small>
        <button
          className="themebutton"
          type="button"
          onClick={() => payHandler("paynow")}
        >
          Pay Now
        </button>
      </div>
      <div className="col-4 mt-3">
        <small>Total Freight</small>
        <h5 className="mb-1">
          AED{" "}
          {QuotesState.completeAmount
            ? Math.round(QuotesState.completeAmount)
            : QuotesState.subtotal_amount ?
                QuotesState.subtotal_amount
            :QuotesState.selected_freight
              ? Math.round(QuotesState.selected_freight)
              : 0}
        </h5>
      </div>
    </div>
  }

  const onCountryChange = (e,key) => {
    const {value,name} = e.target
    if (name === "pickup_country") {
      let findIso = pickCountry.find((item) => item.name===value)
      setPickStates(State.getStatesOfCountry(findIso.isoCode));
      setPickCountryCode(findIso.isoCode);
    } else if (name === "pickup_state") {
      let findIso = pickStates.find((item) => item.name===value)
      setPickCities(City.getCitiesOfState(pickCountryCode, findIso.isoCode));
    }
    onChangeEvent(e, key);
  }

  const onDelCountryChange = (e,) => {
    const {value,name} = e.target
    if (name === "delivery_country") {
      let findIso = delCountry.find((item) => item.name===value)
      setDelStates(State.getStatesOfCountry(findIso.isoCode));
      setDelCountryCode(findIso.isoCode);
    } else if (name === "delivery_state") {
      let findIso = delStates.find((item) => item.name===value)
      setDelCities(City.getCitiesOfState(delCountryCode, findIso.isoCode));
    }
    onAddressChange(e);
  }

  return (
    <section className="pb-5 pt-0">
      <div className="header_title header_title_sp">
        <div className="container-fluid text-center">
          <h1>
            {Volumestate && Volumestate.request_data.origin_city}
            <span className="px-md-4 px-2">
              <img src="/assets/images/arrow_right.svg" alt="Not found" />
            </span>
            {Volumestate && Volumestate.request_data.destination_city}
          </h1>
          <p>
            Pkgs {Volumestate && Volumestate.request_data.number_of_packages} |
            Weight {Volumestate && Volumestate.request_data.weight}kg | Volume{" "}
            {Volumestate && Volumestate.request_data.volume}
          </p>

          <img
            src="/assets/images/country.jpg"
            className="bannerimg"
            alt="Not found"
          />
        </div>
      </div>
      <div className="container-fluid mt-5">


        <div className="row" style={{flexDirection: QuotesState?.stateItem?.deliverer && QuotesState.stateItem.deliverer==="WORLD2DOOR" ? "row" : "row-reverse" }}>
          {QuotesState?.stateItem?.deliverer && QuotesState.stateItem.deliverer==="WORLD2DOOR" ?
            <div className="col-md-8 mb-3">
              <div className="white_box">
                <div className="row mt-3">
                  {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="home-tab"
                        onClick={() => setManageTab(true)}
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      > */}
                  <h6 className="mb-1">Pickup Instructions</h6>
                  <hr />
                  {/* </button>
                    </li> */}
                  {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link "
                        onClick={() => setManageTab(false)}
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        <h6 className="mb-1">Delivery Instructions</h6>
                      </button>
                    </li> */}
                  {/* </ul> */}
                  <div className="tab-content" id="myTabContent">
                    <div
                      // className="tab-pane fade show active"
                      className={
                        tabactive === true
                          ? "tab-pane fade"
                          : "tab-pane fade show active"
                      }
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="form-group">
                        <label className="d-block">
                          <span className="d-block fw-bold">Pickup Type</span>
                        </label>
                        <label className="my-2 radio-inline" htmlFor="single">
                          <input
                            type="radio"
                            name="shipmenttype"
                            value="single"
                            id="single"
                            checked={isShepmentshow === "single" ? true : false}
                            onClick={(e) => shipmentHandler(e)}
                          />{" "}
                          Single
                        </label>
                        <label className="my-2 radio-inline ms-3" htmlFor="multiple">
                          <input
                            type="radio"
                            name="shipmenttype"
                            value="multiple"
                            id="multiple"
                            checked={isShepmentshow === "multiple" ? true : false}
                            onClick={(e) => shipmentHandler(e)}
                          />{" "}
                          Multiple
                        </label>
                      </div>

                      {pickupLocation.map((item, key) => {
                        return (
                          <>
                            <div className="row mt-3">
                              <div className="col-md-12 border-end">
                                <div className="d-flex justify-content-between">
                                  <div
                                    className="form-group"
                                    onChange={(e) => {
                                      pickupHandler(e, key);
                                    }}
                                  >
                                    <label className="d-block">
                                      <span className="d-block fw-bold">
                                        Where to pick your shipment from<span className="text-danger align-middle fs-5">*</span>
                                      </span>
                                    </label>
                                    <label className="my-2 radio-inline">
                                      <input
                                        type="radio"
                                        name={`pickup_address_type_${key}`}
                                        value="store"
                                      // checked={item.pickup_address_type === "store" ? true : false}
                                      />{" "}
                                      Store
                                    </label>
                                    <label className="my-2 radio-inline ms-3">
                                      <input
                                        type="radio"
                                        name={`pickup_address_type_${key}`}
                                        value="residence"
                                      // checked={item.pickup_address_type === "residence" ? true : false}
                                      />{" "}
                                      Residence
                                    </label>
                                    <label className="my-2 radio-inline ms-3">
                                      <input
                                        type="radio"
                                        name={`pickup_address_type_${key}`}
                                        value="office"
                                      // checked={item.pickup_address_type === "office" ? true : false}
                                      />{" "}
                                      Office
                                    </label>
                                    {validator.current.message(
                                      "Pickup Type",
                                      item.pickup_address_type,
                                      "required",
                                      { className: "text-danger" }
                                    )}
                                  </div>
                                  {
                                    pickupLocation.length > 1 &&
                                    <button
                                      className="themebutton bg-danger px-4"
                                      type="button"
                                      style={{ background: "#dc3545" }}
                                      onClick={(e) => removeFunction(key)}
                                    >
                                      Remove
                                    </button>
                                  }
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <span className="d-block fw-bold">
                                      Address<span className="text-danger align-middle fs-5">*</span>
                                    </span>
                                  </label>
                                  <textarea
                                    name="pickup_address"
                                    className="form-control"
                                    cols="30"
                                    rows="4"
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                  ></textarea>
                                  {validator.current.message(
                                    "Pickup Address",
                                    item.pickup_address,
                                    "required",
                                    { className: "text-danger " }
                                  )}
                                </div>
                              </div>

                            <div className="col-md-6">
                              <div className="row">
                              <div className="form-group mb-3 col-6">
                                  <label>Country </label>
                                  <select className="form-control" name="pickup_country" 
                                    value={item?.pickup_country}
                                    onChange={(e) => onCountryChange(e,key)}
                                  >
                                    <option value="">Select Country</option>
                                    {pickCountry.map((item1, key1) => (
                                      <option key={key1} value={item1.name}>
                                        {item1.name}
                                      </option>
                                    ))}
                                  </select>
                                  {validator.current.message(
                                  "Country",
                                  item?.pickup_country,
                                  "required",
                                  { className: "text-danger" }
                                )}
                                </div>
                                <div className="form-group mb-3 col-6">
                                  <label>State</label>
                                  <select className="form-control" name="pickup_state" 
                                  value={item?.pickup_state}
                                  onChange={(e) => onCountryChange(e,key)} 
                                  >
                                    <option value="">Select State</option>
                                    {pickStates.map((item1, key1) => (
                                      <option key={key1} value={item1.name}>
                                        {item1.name}
                                      </option>
                                    ))}
                                  </select>
                                  {validator.current.message(
                                  "State",
                                  item?.pickup_state,
                                  "required",
                                  { className: "text-danger" }
                                )}
                                </div>
                                </div>
                                <div className="row">
                                <div className="form-group mb-3 col-6">
                                    <label>City</label>
                                    <select className="form-control" name="pickup_city"
                                      onChange={(e) => onCountryChange(e,key)}
                                      value={item?.pickup_city}
                                    >
                                      <option value="">Select City</option>
                                      {pickCities.map((item1, key1) => (
                                        <option key={key1} value={item1.name}>
                                          {item1.name}
                                        </option>
                                      ))}
                                    </select>
                                    {validator.current.message(
                                    "City",
                                    item?.pickup_city,
                                    "required",
                                    { className: "text-danger" }
                                  )}
                                </div>
                                <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>Postal Code</label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="pickup_postal_code"
                                    placeholder="Postal Code"
                                    value={item?.pickup_postal_code}
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                  />
                                  {validator.current.message("Postal Code", item?.pickup_postal_code, "required", {
                                    className: "text-danger",
                                  })}
                                </div>
                              </div>
                            </div>
                              </div>

                              <div className="col-md-12">
                                <label>
                                  <span className="d-block  fw-bold">
                                    Contact person at your requested pickup
                                    location
                                  </span>
                                </label>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Salutation<span className="text-danger align-middle fs-5">*</span></small>
                                  </label>
                                  <select
                                    className="form-control"
                                    name="salutation"
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                  >
                                    <option selected disabled>
                                      --select--
                                    </option>
                                    <option value="MR">MR.</option>
                                    <option value="MRS">MRS.</option>
                                    <option value="MISS">MISS.</option>
                                  </select>
                                  {validator.current.message(
                                    "Salutation",
                                    item.salutation,
                                    "required",
                                    { className: "text-danger " }
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Full Name<span className="text-danger align-middle fs-5">*</span></small>
                                  </label>
                                  <input
                                    className="form-control"
                                    name="full_name"
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                    type="text"
                                  />
                                  {validator.current.message(
                                    "Pickup Full Name",
                                    item.full_name,
                                    "required",
                                    { className: "text-danger " }
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Phone Number<span className="text-danger align-middle fs-5">*</span></small>
                                  </label>
                                  <PhoneInput
                                    className="telinput"
                                    country={"ae"}
                                    enableSearch
                                    enableAreaCodes={true}
                                    onChange={(phone, f, g) =>
                                      phoneHandler(phone, g, key)
                                    }
                                  />
                                  {validator.current.message(
                                    "Pickup Phone Number",
                                    item.phone_number,
                                    "required",
                                    { className: "text-danger " }
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Email</small>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                  />
                                  {/* {validator.current.message(
                                    "Pickup Email",
                                    item.email,
                                    "required",
                                    { className: "text-danger " }
                                  )} */}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <label>
                                  <span className="d-block  fw-bold">
                                    When to pickup ?
                                  </span>
                                </label>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Select pickup date<span className="text-danger fs-5">*</span></small>
                                  </label>
                                  {/* <DatePicker
                                      className="form-control"
                                      name="pickup_date"
                                      selected={item.pickup_date}
                                      onChange={(e) => {
                                        convertDate(e, key);
                                      }}
                                      minDate={new Date()}
                                      autocomplete="off"
                                      isClearable
                                      placeholderText="Please select pickup date!"
                                    /> */}
                                  <input className="form-control" type="date" id="datemin" name="datemin" min={getPostedDate(new Date())} onChange={(e) => {
                                    convertDate(e, key);
                                  }} />
                                  {validator.current.message(
                                    "Pickup Date",
                                    item.pickup_date,
                                    "required",
                                    { className: "text-danger" }
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Pickup Time</small>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="time"
                                    name="pickup_time"
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                  />
                                  {/* {validator.current.message(
                                    "pickup_time",
                                    item.pickup_time,
                                    "required",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <label>
                                  <span className="d-block  fw-bold">
                                    What to pickup
                                  </span>
                                </label>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Shipment Description</small>
                                  </label>
                                  <input
                                    className="form-control"
                                    name="what_to_pickup"
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                  />
                                  {/* {validator.current.message(
                                    "Pickup shipment",
                                    item.what_to_pickup,
                                    "required",
                                    { className: "text-danger " }
                                  )} */}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mb-3">
                                  <label>
                                    <small>Receipt/Invoice Ref#</small>
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="invoice_id"
                                    onChange={(e) => {
                                      onChangeEvent(e, key);
                                    }}
                                  />
                                  {/* {validator.current.message(
                                    "Invoice",
                                    item.invoice_id,
                                    "required",
                                    { className: "text-danger" }
                                  )} */}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex justify-content-between align-items-center">
                                  <label>
                                    <span className="d-block  fw-bold">
                                      Please attach one of the following documents
                                    </span>
                                  </label>
                                  <div className="themebutton px-4 uploadProducts">
                                    Select File
                                    <input
                                      type="file"
                                      // multiple
                                      onChange={(e) => {
                                        uploadFile(e, key);
                                      }}
                                    />
                                  </div>

                                </div>
                                {item.file_name && item.file_name.length > 0 &&
                                  item.file_name.map((image, imgkey) => {
                                    return (
                                      <div key={imgkey}>
                                        <span
                                          className="text-muted"
                                          style={{ wordBreak: "break-all" }}
                                        >
                                          File {imgkey + 1} : <b>{image}</b>
                                        </span>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                              <div className="col-md-6">
                                <label>
                                  <span className="d-block  fw-bold">
                                    What to attach ?
                                  </span>
                                </label>

                                <p className="mb-0">
                                  <small>Picture of your package</small>
                                </p>
                                <p className="mb-0">
                                  <small>
                                    Copy of Receipt/Invoice of Purchase
                                  </small>
                                </p>
                                <p className="mb-0">
                                  <small>
                                    Any other document to authorize pickup
                                  </small>
                                </p>
                              </div>
                            </div>

                            {/* <ShowPayButton /> */}

                          </>
                        );
                      })}
                    </div>

                    {manageTab && isShepmentshow === "multiple" ? (
                      <div className="col-md-12">
                        <button
                          className="themebutton px-4 ms-auto"
                          type="button"
                          onClick={(e) => addFunction(e)}
                        >
                          Add
                        </button>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <div
                      // className="tab-pane fade"
                      className={
                        tabactive === true
                          ? "tab-pane fade show active"
                          : "tab-pane fade"
                      }
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="form-group mt-3">
                        <label className="d-block">
                          <span className="d-block fw-bold">
                            Where do you want to deliver
                          </span>
                        </label>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label>
                              <small>Salutation</small>
                            </label>
                            <select
                              className="form-control"
                              name="salutation1"
                              onChange={(e) => {
                                onAddressChange(e);
                              }}
                            >
                              <option selected disabled>
                                --select--
                              </option>
                              <option value="MR">MR.</option>
                              <option value="MRS">MRS.</option>
                              <option value="MISS">MISS.</option>
                            </select>
                            {validator.current.message(
                              "Delivery Salutation",
                              deliverylocations.salutation1,
                              "required",
                              { className: "text-danger " }
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label>
                              <small>Full Name</small>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="full_name1"
                              onChange={(e) => {
                                onAddressChange(e);
                              }}
                            />
                            {validator.current.message(
                              "Delivery Full Name",
                              deliverylocations.full_name1,
                              "required",
                              { className: "text-danger " }
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label>
                              <small>Phone Number</small>
                            </label>
                            <PhoneInput
                              className="telinput"
                              country={"ae"}
                              enableSearch
                              enableAreaCodes={true}
                              onChange={(phone, f, g) =>
                                phoneHandlertwo(phone, g)
                              }
                            />
                            {validator.current.message(
                              "Delivery Phone Number",
                              deliverylocations.phone_number1,
                              "required",
                              { className: "text-danger " }
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label>
                              <small>Email</small>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email1"
                              onChange={(e) => {
                                onAddressChange(e);
                              }}
                            />
                            {validator.current.message(
                              "Delivery Email",
                              deliverylocations.email1,
                              "required",
                              { className: "text-danger " }
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label>
                              <small>Address</small>
                            </label>
                            <textarea
                              onChange={(e) => {
                                onAddressChange(e);
                              }}
                              name="delivery_address"
                              className="form-control"
                              cols="30"
                            ></textarea>
                            {validator.current.message(
                              "Delivery Address",
                              deliverylocations.delivery_address,
                              "required",
                              { className: "text-danger " }
                            )}
                          </div>
                        </div>

                        <ShowPayButton />

                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
           : ""}
          <div className="col-md-4 mb-3">
            <div className="white_box h-100">
              <h5 className="mb-1">Order Summary</h5>
              <small>Quote ID - {Tokenstate}</small>
              <hr />
              <h5 className="mb-1">
                {Volumestate && Volumestate.request_data.origin_city}

                <span className="px-md-4 px-2">
                  <img
                    src="/assets/images/arrrow_right_dark.svg"
                    alt="Not found"
                  />
                </span>
                {Volumestate && Volumestate.request_data.destination_city}
              </h5>

              <h6 className="fw-bold mb-0 mt-4">Services</h6>

              <div className="d-flex align-items-center">
                <div className="me-3">
                  <img src="/assets/images/Flat.svg" alt="Not found" />
                </div>
                <p className="fw-bold my-4">
                  {QuotesState && QuotesState.type}
                </p>
              </div>

              <table className="w-100 mt-3">
                <tbody>
                  <tr>
                    <td width="150px">DOOR DELIVERY</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {QuotesState.selected_freight
                        ? QuotesState.selected_freight
                        : "0"}{" "}
                      <b>(Transit {QuotesState?.transist_type} days)</b>{" "}
                    </td>
                  </tr>
                </tbody>
              </table>

              <p className="fw-bold mt-3 mb-0">ADD-ONS</p>
              <table className="w-100">
                <tbody>
                  <tr>
                    <td width="150px">Packing</td>
                    <td>-</td>
                    <td>AED {QuotesState.packing}</td>
                  </tr>
                  <tr>
                    <td width="150px">Insurance premium</td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {QuotesState.insurance.insurance_amount
                        ? QuotesState.insurance.insurance_amount
                        : "0"}
                    </td>
                  </tr>
                  <tr>
                    <td width="150px">
                      Storage (
                      {QuotesState.storage.storage_amount
                        ? QuotesState.storage.storage_amount
                        : "0"}{" "}
                      )
                    </td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {QuotesState.storage.storage_amount
                        ? QuotesState.storage.storage_amount
                        : "0"}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="w-100 mt-3">
                <tbody>
                  <tr>
                    <td width="150px" className="py-1">
                      Sub Total
                    </td>
                    <td>-</td>
                    {/* <td>AED  {!QuotesState.selected_freight ? QuotesState.subtotal_amount ? QuotesState.subtotal_amount : "0" : QuotesState.selected_freight}</td> */}
                    <td>
                      AED{" "}
                      {QuotesState.subtotal_amount
                        ? Math.round(QuotesState.subtotal_amount)
                        : QuotesState.selected_freight
                          ? Math.round(QuotesState.selected_freight)
                          : 0}
                    </td>
                  </tr>
                  <tr>
                    <td width="150px" className="py-1">
                      Discount
                    </td>
                    <td>-</td>
                    <td>
                      AED {QuotesState?.discount_value}
                    </td>
                  </tr>

                  <tr>
                    <td width="150px" className="py-1">
                      Vat
                    </td>
                    <td>-</td>
                    <td>AED 0.00</td>
                  </tr>
                  {/* <tr>
                    <td width="150px" className="py-1">
                      Net
                    </td>
                    <td>-</td>
                    <td>AED {QuotesState.selected_freight
                      ? QuotesState.selected_freight
                      : "0"}</td>
                  </tr> */}
                </tbody>
              </table>

              <p className="fw-bold mt-3 mb-0">TAXES</p>

              <table className="w-100">
                <tbody>
                  <tr>
                    <td width="150px" className="py-1">
                      Vat
                    </td>
                    <td>-</td>
                    <td>AED 0.00</td>
                  </tr>
                  {/* <tr>
                    <td width="150px" className="py-1">
                      TRN
                    </td>
                    <td>-</td>
                    <td>A100480781200003</td>
                  </tr> */}
                </tbody>
              </table>
              <hr />
              <table className="w-100">
                <tbody>
                  <tr className="py-3 fw-bold">
                    <td width="150px" className="py-1">
                      Net Total
                    </td>
                    <td>-</td>
                    <td>
                      AED{" "}
                      {QuotesState.completeAmount
                        ? Math.round(QuotesState.completeAmount)
                        : QuotesState.subtotal_amount ?
                            QuotesState.subtotal_amount
                        :QuotesState.selected_freight
                          ? Math.round(QuotesState.selected_freight)
                          : 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        {/* </div>
        <div className="row mt-3"> */}
          <div className="col-md-8">
            <div className="white_box">
              <div className="row mt-3">

                {/* <button
                      className="nav-link "
                      onClick={() => setManageTab(true)}
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="true"
                    > */}
                <h6 className="mb-1">Delivery Instructions</h6>
                <hr />

              </div>
              <div
              // className="tab-pane fade"
              // className={
              //   tabactive === true
              //     ? "tab-pane fade show active"
              //     : "tab-pane fade"
              // }
              // id="profile"
              // role="tabpanel"
              // aria-labelledby="profile-tab"
              >
                <div className="form-group mt-3">
                  <label className="d-block">
                    <span className="d-block fw-bold">
                      Where do you want to deliver
                    </span>
                  </label>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        <small>Salutation<span className="text-danger align-middle fs-5">*</span></small>
                      </label>
                      <select
                        className="form-control"
                        name="salutation1"
                        onChange={(e) => {
                          onAddressChange(e);
                        }}
                      >
                        <option selected disabled>
                          --select--
                        </option>
                        <option value="MR">MR.</option>
                        <option value="MRS">MRS.</option>
                        <option value="MISS">MISS.</option>
                      </select>
                      {validator.current.message(
                        "Delivery Salutation",
                        deliverylocations.salutation1,
                        "required",
                        { className: "text-danger " }
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        <small>Full Name<span className="text-danger align-middle fs-5">*</span></small>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="full_name1"
                        onChange={(e) => {
                          onAddressChange(e);
                        }}
                      />
                      {validator.current.message(
                        "Delivery Full Name",
                        deliverylocations.full_name1,
                        "required",
                        { className: "text-danger " }
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        <small>Phone Number<span className="text-danger align-middle fs-5">*</span></small>
                      </label>
                      <PhoneInput
                        className="telinput"
                        country={"ae"}
                        enableSearch
                        enableAreaCodes={true}
                        onChange={(phone, f, g) =>
                          phoneHandlertwo(phone, g)
                        }
                      />
                      {validator.current.message(
                        "Delivery Phone Number",
                        deliverylocations.phone_number1,
                        "required",
                        { className: "text-danger " }
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        <small>Email<span className="text-danger align-middle fs-5">*</span></small>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        name="email1"
                        onChange={(e) => {
                          onAddressChange(e);
                        }}
                      />
                      {validator.current.message(
                        "Delivery Email",
                        deliverylocations.email1,
                        "required",
                        { className: "text-danger " }
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>
                        <small>Address<span className="text-danger align-middle fs-5">*</span></small>
                      </label>
                      <textarea
                        onChange={(e) => {
                          onAddressChange(e);
                        }}
                        name="delivery_address"
                        className="form-control"
                        cols="30"
                      ></textarea>
                      {validator.current.message(
                        "Delivery Address",
                        deliverylocations.delivery_address,
                        "required",
                        { className: "text-danger " }
                      )}
                    </div>
                  </div>

                            <div className="col-md-6">
                            <div className="row">
                            <div className="form-group mb-3 col-6">
                                <label>Country </label>
                                <select className="form-control" name="delivery_country" 
                                  value={deliverylocations?.delivery_country}
                                  onChange={(e) => onDelCountryChange(e)}
                                >
                                  <option value="">Select Country</option>
                                  {delCountry.map((item1, key1) => (
                                    <option key={key1} value={item1.name}>
                                      {item1.name}
                                    </option>
                                  ))}
                                </select>
                                {validator.current.message(
                                "Country",
                                deliverylocations?.delivery_country,
                                "required",
                                { className: "text-danger" }
                              )}
                              </div>
                              <div className="form-group mb-3 col-6">
                                <label>State</label>
                                <select className="form-control" name="delivery_state" 
                                value={deliverylocations?.delivery_state}
                                onChange={(e) => onDelCountryChange(e)} 
                                >
                                  <option value="">Select State</option>
                                  {delStates.map((item1, key1) => (
                                    <option key={key1} value={item1.name}>
                                      {item1.name}
                                    </option>
                                  ))}
                                </select>
                                {validator.current.message(
                                "State",
                                deliverylocations?.delivery_state,
                                "required",
                                { className: "text-danger" }
                              )}
                              </div>
                              </div>
                              <div className="row">
                              <div className="form-group mb-3 col-6">
                                  <label>City</label>
                                  <select className="form-control" name="delivery_city"
                                    onChange={(e) => onDelCountryChange(e)}
                                    value={deliverylocations?.delivery_city}
                                  >
                                    <option value="">Select City</option>
                                    {delCities.map((item1, key1) => (
                                      <option key={key1} value={item1.name}>
                                        {item1.name}
                                      </option>
                                    ))}
                                  </select>
                                  {validator.current.message(
                                  "City",
                                  deliverylocations?.delivery_city,
                                  "required",
                                  { className: "text-danger" }
                                )}
                              </div>
                              <div className="col-md-6">
                              <div className="form-group mb-3">
                                <label>Postal Code</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="delivery_postal_code"
                                  placeholder="Postal Code"
                                  value={deliverylocations?.delivery_postal_code}
                                  onChange={(e) => {
                                    onAddressChange(e);
                                  }}
                                />
                                {validator.current.message("Postal Code", deliverylocations?.delivery_postal_code, "required", {
                                  className: "text-danger",
                                })}
                              </div>
                            </div>
                          </div>
                            </div>







                  <ShowPayButton />

                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <div className="row align-items-center">

          
        </div> */}
      </div>
      <ConfirmationModel
        show={isQuote}
        data={confirmationData}
        //  onSubmit={(e) => onSubmitClick(e)}
        onClose={() => onCloseClick()}
      />
      <Loader isShow={loader} />
    </section>
  );
}
