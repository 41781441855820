import { DataService } from './dataService'

export const storageService = {
    getStores: async (data) => {
       
        return DataService.get('retailer/get_store', data)
    },

    getStoreDetails: async (data) => {
        return DataService.get('retailer/get_store_details', data)
    },

    getBookingapi: async (data) => {
        return DataService.get('/user/get_my_booking', data)
    },

    getQuotationapi: async (data) => {
        return DataService.get('/user/get_quotation_list', data)
    },

    getDocumentApi: async () => {
        return DataService.get('/user/get_my_document' )
    },

    getOrderHistoryApi: async () => {
        return DataService.get('/user/get_order_history' )
    },

    getInvoicesApi: async () => {
        return DataService.get('/payment/user/get_invoices' )
    },

    getInvoice: async (data) => {
        return DataService.get('/retailer/get/invoice/' , data )
    },

    getDetails: async (data) => {
        return DataService.get('/retailer/get_job_details', data)
    },
    
}


export default storageService;