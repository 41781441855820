import { DataService } from './dataService'

export const blogService = {
    
    getBlog: async (data) => {
        return DataService.get('/admin/blog_list', data)
    },
    getsingleBlog: async (id) => {
        return DataService.get(`/admin/blog_list/` + id)
    },

    // getEnquiryapi: async (data) => {
    //     return DataService.get('/retailer/get_retailer_inquiry_list', data)
    // },
}


export default blogService;