import React, { useEffect, useState } from 'react'
// import { Modal } from "react-bootstrap-v5";
// import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import commonApicall from "./commonApicall";
import { toast } from "react-toastify";
import { store } from "../Redux/store";
import { useSelector } from 'react-redux';
import { Storage } from '../Storage';

export default function AllBookingStore() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [loader, setLoader] = useState(false);

    // const state = useSelector((state)=> state.userDetails.user);
    // console.log("state ....", state)

    // const [show, setShow] = useState(false);
    // const [Editshow, setEditshow] = useState(false);
    // const [DeleteShow, setDeleteShow] = useState(false)

    // const OpenDeleteHandle = () => setDeleteShow(true)
    // const CloseDeletehandle = () => setDeleteShow(false)

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    // const editHandle = () => setEditshow(true)
    // const CloseeditHandle = () => setEditshow(false)
    
    // code by sd
//    const storeIds=store.getState().userDetails.user.data._id
//     console.log("storeIds======>",storeIds);  

const userId = Storage.get("user-auth");

console.log("user ....", userId)

    useEffect(() => {
       // getStoreBookings()
      }, []);

      const getStoreBookings = () => {
        let param = {};
        // if (startDate) {
        //   param["start_date"] = startDate;
        // } else if (endDate) {
        //   param["end_date"] = endDate;
        // } else if (searchValue) {
        //   param["search"] = searchValue;
        // }
    
        if(userId?._id) {
          param.store_ids = userId?._id
        }
        
        setLoader(true);
        commonApicall.getStoreListData(param, function (status, message, data) {
          setLoader(false);
          if (status === true) {
            getStoreBookings(data);
          } else {
            toast.error(message);
          }
        });
      }
    
    //end 

    return (
        <> 
            <section className="pb-5 pt-0">
                <div className="header_title">
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between">
                            <h1>All Bookings</h1>
                            {/* <button type="button" className="themebutton" >Add Stores</button> */}
                        </div>
                        <img alt='Not found' src="assets/images/login_bg.jpg" className="bannerimg" />
                    </div>
                </div>
                <div className="container-fluid mt-5">
                    <div className="white_box h-100 pb-1">
                        <div className="filterSec">
                            <div>
                                <label>From</label>
                                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                <label>To</label>
                                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                            </div>
                            {/* <div>
                                <input type="text" placeholder="Search by Id, Store Name" className="form-control" />
                            </div> */}
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                    <th>Sr. No.</th>
                                        <th>Booking ID</th>
                                        <th>Actual Amount</th>
                                        <th>Agreed Amount</th>
                                        
                                        <th>Value Of Goods</th>
                                        <th>Retailer Invoice No.</th>
                                        <th>Shipment Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>1</td>
                                    <td>123455</td>
                                    <td>2500 AED</td>
                                    <td>2500 AED</td>
                                    <td>5000 AED</td>
                                    <td>abc123</td>
                                    <td>Delivered</td>
                                    </tr>

                                    <tr>
                                        <td>2</td>
                                        <td>823782</td>
                                        <td>6500 AED</td>
                                        <td>5900 AED</td>
                                        
                                        <td>6000 AED</td>
                                        <td>xyz000</td>
                                        <td>Arrived at Dest Apt</td>
                                    </tr>

                                    <tr>
                                    <td>3</td>
                                        <td>298399</td>
                                        <td>5000 AED</td>
                                        <td>4000 AED</td>
                                       
                                        <td>4500 AED</td>
                                        <td>mno823</td>
                                        <td>Departed</td>
                                    </tr>
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td colspan="2"></td>
                                        <td><b>12400 AED</b></td>
                                        <td> <b>15500 AED</b></td>
                                        
                                        <td colspan="2"></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
